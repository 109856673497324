import React, { Component } from "react";
import { history } from "../_helpers/history";
import eye from "../assets/eye.png";
import { helperText } from "../_helpers/helpertext";
import PopupModal from "../_helpers/PopupModal";
import Loader from "../_helpers/Loader";
import { loginService } from "../_services/login.service";
import { MessageType } from "../_helpers/Enums";
import { Input} from "reactstrap";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailId: "",
      password: "",
      confirmPassword: "",
      showPassword: false,
      showconfirmPassword: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      isLoading: false,
    };
  }

  componentDidMount = async() => {
    const emailID = this.props.match.params.emailId;
    this.setState({
        emailId:emailID
    })
    if(emailID!=undefined){
      var response = await loginService.CheckIfLinkValid(emailID);
      if(response.status==200){
        if(response.data==false){
          //redirect
          history.push("/ErrorPage")
        }
      }
    }
  };

  changePassword = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  toggleEye = () => {
    const currentState = this.state.showPassword;
    this.setState({ showPassword: !currentState });
  };

  toggleConfirmEye = () => {
    const currentState = this.state.showconfirmPassword;
    this.setState({ showconfirmPassword: !currentState });
  };

  validate = () => {
    
    const { password, confirmPassword,emailId } = this.state;
    if (password == confirmPassword) {
      if (password.length > 7) {
        this.updatePassword(emailId,password);
      } else {
        this.setState({
          popUpModalText: helperText.resetPageText.wordLength,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
    } else {
      this.setState({
        popUpModalText: helperText.resetPageText.unMatchPassword,
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to update the password that the user has set after resetting the password
  <summary>*/ 
  updatePassword = async (emailId,password) => {
    try{
      this.setState({
        isLoading: true,
      });
      var response = await loginService.updatePassword(emailId,password);
      if (response.status == 200) {
        //if(response.data=='true'){
          this.setState({
            popUpModalText: helperText.resetPageText.wordUpdateSuccess,
            showPopupModal: true,
            messageType:MessageType.Success,
          });
        // }else{
        //   this.setState({
        //     popUpModalText: helperText.resetPageText.urlAlreadyUsed,
        //     showPopupModal: true,
        //     messageType:MessageType.Failure,
        //   });
        // }
      }
      
    this.setState({
      isLoading: false,
    });
    }catch (ex) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
    if(this.state.messageType==MessageType.Success){
      history.push("/LoginPage")
    }
  };

  render() {
    const {
      password,
      confirmPassword,
      showPassword,
      showconfirmPassword,
      showPopupModal,
      popUpModalText,
      messageType,
      isLoading,
    } = this.state;
    return (
      <div className="login-background">
        <div className="container container-background pg-height">
          <div className="text-center" style={{ paddingTop: "40px" }}>
            <h2 className="title">
              {" "}
              Welcome to the <br />
              <strong> IIMS Education Portal </strong>
            </h2>
            <img src="images/studentlogin.png" alt="logo image" width="120px" />
          </div>
          <section className="studentloginbox">
            <form action="index.html" className="confirm-psd-form">
              <div className="list-groupstudent">
                <div className="list-groupstudent">
                  <p className="psd-txt">
                    (Min. 8 characters containing at least 1 special character)
                  </p>
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="form-control input-border"
                    placeholder="New Password"
                    value={password}
                    name="password"
                    onChange={(e) => this.changePassword(e)}
                    onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                    }} onCopy={(e)=>{
                      e.preventDefault()
                      return false;
                    }}
                  />
                  <img
                    style={{ marginLeft: "90%", marginTop: "-66px" }}
                    src={eye}
                    onClick={this.toggleEye}
                  />{" "}
                </div>
                <div className="list-groupstudent">
                  <Input
                    type={showconfirmPassword ? "text" : "password"}
                    className="form-control input-border"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => this.changePassword(e)}
                    onPaste={(e)=>{
                      e.preventDefault()
                      return false;
                    }} onCopy={(e)=>{
                      e.preventDefault()
                      return false;
                    }}
                  />
                  <img
                    style={{ marginLeft: "90%", marginTop: "-66px" }}
                    src={eye}
                    onClick={this.toggleConfirmEye}
                  />{" "}
                </div>
                <div className="list-groupstudent forgot-pass back-login">
                  <button
                    type="button"
                    className="btn btn-link back-login-btn"
                    onClick={() => history.push("/LoginPage")}
                  >
                    Back to login
                  </button>
                </div>
                <center>
                  <button
                    type="button"
                    className="btn btn-primary1 psd-submit"
                    onClick={() => this.validate()}
                  >
                    SUBMIT
                  </button>
                </center>
              </div>
              {/* <div className="psd-change">
                <div className="change-psd-alert"> 
                  <h5>Password Changed Successfully.</h5>
                </div>
              </div>
              <div className="psd-change">
                <div className="psd-error"> 
                  <h5>The Password and Confirmation password do not match.</h5>
                </div>
              </div> */}
            </form>
          </section>
        </div>
        {isLoading && <Loader />}
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
