//this file will contain wrapper functions for http get/post.
import axios from "axios";
import { config } from "./config";
import { isStringEmpty } from "../_helpers/string_helper";
import { DecryptUsingAES256, EncryptUsingAES256 } from "../_helpers/utilities";
const defaultTimeout = 15000;
let postServiceTimeout = null;
let saveServiceTimeout = null;
let postServiceWithHeaderTimeout = null;
let getServiceTimeout = null;

class webserviceWrapper {
    constructor() {
        let service = axios.create();
        service.interceptors.request.use(this.HandleRequest.bind(this), function (error) {
            return Promise.reject(error);
        });

        service.interceptors.response.use(this.HandleSuccessResponse, this.HandleErrorResponse);
        //service.interceptors.response.use(this.handleSuccess, this.handleError);

        service.interceptors.request.use((config) => {
            const authToken = this.retreiveAuthToken();

            if (!isStringEmpty(authToken)) config.headers.Authorization = authToken;
            return config;
        });

        this.service = service;
    }
    buildUrl = (path) => {
        return `${config.apiUrl}/${path}`;
    };
    buildLongRunUrl = (path) => {
        return `${config.longRunningUrl}${path}`;
    };
    HandleRequest(req) {
        const ExcludeURLList = [];

        let exludeFound = ExcludeURLList.filter((element) => {
            return req.url.includes(element);
        });

        const authToken = this.retreiveAuthToken();
        if (authToken) {
            req.headers.common['Authorization'] = authToken
        }

        if (!(exludeFound && exludeFound.length > 0)) {
            // const BASE_URL = "https://localhost:44406/"
            if (req.method == "get") {
                if (req.url.indexOf("?") > 0) {
                    let encriptURL =
                        req.url.substr(0, req.url.indexOf("?") + 1) +
                        EncryptUsingAES256(
                            req.url.substr(req.url.indexOf("?") + 1, req.url.length)
                        );
                    let cloneReq = req;
                    cloneReq.url = encriptURL;
                    return cloneReq;
                }
                return req;
            }
            else if (req.method == "post") {
                if (req.url.indexOf("?") > 0) {
                    let encriptURL = req.url.substr(0, req.url.indexOf("?") + 1) + EncryptUsingAES256(req.url.substr(req.url.indexOf("?") + 1, req.url.length));
                    let cloneReq = req;
                    cloneReq.url = encriptURL;
                    return cloneReq;
                }
                else if (req.header && req.header["Content-Type"] == "multipart/form-data") {
                    var object = {};
                    var formData = new FormData();
                    req.data.forEach((value, key) => {
                        if (value instanceof File) {
                            //add files directly to formdata
                            formData.append(key, value);
                        } else {
                            //assign the property values to encrypt in next step
                            object[key] = value;
                        }
                    });
                    var json = JSON.stringify(object);
                    let cloneReq = req;
                    let payload = EncryptUsingAES256(json);
                    formData.append("payload", payload);
                    cloneReq.data = formData;
                    return cloneReq;
                }
                else if (req.data || req.data.length > 0) {
                    // console.log(req);
                    let cloneReq = req;
                    let payload = EncryptUsingAES256(JSON.stringify(req.data));
                    let encryptedPayload = {
                        payload,
                    };
                    cloneReq.data = encryptedPayload;
                    return cloneReq;
                }
                return req;
            }
        }

        return req;
    }

    /* <summary>
    date: 03-06-2020
    Name: Gm
    description: Handle Success Response from WebAPI
    <summary>*/
    HandleSuccessResponse(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        const ExcludeURLList = [
            config.apiUrl + "/TrackingTool/DownloadPOReadableFiles",
            config.apiUrl + "/TrackingTool/DownloadTrackingToolData",
            config.apiUrl + "/TrackingTool/DownloadApiImport",
        ];
        // Do something before request is sent
        let exludeFound = ExcludeURLList.filter((element) => {
            return response.config.url.includes(element);
        });

        if (!(exludeFound && exludeFound.length > 0)) {
            let cloneRes = response;

            let decryptedData = isStringEmpty(response.data)
                ? ""
                : JSON.parse(DecryptUsingAES256(response.data));
            cloneRes.data = decryptedData;
            let responsePayload = {
                status: 200,
                error: null,
                data: cloneRes.data,
                headers: cloneRes.headers,
            };
            return responsePayload;
        }

        let responsePayload = {
            status: 200,
            error: null,
            data: response.data,
            headers: response.headers,
        };
        return responsePayload;
    }

    /* <summary>
    date: 03-06-2020
    Name: Gm
    description: Handle  Error response from WebAPI 
    <summary>*/
    HandleErrorResponse = (error) => {
        try {
            let decryptedData = isStringEmpty(error.response)
                ? ""
                : JSON.parse(DecryptUsingAES256(error.response));

            error.response = decryptedData;

            var response = {
                status: error.response !== undefined ? error.response.status : error,
                error: error,
                data: error.response,
            };
            if (response.status == 401) {
                /* <summary>
                date: 18/10/2022
                Name: AS
                description: Sets the Session Timeout Modal Visibility to true
                <summary>*/

                // store.dispatch(eventAction.SetMessageModalVisible(true));
            }
            return response;
        } catch (error) {
            var response = {
                status: 500,
                error: error,
                data: null,
            };
            return response;
        }
    };

    /* <summary>
    date: 09/07/2021
    Name: VD
    description: Handle Success Response from WebAPI
    <summary>*/

    handleSuccess(response) {
        var response = {
            status: 200,
            error: null,
            data: response.data,
            headers: response.headers,
        };
        return response;
    }

    /* <summary>
    date: 09/07/2021
    Name: VD
    description: Handle  Error response from WebAPI 
    <summary>*/

    handleError = (error) => {
        try {
            var response = {
                status: error.response !== undefined ? error.response.status : error,
                error: error,
                data: error.response,
            };
            return response;
        } catch (error) {
            var response = {
                status: 500,
                error: error,
                data: null,
            };
            return response;
        }
    };

    async get(path, timeout, responseType) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        const response = await this.service.get(
            this.buildUrl(path),
            { responseType: responseType == undefined ? "json" : responseType },
            {
                cancelToken: source.token,
            }
        );
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }
    async saveRequest(path, payload, timeout) {
        clearTimeout(saveServiceTimeout);
        var source = axios.CancelToken.source();
        saveServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: this.buildUrl(path),
            responseType: "json",
            data: payload,
            timeout: 60 * 7 * 1000,
            //timeout: timeout === undefined ? defaultTimeout : timeout,
        });
        clearTimeout(saveServiceTimeout);
        saveServiceTimeout = null;
        return response;
    }

    async post(path, payload, timeout) { 
        clearTimeout(postServiceTimeout);
        var source = axios.CancelToken.source();
        postServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: this.buildUrl(path),
            responseType: "json",
            data: payload,
            //timeout: timeout === undefined ? defaultTimeout : timeout,
        });
        clearTimeout(postServiceTimeout);
        postServiceTimeout = null;
        return response;
    }


    /* <summary>
    date: 09/07/2021
    Name: VD
    description: Post API Call with Header Data
    <summary>*/

    async postWithHeader(path, payload, timeout, header) {
        clearTimeout(postServiceWithHeaderTimeout);
        var source = axios.CancelToken.source();
        postServiceWithHeaderTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: this.buildUrl(path),
            responseType: "json",
            header: header,
            data: payload,
            cancelToken: source.token,
        });
        postServiceWithHeaderTimeout = null;
        return response;
    }

    /* <summary>
    date: 19-07-2021
    Name: VD
    description: For Downloading the File
    <summary>*/
    async downloadBlob(path, timeout) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );

        const url = this.buildUrl(path);
        const response = await this.service.get(url, {
            cancelToken: source.token,
            responseType: "blob",
        });
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }

    async postForNotification(path, payload, timeout) {
        clearTimeout(postServiceTimeout);
        var source = axios.CancelToken.source();
        postServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: path,
            responseType: "json",
            data: payload,
        });
        clearTimeout(postServiceTimeout);
        postServiceTimeout = null;
        return response;
    }

    async longRunningPost(path, payload, timeout, withCredentials) {
        clearTimeout(postServiceTimeout);
        var source = axios.CancelToken.source();
        postServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: path,
            responseType: "json",
            data: payload,
            withCredentials: withCredentials ? true : false,
        });
        clearTimeout(postServiceTimeout);
        postServiceTimeout = null;
        return response;
    }
    /* <summary>
    date: 09/07/2021
    Name: VD
    description: Get Call with Long Running Url
    <summary>*/
    async getWithLongRunningUrl(path, timeout, responseType) {
        clearTimeout(getServiceTimeout);
        let source = axios.CancelToken.source();
        getServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        const response = await this.service.get(
            this.buildLongRunUrl(path),
            { responseType: responseType == undefined ? "json" : responseType },
            {
                cancelToken: source.token,
            }
        );
        clearTimeout(getServiceTimeout);
        getServiceTimeout = null;
        return response;
    }
    /* <summary>
    date: 09/07/2021
    Name: VD
    description: Post call with Long running url
    <summary>*/
    async postWithLongRunningUrl(path, payload, timeout) {
        clearTimeout(postServiceTimeout);
        var source = axios.CancelToken.source();
        postServiceTimeout = setTimeout(
            () => {
                source.cancel("timeout error");
            },
            timeout === undefined ? defaultTimeout : timeout
        );
        var response = await this.service.request({
            method: "POST",
            url: this.buildLongRunUrl(path),
            responseType: "json",
            data: payload,
            //timeout: timeout === undefined ? defaultTimeout : timeout,
        });
        clearTimeout(postServiceTimeout);
        postServiceTimeout = null;
        return response;
    }

    /* <summary>
    date: 21-07-2021
    Name: VD
    description: To get the auth token for authorisation
    <summary>*/
    retreiveAuthToken = () => {
        let authToken = "";
        try {
            const value = localStorage.getItem('SessionToken');
            if (!isStringEmpty(value)) authToken = `Bearer ${value}`;
        } catch (e) { }
        return authToken;
    };
}
export default new webserviceWrapper();
