import React, { Component } from "react";
import { Link, Route } from "react-router-dom";
import { history } from "../_helpers/history";
import { Role } from "../_helpers/Enums";
import { getBrowserDetails } from "../_helpers/utilities";
import { loginService } from "../_services/login.service";
import distancelearninglogo from "../assets/distance-learning-logo.png";
import notification from "../assets/notification.png";
import user2 from "../assets/user2.png";
import { studentService } from "../_services/student.service";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../_helpers/utilities";

class Header extends Component {
  constructor(props) {
      super(props);
    this.state = {
      currentTab: this.props.currentTab,
      showLoginModal: false,
      IsloggedIn: false,
      userName: "",
      isActive: false,
      menuwidth: 0,
      menuBlock: "",
      notificationCount:0,
      profileImagePath:"",
    };
    this.increaseWidthId = null;
    this.decreaseWidthId = null;
  }

    componentDidMount = async () => {
    const browserDetails = getBrowserDetails();
    if (this.state.currentTab != "Register") {
        if (GetLocalStorageDataWithoutParse("RoleID") === Role.Student.toString()) {
        this.setState({
          IsloggedIn: true,
            userName: GetLocalStorageDataWithoutParse("UserName"),
            notificationCount: GetLocalStorageDataWithoutParse("notificationCount"),
            profileImagePath: GetLocalStorageDataWithoutParse("ProfileImagePath")
        });
        this.getNotificationCount();
        //this.getStudentProfileImage();
      } else {
        //this is used when the user login has expired and he is on different page
        this.setState({
          IsloggedIn: false,
        });
        history.push("/LoginPage");
      }
    }
  }

  getNotificationCount = async () => {
      const userID = GetLocalStorageDataWithoutParse("UserID");
    
    var response = await studentService.GetNotificationCount(userID);
    if (response.status == 200) {
        AddLocalStorageData("notificationCount", response.data);
      this.setState({
        notificationCount:response.data
      })
    }
  };

    checkIfLogedIn = (Page) => {
      if (GetLocalStorageDataWithoutParse("RoleID") === Role.Student.toString()) {
      this.setState({
        IsloggedIn: true,
      });
      history.push(Page);
    } else {
      this.setState(
        {
          IsloggedIn: false,
        },
        async () => {
          this.dispplayLoginModal();
        }
      );
    }
  };
  Logout = () => {
      const UserName = GetLocalStorageDataWithoutParse("UserName");
      const userID = GetLocalStorageDataWithoutParse("UserID");
    localStorage.clear();
    history.push("/LoginPage");
    this.setState(
      {
        IsloggedIn: false,
      },
      async () => {
        var response = await loginService.SaveLogoutInfo(UserName, userID);
        if (response.status == 200) {
          //success
          
        }
        
      }
    );
    
  };

  showIndexPage = () => {
    history.push("/LoginPage");
  };
  showNotification = () => {
    history.push("/StudentNotification");
  };

  showStudentProfile = () => {
    history.push("/StudentProfile");
  };
  dispplayLoginModal = () => {
    this.setState({
      showLoginModal: true,
    });
  };

  closeModal = () => {
    this.setState({
      showLoginModal: false,
    });
  };

  toggleSlider = () => {

    if (this.state.menuwidth <= 0) {
      if (this.decreaseWidthId != null) {
        clearInterval(this.decreaseWidthId);
      }
      this.increaseWidthId = setInterval(() => this.method(), 50);
    } else {
      if (this.increaseWidthId != null) {
        clearInterval(this.increaseWidthId);
      }
      //  this.decreaseWidthId = setInterval(()=>this.decreaseWidth(), 100);
      this.decreaseWidth();
    }

  };

  method = () => {
    if (this.state.menuwidth < 200) {
      this.setState((prev) => ({
        menuwidth: prev.menuwidth + 50,
        menuBlock: "block",
      }));
    }
  };

  decreaseWidth = () => {
    if (this.state.menuwidth <= 200) {
      this.setState((prev) => ({
        menuwidth: 0,
        menuBlock: "none",
      }));
    }
  };
  render() {
    const { 
      showLoginModal, 
      IsloggedIn, 
      userName, 
      menuwidth, 
      menuBlock,
      notificationCount, 
      currentTab,
      profileImagePath,
    } =  this.state;
    return (
      <div>
        <nav className="navbar navbar-default custom-student-nav">
          <div className="container-fluid">
            {/* Logo and toggle for mobile display */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="slide-collapse"
                data-target="slide-navbar-collapse"
                onClick={() => this.toggleSlider()}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
                        </button>

                        {GetLocalStorageDataWithoutParse("BelongsToMarineCorrosionCourse") != 1 ?
                            (
                                <a className="navbar-brand" onClick={() => history.push("/StudentHomePage")} style={{ cursor: 'pointer' }}>
                                    <img
                                        src={distancelearninglogo}
                                        className="img-responsive image-small logo-alignment"
                                    />
                                </a>
                            )
                            :
                            (<a className="navbar-brand">
                                <img
                                src={distancelearninglogo}
                                className="img-responsive image-small logo-alignment"
                            />
                            </a>)
                        }

              <div className="mob-notify">
                <a onClick={this.showNotification}>
                  <img src={notification} />
                  <span className="notify">{notificationCount>99?'99':notificationCount}</span>
                </a>
              </div>
            </div>
            {/* Collect the nav links and other content for toggling */}
            <div
              className="collapse navbar-collapse"
              id="slide-navbar-collapse"
              style={{
                display: menuBlock,
                width: menuwidth,
                overflow: "hidden",
              }}
            >
            <ul className="nav navbar-nav navbar-right">
                            {(GetLocalStorageDataWithoutParse("BelongsToMarineCorrosionCourse") != 1) ?
             (
                currentTab == "Home" ? (
                  <li className="active li-mt" style={{cursor:'pointer'}}>
                  <a onClick={() => this.checkIfLogedIn("/StudentHomePage")}>
                    Home 
                  </a>
                </li>
                ): (
                  <li className="li-mt" style={{cursor:'pointer'}}>
                  <a onClick={() => this.checkIfLogedIn("/StudentHomePage")}>
                    Home 
                  </a>
                </li>
              )
             )
             : ("")
             }


                {currentTab == "StudyMaterial" ? (
                  <li className="active li-mt" style={{cursor:'pointer'}}>
                  <a onClick={() => this.checkIfLogedIn("/AllCourses")}>
                    Study Material <span className="sr-only">(current)</span>
                  </a>
                </li>
                ): (
                  <li className="li-mt" style={{cursor:'pointer'}}>
                  <a onClick={() => this.checkIfLogedIn("/AllCourses")}>
                    Study Material
                  </a>
                </li>
                )}
                {/* {currentTab == "Payments" ? (
                  <li className="active li-mt">
                  <a>Payments</a> <span className="sr-only">(current)</span>
                </li>
                ) : (
                  <li className="li-mt">
                  <a>Payments</a>
                </li>
                )} */}


                            {(GetLocalStorageDataWithoutParse("BelongsToMarineCorrosionCourse") != 1) ?
               (
                currentTab == "Assignment" ? (
                  <li className="active li-mt" style={{cursor:'pointer'}}>
                  <a onClick={() => this.checkIfLogedIn("/Assignments")}>
                  Assignments <span className="sr-only">(current)</span>
                  </a>
                </li>
                ): (
                  <li className="li-mt" style={{cursor:'pointer'}}>
                  <a onClick={() => this.checkIfLogedIn("/Assignments")}>
                  Assignments
                  </a>
                </li>
               )
               )
               :("")
              }


              {currentTab == "StudentNotification" ?
               (
                  IsloggedIn === true ? (
                    <li
                    style={{cursor:'pointer'}}
                      onClick={this.showNotification}
                    >
                      <a>
                        <img className="notificationIcon" src={notification} />
                        <span className="notify">{notificationCount}</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )
                ):(
                  IsloggedIn === true ? (
                    <li
                      className="hidden-xs hide-small"
                      onClick={this.showNotification}
                      style={{cursor:'pointer'}}
                    >
                      <a>
                        <img src={notification} />
                        <span className="notify">{notificationCount}</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )
              )}


                            {/*currentTab == "StudentProfile" ? (*/
                  IsloggedIn === true ? (
                    <li
                      style={{cursor:'pointer'}}
                      className="menu-user-icon"
                      onClick={this.showStudentProfile}
                    >
                      <a>
                        <img src={profileImagePath==""?user2:profileImagePath} className="header-profile-img" /> {userName}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                
                {IsloggedIn === true ? (
                  <li className>
                    <a style={{cursor:'pointer'}}>
                      <button
                        type="button"
                        onClick={this.Logout}
                        className="btn btn btn-primary purple"
                        data-toggle="modal"
                        data-target="#myModal"
                      >
                        Logout
                      </button>
                    </a>
                  </li>
                ) : (
                  <li className="hidden-xs hide-small" style={{cursor:'pointer'}}>
                    <button
                      type="button"
                      className="btn btn btn-primary purple"
                      //data-toggle="modal" data-target="#myModal"
                      onClick={() => history.push("/LoginPage")}
                    >
                      Login
                    </button>
                  </li>
                )}
              </ul>
            </div>
            {/* /.navbar-collapse */}
          </div>
          {/* /.container-fluid */}
        </nav>
        <div className="menu-overlay"> &nbsp;</div>
      </div>
    );
  }
}

export default Header;
