import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Deleteicon from "../../../assets/Deleteicon.png";
import Document from "../../../assets/Document.png";
import { history } from "../../../_helpers/history";
import DataTable from "react-data-table-component";
import Loader from "../../../_helpers/Loader";
import { admincourseservice } from "../../../_services/admincourse.service";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import coursebg1 from "../../../assets/coursebg1.png";
import coursebg2 from "../../../assets/coursebg2.png";
import coursebg3 from "../../../assets/coursebg3.png";
import AdminFooter from "../../../_components/AdminFooter";
import EditCourseNameModel from "./Components/EditCourseNameModel";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";

export default class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      courseList: [],
      courseName: "",
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      errorMessage: {},
      checkedBoxes: [],
      categoryList: [],
      unFilteredCourseList: [],
      selectedCategory: 1,
      categoryID: 0,
      selectedCategoryForFilter: 0,
      toggledClearRows: false,
      showEditCourseNameModel: false,
      courseId: 0,
      courseNameToEdit: "",
    };
  }

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the list of all course
  <summary>*/
  componentDidMount = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      this.GetCourseList();

      var getCategoryList = await admincourseservice.GetcourseCategoryList();
      if (getCategoryList.status == 200) {
        this.setState({
          categoryList: getCategoryList.data,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to get the list of all courses
  <summary>*/ 
  GetCourseList = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      var response = await admincourseservice.GetCourseList();
      if (response.status == 200) {
        this.setState({
          courseList: response.data,
          unFilteredCourseList: response.data,
        });
      } else if (response.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (ex) {
      return "";
    }
  };

  toggleCheckbox = async (e, item) => {
    if (e.target.checked) {
      let arr = this.state.checkedBoxes;
      arr.push(item.courseID);

      this.setState({ checkedBoxes: arr });
    } else {
      let items = this.state.checkedBoxes.splice(
        this.state.checkedBoxes.indexOf(item.courseID),
        1
      );

      this.setState({
        checkedBoxes: items,
      });
    }
  };

  handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    this.setState({
      checkedBoxes: state.selectedRows,
      isChanged: true,
    });
  };

  handleClearRows = () => {
    this.setState({ toggledClearRows: !this.state.toggledClearRows });
  };

  header = [
    // {
    //   name: (
    //     <th className="text-center" scope="col">
    //       {" "}
    //     </th>
    //   ),
    //   //selector: "Title",
    //   cell: (row) => (
    //     <input
    //       type="checkbox"
    //       id="check"
    //       name="check"
    //       defaultValue="check"
    //       value={row.courseID}
    //       checked={this.state.checkedBoxes.find((p) => p.id === row.courseID)}
    //       onChange={(e) => this.toggleCheckbox(e, row)}
    //     />
    //   ),
    //   center: true,
    // },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
        </th>
      ),
      //selector: "Title",
      cell: (row) => (
        <img
          src={Document}
          width="30px"
          onClick={() => history.push(`/CourseIteration/${row.courseID}`)}
          style={{ cursor: "pointer" }}
        />
      ),
      center: true,
      width: "80px",
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
        </th>
      ),
      //selector: "Title",
      cell: (row) => (
        <div className="edit-icon">
          <a
            title="Edit"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => this.updateCourseName(row.courseID, row.courseName)}
          >
            <i className="fa fa-pencil pencil" aria-hidden="true" />
          </a>
        </div>
      ),
      left: true,
      width: "50px",
    },
    {
        name: ( 
        <th className="text-center" scope="col">
          {" "}
          Course title{" "}
                </th> 
      ),
      selector: "Title",
      cell: (row) => (
        <div
          onClick={() => history.push(`/CourseIteration/${row.courseID}`)}
          style={{ cursor: "pointer" }}
        >
          {row.courseName}
        </div>
      ),
      left: true,
      grow: 3,
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Course iterations{" "}
        </th>
      ),
      selector: "Iterations",
      cell: (row) => (
        <div
          onClick={() => history.push(`/CourseIteration/${row.courseID}`)}
          style={{ cursor: "pointer" }}
        >
          {row.courseIterationCount}
        </div>
      ),
      center: true,
    },
  ];

  updateCourseName = (id, name) => {
    this.setState({
      showEditCourseNameModel: true,
      courseId: id,
      courseNameToEdit: name,
    });
  };

  closeModal = () => {
    this.handleClearRows();
    this.setState({
      showPopupModal: false,
      showEditCourseNameModel: false,
    });
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isChanged: true,
    });

    this.setState({
      errorMessage: {},
    });
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To create a new Course
  <summary>*/
  createCourse = async () => {
    try {
      const { courseName, selectedCategory } = this.state;
      this.setState({
        isLoading: true,
      });
      if (this.validate()) {
        var response = await admincourseservice.CreateCourse( courseName, selectedCategory );
        if (response.status == 200) {
          if (response.data.courseID == 0) {
            this.setState({
              popUpModalText: helperText.createCourse.nameExist,
              showPopupModal: true,
              messageType: MessageType.Failure,
            });
          } else {
            this.setState((prevstate) => ({
              courseList: [...prevstate.courseList, response.data],
              popUpModalText: helperText.createCourse.courseCreated,
              showPopupModal: true,
              messageType: MessageType.Success,
            }));
          }
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
      this.setState({
        courseName: "",
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  validate = () => {
    const { courseName } = this.state;
    try {
      var isValid = true;
      if (courseName == "") isValid = false;

      if (courseName == "") {
        var error = this.state.errorMessage;
        error["emptyCourseName"] = helperText.createCourse.emptyCourseName;
        this.setState({ errorMessage: error });
      }
      return isValid;
    } catch (error) {}
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To delete the selected courses
  <summary>*/
  deleteSelectedCourses = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      const { checkedBoxes } = this.state;

      if (this.validateCheckbox()) {
        var response = await admincourseservice.DeleteSelectedCourses(checkedBoxes);
        if (response.status == 200) {
          this.setState({
            courseList: response.data,
            checkedBoxes: [],
            popUpModalText: helperText.courseIteration.courseDeleted,
            showPopupModal: true,
            messageType: MessageType.Success,
          });
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  validateCheckbox = () => {
    const { checkedBoxes } = this.state;
    try {
      var isValid = true;
      if (checkedBoxes == "") isValid = false;

      if (checkedBoxes == "") {
        var error = this.state.errorMessage;
        error["emptyCheckbox"] = helperText.createCourse.emptyCourseCheckbox;
        this.setState({ errorMessage: error });
      }
      return isValid;
    } catch (error) {}
  };

  onRadioChecked(event) {
    const value = event.target.value;
    this.setState({
      selectedCategory: value,
    });
  }

  OnEventChange(event) {
    this.setState(
      {
        selectedCategoryForFilter: event.target.value,
      },
      async () => {
        await this.filterCourse();
      }
    );
  }

  filterCourse = () => {
    const { courseList, unFilteredCourseList, selectedCategoryForFilter } = this.state;
    var filteredValue;
    if (selectedCategoryForFilter == 0) {
      filteredValue = unFilteredCourseList;
    } else {
      filteredValue = unFilteredCourseList.filter((item) => {
        if (item.categoryId == selectedCategoryForFilter) {
          return item;
        }
      });
    }

    this.setState({ courseList: filteredValue });
  };

  closeshowEditCourseNameModel = (value) => {
    this.setState({
      showEditCourseNameModel: false,
    });
    if (value == 1) {
      this.GetCourseList();
      this.setState({
        popUpModalText: helperText.EditCourseNameModel.updatedCourseName,
        showPopupModal: true,
        messageType: MessageType.Success,
      })
    }
  };

  render() {
    const {
      isLoading,
      courseName,
      popUpModalText,
      showPopupModal,
      messageType,
      categoryList,
      showEditCourseNameModel,
      courseId,
      courseNameToEdit,
    } = this.state;
    return (
      <div className="admin-menu-bar">
        <section className="vbox1">
          <AdminHeader currentTab="Courses" />
          {isLoading && <Loader />}

          {/* .aside */}

          {/* /.aside */}
          {/* Begin page content */}

          <main role="main" className="container admin-main-body">
            <div className="row">
              <div className="col-md-12">
                <h5 className="mt-3 mb-3 gray">Course Manager</h5>
              </div>
              <div className="col-sm-12 col-xs-12">
                <p>
                  Select a course below to manage its iterations and modules.
                  You can create a new course by completing the form below
                </p>
              </div>
              <div className="col-sm-12">
                <div className="row iterations-radio-sec">
                  <div className="col-12 col-sm-4">
                    <input
                      type="radio"
                      id="yacht-small-craft"
                      name="course-iterations"
                      //defaultValue="yacht-small-craft"
                      defaultChecked="checked"
                      value={1}
                      onChange={(e) => this.onRadioChecked(e)}
                    />
                    <label htmlFor="yacht-small-craft">
                      Professional Qualification in Yacht &amp; Small Craft
                      Marine Surveying
                      <img
                        src={coursebg1}
                        className="img-responsive"
                        alt="Commercial Ship"
                        style={{ border: "3px solid #6CFFD3" }}
                      />
                    </label>
                    <br />
                  </div>
                  <div className="col-12 col-sm-4">
                    <input
                      type="radio"
                      id="commercial-ship"
                      name="course-iterations"
                      //defaultValue="commercial-ship"
                      value={2}
                      onChange={(e) => this.onRadioChecked(e)}
                    />
                    <label htmlFor="commercial-ship">
                      Professional Qualification in Commercial Ship Marine
                      Surveying
                      <img
                        src={coursebg2}
                        className="img-responsive"
                        alt="Commercial Ship"
                        style={{ border: "3px solid #6CE8FF" }}
                      />
                    </label>
                  </div>

                  <div className="col-12 col-sm-4">
                    <input
                      type="radio"
                      id="marine-corrosion"
                      name="course-iterations"
                      //defaultValue="marine-corrosion"
                      value={3}
                      onChange={(e) => this.onRadioChecked(e)}
                    />
                    <label htmlFor="marine-corrosion">
                      Professional Qualification in
                      <br /> Marine Corrosion
                      <img
                        src={coursebg3}
                        className="img-responsive"
                        alt="Commercial Ship"
                        style={{ border: "3px solid #FFD36C" }}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-xs-12 course-detail manage-courses-detail">
                <div className="row">
                  <div className="col-lg-8 col-xs-12 new-course">
                    <label className="white"> New course Title </label>
                    <input
                      className="textbox"
                      type="text"
                      id="courseName"
                      name="courseName"
                      value={courseName}
                      style={{ width: "65%", marginLeft: "5px" }}
                      onChange={this.onTextChange}
                    />
                    <div
                      style={{
                        display: this.state.errorMessage.emptyCourseName
                          ? "contents"
                          : "none",
                        color: "red",
                      }}
                    >
                      {this.state.errorMessage.emptyCourseName}
                    </div>
                  </div>
                  <div className="col-lg-3 col-xs-12">
                    <button
                      type="button"
                      className="btn btn-dark button-color create-button"
                      onClick={this.createCourse}
                    >
                      Create course
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-xs-12 manage-courses-dropdown">
                <div className="form-group">
                  <label htmlFor="course-cate">Course category</label>
                  <select
                    className="form-control"
                    id="course-cate"
                    value={this.state.selectedCategoryForFilter}
                    onChange={this.OnEventChange.bind(this)}
                  >
                    {/* <option>
                          Professional Qualification in Commercial Ship Marine
                          Surveying
                        </option>
                        <option>
                          Professional Qualification in Yacht &amp; Small Craft
                          Marine Surveying
                        </option>
                        <option>
                          Professional Qualification in Marine Corrosion
                        </option> */}
                    {/* <option
                              aria-label="None"
                              value={0}
                              selected
                              disabled
                            >
                              Select category to filter
                            </option> */}
                    <option aria-label="None" value={0}>
                      All
                    </option>
                    {categoryList.map((item) => {
                      return (
                        <option key={item.categoryID} value={item.categoryID}>{item.title}</option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="col-sm-12">
                <hr />
              </div>
              <div className="col-md-12 col-xs-12 new-col">
                <button
                  type="button"
                  className="btn delete-button delete"
                  style={{ marginLeft: "11px" }}
                  onClick={this.deleteSelectedCourses}
                >
                  <img src={Deleteicon} className="delete-icon" width="20px" />{" "}
                  Delete
                </button>
              </div>
              <div
                style={{
                  display: this.state.errorMessage.emptyCheckbox
                    ? "table-cell"
                    : "none",
                  color: "red",
                }}
              >
                {this.state.errorMessage.emptyCheckbox}
              </div>
              <div className="col-md-12">
                <div className="table-responsive course-table">
                  <DataTable
                    columns={this.header}
                    data={this.state.courseList}
                    noHeader={true}
                    pagination
                    selectableRows
                    onSelectedRowsChange={this.handleChange}
                    // add for checkbox selection
                    clearSelectedRows={this.state.toggledClearRows}
                  />
                </div>
              </div>
            </div>
          </main>
          <AdminFooter />
        </section>
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {showEditCourseNameModel && (
          <EditCourseNameModel
            closeshowEditCourseNameModel={this.closeshowEditCourseNameModel}
            courseID={courseId}
            courseNameToEdit={courseNameToEdit}
          />
        )}
      </div>
    );
  }
}
