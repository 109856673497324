import React, { Component } from "react";
import AdminHeader from "../../_components/AdminHeader";
import { studentService } from "../../_services/student.service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { ConvertToHtml } from "../../_helpers/utilities";
import { uploadfileservice } from "../../_services/uploadfile.service";
import { history } from "../../_helpers/history";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import { MessageType } from "../../_helpers/Enums";
import { ConvertFromHTML } from "../../_helpers/utilities";
import Deleteicon from "../../assets/RedDeleteIcon.png";
import { helperText } from "../../_helpers/helpertext";
import DataTable from "react-data-table-component";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class EmailToStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userId: 0,
      membershipNumber: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNo: "",
      coursesList: [],
      errorMessage: {},
      subject: "",
      message: EditorState.createEmpty(),
      fileInputKey: this.generateRandomString(),
      assignmentId: 0,
      currentUser: 0,
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      templateName: "",
      templateList:[],
      selectedTemplate:[], 
      AttachedFilesList: [],
      DeleteAttachedFilesList: [],
      CourseEnquiryEmailTemplateId:0,
    };
  }
  componentDidMount = async () => {
    const UserID = this.props.match.params.userID;
    const AssignmentID = this.props.match.params.assignmentId;
      const adminId = GetLocalStorageDataWithoutParse("UserID");
    this.setState({
      userId: UserID,
      currentUser: adminId,
      assignmentId: AssignmentID,
    });
    this.getUserData(UserID);
    this.getEmailTemplate();
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the list of student 
  <summary>*/ 
  getUserData = async (userId) => {
    try {
      var response = await studentService.getStudentProfile(userId);
      if (response.status == 200) {
        this.setState({
          membershipNumber: response.data.studentData.membershipNumber,
          firstName: response.data.studentData.firstName,
          lastName: response.data.studentData.lastName,
          emailAddress: response.data.studentData.emailAddress,
          contactNo: response.data.studentData.contactNo,
          coursesList: response.data.studentCourseData,
        });
        this.uniqueList();
      }
    } catch (ex) {
      return "";
    }
  };

  uniqueList = async () => {
    const { coursesList } = this.state;

      const uniqueTags = [];
      coursesList.forEach((item) => {
      var findItem = uniqueTags.find((x) => x.courseName === item.courseName);
      if (!findItem) uniqueTags.push(item);
      return item;
    });

    this.setState({
      coursesList: uniqueTags,
    });
  };

  subjectText = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });

  };

  HandleTextChange = (editorState, name) => { 
    var empty = editorState.getCurrentContent().hasText();
    this.setState({
      [name]: editorState,
      errorMessage: {},
      hasMessage: empty,
    });
  };

  onFileChange = (e) => {
    if (!e.target.value) {
      this.setState({
        fileInputKey: this.generateRandomString(),
        documentFile: null,
        AttachedFilesList: null,
      });
      return;
      }
    let files = e.target.files; 
    var ins = files.length;

    for (var i = 0; i < ins; i++) {
      let attachedFile = files[i]; 
      this.setState((prevState) => ({
        AttachedFilesList: [...prevState.AttachedFilesList, attachedFile],
      }));
    }

    this.setState({
      documentFile: files,
    });
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  /* <summary>
  date: 27-09-2021
  Name: VD
  description: This is used to send the mail from both admin and student side
  <summary>*/
  sendEmail = async (e) => {
    try {
      e.preventDefault();

      const { 
        subject, 
        message, 
        documentFile, 
        currentUser, 
        emailAddress,
        AttachedFilesList, 
        DeleteAttachedFilesList,
        CourseEnquiryEmailTemplateId
      } = this.state; 
      var value = ConvertToHtml(message);
      if (this.validate()) {
        this.setState({
          isLoading: true,
        });
        let data = new FormData();
        data.append("subject", subject);
        data.append("message", value);
        data.append("AttachementsToDeleted", DeleteAttachedFilesList);
        if(AttachedFilesList!=undefined){
        var ins = AttachedFilesList.length;   
            for (var x = 0; x < ins; x++) {
                if (AttachedFilesList[x].size != null && AttachedFilesList[x].size != undefined) { 
                    data.append(`file_${x}`, AttachedFilesList[x]);
                }
          }
        }
        data.append("AttachementsToDeleted", DeleteAttachedFilesList);
        data.append("userId", currentUser);
        data.append("sendTo", emailAddress);
        data.append("CourseEnquiryEmailTemplateId",CourseEnquiryEmailTemplateId);

        var response = await uploadfileservice.SendEmail(data);
        if (response.status == 200) {
          this.setState({
            popUpModalText: helperText.AdvanceCoursePopupModalText.mailsent,
            showPopupModal: true,
            messageType:MessageType.Success,
            subject: "",
            message: EditorState.createEmpty(),
            fileInputKey: this.generateRandomString(),
            documentFile: null,
            AttachedFilesList: [],
          });
        }
        this.setState({
          isLoading: false,
        });
      }
    } catch (ex) {
      return "";
    }
  };

  validate = () => {
    const { subject, message, hasMessage } = this.state;
    try {
      var isValid = true;
      if (subject == "" || hasMessage == false) isValid = false;

      if (subject == "") {
        var error = this.state.errorMessage;
        error["emptysubject"] = helperText.AddEditTemplate.emptyTemplatesubject;
        this.setState({ errorMessage: error });
      }
      if (hasMessage == false) {
        var error = this.state.errorMessage;
        error["emptymessage"] = helperText.AddEditTemplate.emptyTemplatemessage;
        this.setState({ errorMessage: error });
      }

      return isValid;
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  getEmailTemplate=async()=>{
    try{
      this.setState({isLoadingL:true});
      var response=await studentService.GetEmailTemplateList();
      if(response.status==200){
        this.setState({
          templateList:response.data
        })
      }
      this.setState({isLoading:false});
    } catch (error) {}
  }

  onCategoryChange=(e)=>{
    const { name, value } = e.target;
    const{
      templateList,
      selectedTemplate,
      subject,
      message,
    }=this.state;
 
    var filterTemplate= templateList.filter((item)=>{
      if(item.courseEnquiryEmailTemplateID==value){
        return item
      }
    })

    this.setState({
      templateName:value,
      subject:filterTemplate[0].emailSubject,
      message:ConvertFromHTML(filterTemplate[0].emailMessage),
      CourseEnquiryEmailTemplateId:value,
    })

    if(filterTemplate!=""){
      this.getTemplateAttachmentList(value);
    }
  }

  getTemplateAttachmentList=async(value)=>{
    try{ 
      var response=await studentService.GetEmailTemplateAttachementList(value);
      if(response.status==200){
        this.setState({
          AttachedFilesList:response.data,
        })
      }
    }catch(error){

    }  
  }

  DeleteFile = (row) => {   
    let attachedlist = this.state.AttachedFilesList;
     var ins = attachedlist.length;   
      for (var x = 0; x < ins; x++) {
          if (attachedlist[x].name == row.name) {
              if (attachedlist[x].size != null && attachedlist[x].size != undefined) { 
                  const items = this.state.AttachedFilesList.filter(AttachedFilesList => AttachedFilesList.name !== row.name);
                  this.setState({ AttachedFilesList: items });
              }
              else {
                  this.setState((prevState) => ({
                      DeleteAttachedFilesList: [...prevState.DeleteAttachedFilesList, row.courseEnquiryEmailTemplateAttachmentBridgeId],
                  }));
                  const items = this.state.AttachedFilesList.filter(AttachedFilesList => AttachedFilesList.name !== row.name);
                  this.setState({ AttachedFilesList: items });
              }
          }
    }
      
  }

  AttachedFilesColumns = [
    {
      cell: (row) => <td className="center">{row.name}</td>,
      center: true,
      grow: 1,
    },
    {
      cell: (row) => (
        <td
          className="center material-tab"
          onClick={() => this.DeleteFile(row)}
          style={{ cursor: "pointer" }}
        >
         <img
                      src={Deleteicon}
                      className="manage-notification-deleteIcon"
                  />
        </td>
      ),
      center: true,
    },
  ];

  render() {
    const {
      isLoading,
      subject,
      message,
      fileInputKey,
      membershipNumber,
      firstName,
      lastName,
      emailAddress,
      contactNo,
      coursesList,
      popUpModalText,
      showPopupModal,
      messageType,
      templateName,
      templateList,
      AttachedFilesList,
    } = this.state;
    return (
      <div>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        <div className="menu-overlay"> &nbsp;</div>
        {/* Begin page content */}
        {/* Begin page content */}
        <main role="main" className="container">
          <div className="row">
            <div className="col-md-12">
              <h5 className="mt-3 mb-3 gray">Assignment Submissions</h5>
            </div>
            <div className="col-md-12" style={{ marginTop: "-1rem" }}>
              <h6 className="mt-3 mb-3 gray">
                Student Course Manager&gt;{" "}
                <span>
                  <strong> Enrolled Courses </strong>{" "}
                </span>{" "}
                &gt;{" "}
                <span>
                  {" "}
                  <strong> Assignment Submissions </strong>{" "}
                </span>{" "}
                <span>
                  {" "}
                  <strong>
                    {" "}
                    &gt; Email Message to Student <strong> </strong>
                  </strong>
                </span>
              </h6>
              <strong>
                <strong></strong>
              </strong>
            </div>
            <strong>
              <strong>
                <div
                  className="col-md-6  col-sm-12"
                  style={{ marginTop: "13px" }}
                >
                  <div className="student-info">
                    <div
                      className="table-responsive"
                      style={{ border: "none" }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <td className="cell-size">
                              <strong>Membership Number: </strong>
                            </td>
                            <td className="cell-size cell-alignment">
                              {membershipNumber}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell-size">
                              <strong>First Name:</strong>
                            </td>
                            <td className="cell-size cell-alignment">
                              {firstName}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell-size">
                              <strong>Last Name:</strong>
                            </td>
                            <td className="cell-size cell-alignment">
                              {lastName}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell-size">
                              <strong>Email:</strong>
                            </td>
                            <td className="cell-size cell-alignment">
                              {emailAddress}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell-size">
                              <strong>Phone:</strong>
                            </td>
                            <td className="cell-size cell-alignment">
                              {contactNo}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell-size">
                              <strong>Enrolled Courses:</strong>
                            </td>
                            <td className="cell-size cell-alignment text-color">
                              {coursesList.map((item) => {
                                return <span>{item.courseCategory}</span>;
                              })}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn back-button"
                    onClick={() => history.goBack()}
                  >
                    <i className="fa fa-angle-left" /> Back
                  </button>
                </div>
                <div
                  className="col-md-6 col-sm-12"
                  style={{ marginTop: "5px" }}
                >
                  <form className="form-inline" action="/action_page.php">
                    <div className="row">
                    <div className=" col-12 col-md-2">
                        <label htmlFor="subject" className="label-alignment">
                          Category
                        </label>
                      </div>
                      <div className="col-12 col-md-10">
                      <select
                            className="form-control1 courses"
                            id="courses"
                            name="templateName"
                            value={templateName}
                            onChange={e=>this.onCategoryChange(e)}
                            style={{
                              width: "100%",
                            }}
                          >
                            <option value="" selected disabled> Select Category </option>
                            {templateList.map((item)=>{
                              return(
                                <option value={item.courseEnquiryEmailTemplateID}>{item.templateName}</option>
                              )
                            })}
                            </select>
                      </div>
                      <div className="col-md-2 col-12">
                        <label htmlFor="subject" className="label-alignment">
                          Subject
                        </label>
                      </div>
                      <div className="col-12 col-md-10">
                        <input
                          type="text"
                          name="subject"
                          //className="textbox text-box-width"
                          style={{ width: "100%", border: "1px solid #cbd5dd" }}
                          value={subject}
                          onChange={(e) => this.subjectText(e)}
                        />
                        <div
                        style={{
                          display: this.state.errorMessage.emptysubject
                            ? "contents"
                            : "none",
                          color: "red",
                        }}
                      >
                        {this.state.errorMessage.emptysubject}
                      </div>
                      </div>
                      
                      <div className="  col-12 col-md-2">
                        <label htmlFor="subject" className="label-alignment">
                          Message
                        </label>
                      </div>
                      <div className="col-md-10 col-12">
                        <Editor
                          editorStyle={{
                            minHeight: "200px",
                            fontWeight: "normal",
                          }}
                          editorState={message}
                          wrapperClassName="demo-wrapper sendtext-area-bg"
                          editorClassName="editer-content"
                          toolbarClassName="toolbar-class"
                          onEditorStateChange={(editorState) =>
                            this.HandleTextChange(editorState, "message")
                          }
                          toolbar={{
                            options: ["inline"],
                            inline: {
                              inDropdown: false,
                              options: ["bold", "italic", "underline"],
                            },
                          }}
                        />
                         <div
                        style={{
                          display: this.state.errorMessage.emptymessage
                            ? "contents"
                            : "none",
                          color: "red",
                        }}
                      >
                        {this.state.errorMessage.emptymessage}
                      </div>
                      </div>
                     
                      <div className=" col-md-2 col-12"></div>
                      {/* <div className=" col-md-10 col-12">
                        <Input
                          type="file"
                          accept=".pdf,.xlsx, .xls,"
                          // accept=" .doc, .docx, .pdf"
                          name="documentFile"
                          onChange={this.onFileChange}
                          key={fileInputKey || ""}
                          style={{
                            marginTop: "10px",
                            width: "95%",
                            overflow: "revert",
                            whiteSpace: "break-spaces",
                          }}
                        /> */}
                        {/* <button type="button" className="btn save-button">
                          Upload
                        </button> */}
                      {/* </div> */}
                      
                  {/* {filename == "" ? ( */}
                    <div className=" col-md-10 col-12">
                    <input
                      type="file"
                      accept=".pdf,.xlsx, .xls,.doc,.docx"
                      // accept=" .doc, .docx, .pdf"
                      name="documentFile"
                      onChange={this.onFileChange}      
                      multiple="multiple"                
                      key={fileInputKey || ""}
                      style={{
                        marginTop: "10px",
                        width: "95%",
                        overflow: "revert",
                        whiteSpace: "break-spaces",
                        color: "transparent",
                        cursor:'pointer',
                      }}
                    />
                    <div id="selectedRefereceLetters" />
                      <DataTable
                        columns={this.AttachedFilesColumns}
                        data={AttachedFilesList}
                        noHeader={true}
                      />
                    </div>
                 
                      <div className="col-md-2 col-12"></div>
                      <div className=" col-md-10 col-12" style={{paddingBottom:'9em'}}>
                        <button
                          type="button"
                          className="btn btn-dark button-color send-button send-button-align"
                          onClick={this.sendEmail}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </strong>
            </strong>
          </div>
          <strong>
            <strong></strong>
          </strong>
        </main>
        <strong>
          <strong>
            <footer className="admin-footer-bar">
              <div className="container">
                <span className="text-muted">
                  © International Institute of Marine Surveying 2021
                </span>
              </div>
            </footer>
          </strong>
        </strong>
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
