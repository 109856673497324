import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Loader from "../../../_helpers/Loader";
import { UploadModal } from "../../../_helpers/UploadModal";
import DataTable from "react-data-table-component";
import moment from "moment";
import { adminstudentservice } from "../../../_services/adminstudent.service";
import { history } from "../../../_helpers/history";
import AdminFooter from "../../../_components/AdminFooter";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";
import { adminservice } from "../../../_services/admin.service";
import { UIGridToSheet,SheetToArrayBuffer } from "../../../_helpers/utilities";

const XLSX = require("xlsx");
export default class UploadStudentData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      openCommonUploadModal: false,
      showSuccess: false,
      showFailure: false,
      importFileDetailsList: [],
    };
  }

  componentDidMount = async () => {
    this.getImportFiles();
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: TO get the File details that have been uploaded by the admin which contains student details
  <summary>*/ 
  getImportFiles = async () => {
    try {
      this.setState({ isLoading: true });
      var response = await adminstudentservice.GetStudentDataUploadedFileList();
      if (response.status == 200) {
        this.setState({
          importFileDetailsList: response.data,
        });
      } else {
        this.setState({
          showFailure: true,
        });
      }

      this.setState({ isLoading: false });
    } catch (e) { 
      return "";
    }
  };

  onUploadClicked = () => {
    this.setState({
      openCommonUploadModal: true,
    });
  };

  closeModal = () => {
    this.setState(
      {
        openCommonUploadModal: false,
      },
      async () => {
        this.getImportFiles();
      }
    );
  };

  header = [
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Document name{" "}
        </th>
      ),
      selector: "fileName",
      cell: (row) => <div style={{ cursor: "pointer" }}>{row.fileName}</div>,
      center: true,
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Document version{" "}
        </th>
      ),
      selector: "fileVersion",
      cell: (row) => <div style={{ cursor: "pointer" }}>{row.fileVersion}</div>,
      center: true,
    },
    {
      name: (
        <th className="text-center" scope="col">
          {" "}
          Date Uploaded (dd/mm/yyyy){" "}
        </th>
      ),
      selector: "createdOn",
      cell: (row) => (
        <div style={{ cursor: "pointer" }}>
          {moment(row.createdOn).format("DD-MM-YYYY")}
        </div>
      ),
      center: true,
    },
  ];

  downloadTemplate = async () => {
    try {
      var sheetName = "sheet1";
      var wopts = { bookType: "xlsx", bookSST: true, type: "binary" };
      var columns = [
        "Membership Number",
        "FirstName",
        "Lastname",
        "Middlename",
        "Address 1",
        "Address 2",
        "Address 3",
        "City",
        "County/State/Province",
        "Country",
        "Postcode",
        "Phone",
        "Business Email",
        "Type",
        "Intake",
        "Paid",
        //"Unit",
        "Invoice Address",
        "Payment Plan",
        "Amount Paid",
        "Payment Date",
        "hardcopies",
        "PF Ref"
      ];
      var data = [];

      var wb = XLSX.utils.book_new();
      var ws = UIGridToSheet(data, columns);

      XLSX.utils.book_append_sheet(wb, ws, sheetName);
      var wbout = XLSX.write(wb, wopts);
      const url = window.URL.createObjectURL(
        new Blob([SheetToArrayBuffer(wbout)], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Student Template.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      this.setState({
        showPopupModal: true,
        popUpModalText: helperText.DataLoadError.downloadError,
        messageType:MessageType.Failure,
      });
    }
  };

  render() {
    const { 
      isLoading, 
      showSuccess, 
      showFailure 
    } = this.state;
    return (
      <div className="overall-font" style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Home" />
        {isLoading && <Loader />}

        {/* Begin page content */}
        <main role="main" className="container admin-main-body ">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                  <h5 className="mt-3 mb-3 gray">Upload Student Data</h5>
                </div>
                <div
                  className="col-lg-4 col-md-12 col-sm-12  col-xs-12"
                  style={{ marginTop: "25px" }}
                >
                  <button className="upload" onClick={this.downloadTemplate}> Download Template </button>
                  <button className="upload" onClick={this.onUploadClicked}>
                    {" "}
                    Upload{" "}
                  </button>
                </div>
              </div>
            </div>
            {showFailure && (
              <div className="col-md-12" style={{ paddingTop: "15px" }}>
                <div className="alert alert-danger alert-dismissible">
                  <a
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    style={{ color: "#1d1616", fontWeight: "bold" }}
                  >
                    ×
                  </a>
                  <span style={{ fontSize: "16px" }}>
                    Oops! an invalid data or mismatch in document structure
                    detected
                  </span>
                </div>
              </div>
            )}
            {showSuccess && (
              <div className="col-md-12">
                <div className="alert alert-success alert-dismissible">
                  <a
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                    style={{ color: "#1d1616", fontWeight: "bold" }}
                  >
                    ×
                  </a>
                  <span style={{ fontSize: "16px" }}>
                    Data uploaded successfully.
                  </span>
                </div>
              </div>
            )}
            <div className="col-md-12">
              <h6 className="mt-3 mb-3 gray">Uploaded History</h6>
            </div>
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <table className="table table-striped">
                  <thead>
                    <tr style={{ backgroundColor: "#EEEBEB" }}>
                      <th className="text-center">Document Name</th>
                      <th className="text-center">Document Version</th>
                      <th className="text-center">
                        Date Uploaded (dd/mm/yyyy)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">Student records sheet ver 2.1.xls</td>
                      <td className="center"> 2.0 </td>
                      <td className="center">08/07/2021</td>
                    </tr>
                  </tbody>
                </table> */}
                <DataTable
                  columns={this.header}
                  data={this.state.importFileDetailsList}
                  noHeader={true}
                  pagination
                />
              </div>
              <button
                type="button"
                className="btn back-button"
                style={{ marginTop: "20px" }}
                onClick={() => history.push("/DashboardPage")}
              >
                <i className="fa fa-angle-left" /> Back
              </button>
            </div>
          </div>
        </main>
        <AdminFooter />
        {this.state.openCommonUploadModal && (
          <UploadModal
            ref="uploadModal"
            closeModal={this.closeModal}
            OpenModal={this.state.openCommonUploadModal}
            //rebindSectionContent={this.componentDidMount}
          ></UploadModal>
        )}
      </div>
    );
  }
}
