import React, { Component } from "react";
import AdminHeader from "../../../_components/AdminHeader";
import Loader from "../../../_helpers/Loader";
import { adminservice } from "../../../_services/admin.service";
import Logout from "../../../_helpers/Logout";
import { history } from "../../../_helpers/history"; 
import ModuleModel from "../../../Models/ModuleModel";
import ModuleDetailsModel from "../../../Models/ModuleDetailsModel";
import PopupModal from "../../../_helpers/PopupModal";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, stateFromHTML } from "draft-js";
import { ConvertToHtml, ConvertFromHTML } from "../../../_helpers/utilities";
import AdminFooter from "../../../_components/AdminFooter";
import CommonUploadForm from "../../../_helpers/CommonUploadForm";
import { MessageType } from "../../../_helpers/Enums";
import coursebg1 from "../../../assets/coursebg1.png";
import coursebg2 from "../../../assets/coursebg2.png";
import coursebg3 from "../../../assets/coursebg3.png";
import { helperText } from "../../../_helpers/helpertext";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../../_helpers/utilities";

class AddEditUnitAddDynamicContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      moduleID: 0,
      moduleDetailId: 0,
      moduleTabList: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      checkedBoxes: [],
      moduleYourUnitList: [],
      unitNumber: "",
      modulePartNumber:'',
      unitHeader: "",
      unitShortDescription: "",
      unitLongDescription: "",
      unitCoverImageFileMediaID: 0,
      moduleEssentialVideoList: [],
      moduleEssentialReadingList: [],
      moduleEssentialBusinessList: [],
      moduleEssentialAdviceList: [],
      moduleOtherGenaralList: [],
      newHeader: "",
      newDescription: "",
      newLink: "",
      showEditorModal: false,
      tabId: 0,
      message: EditorState.createEmpty(),
      errorMessage: {},
      errorTabMessage: {},
      editorState: "",
      showCommomUploadModel: false,
      folderName: "UnitCoverImages",
      downloadUnitText: "",
      openCommonUploadModal: false,
      uploadedPdfId: 0,
      imageName: "",
      yourUnitPdfName:"",
      yourUnitPdfId:0,
      typeofFile:"",
      pdfCoverImageId:0,
      categoryOneSelected: false,
      categoryTwoSelected: false,
      categoryThreeSelected: false,
      moduleTypeId:1,
      pdfCoverImageName:"",
      currentPage:"ManageStudyMaterial",
      activeTab:1
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: RA
  description:To get the lis of all Module tabs and to call various methods 
  <summary>*/ 
  componentDidMount = async () => {
    try {
      const moduleId = this.props.match.params.moduleId;
      this.setState({
        isLoading: true,
        moduleID: moduleId,
      });

      var moduleTabresponse = await adminservice.GetAllModuleTabs();
      if (moduleTabresponse.status == 200) {
        this.setState({
          moduleTabList: moduleTabresponse.data,
          isLoading: false,
        });
      } else if (moduleTabresponse.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.AddEditUnitAddDynamicContent.tabLoadError,
          showPopupModal: true,
          isLoading: false,
          messageType:MessageType.Failure,
        });
      }

      var imoduleID = moduleId;
      if (imoduleID != 0) {
        await this.GetModuleDataBasedOnModuleID(imoduleID);
        await this.GetModuleDataBasedOnModuleTabID(imoduleID, 2);
        await this.GetModuleDataBasedOnModuleTabID(imoduleID, 3);
        await this.GetModuleDataBasedOnModuleTabID(imoduleID, 4);
        await this.GetModuleDataBasedOnModuleTabID(imoduleID, 5);
        await this.GetModuleDataBasedOnModuleTabID(imoduleID, 6);
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description:To get the Module data based on the module Id for Your Module tab 
  <summary>*/ 
  GetModuleDataBasedOnModuleID = async (moduleID) => {
    try {
      var moduleresponse = await adminservice.GetModuleDataBasedOnModuleID( moduleID );
      this.setState({
        unitNumber: moduleresponse.data[0].uniqueId,
        modulePartNumber:moduleresponse.data[0].modulePartNumber,
        unitHeader: moduleresponse.data[0].moduleName,
        unitShortDescription: moduleresponse.data[0].shortDescription,
        unitLongDescription: moduleresponse.data[0].description,
        unitCoverImageFileMediaID:moduleresponse.data[0].unitCoverImageFileMediaID,
        downloadUnitText: moduleresponse.data[0].downloadUnitText,
        imageName:moduleresponse.data[0].unitCoverImageName,
        yourUnitPdfName:moduleresponse.data[0].modulePdfName,
        yourUnitPdfId:moduleresponse.data[0].moduleFileMediaID,
        moduleTypeId:moduleresponse.data[0].moduleTypeId,
        categoryOneSelected:moduleresponse.data[0].categoryOneSelected,
        categoryTwoSelected:moduleresponse.data[0].categoryTwoSelected,
        categoryThreeSelected:moduleresponse.data[0].categoryThreeSelected,
      });
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: To get the details based on the module tab id
  <summary>*/ 
  GetModuleDataBasedOnModuleTabID = async (moduleID, tabID) => {
    try {
      var moduleresponse =
        await adminservice.GetModuleDetailsBasedOnModuleTabID(moduleID, tabID);
      if (moduleresponse.status == 200) {
        if (tabID == 2)
          this.setState({ moduleEssentialVideoList: moduleresponse.data });
        else if (tabID == 3)
          this.setState({ moduleEssentialReadingList: moduleresponse.data });
        else if (tabID == 4)
          this.setState({ moduleEssentialBusinessList: moduleresponse.data });
        else if (tabID == 5)
          this.setState({ moduleEssentialAdviceList: moduleresponse.data });
        else if (tabID == 6)
          this.setState({ moduleOtherGenaralList: moduleresponse.data });
      } else if (moduleresponse.status == 401) {
        Logout();
      } else {
        this.setState({
          popUpModalText: helperText.AddEditUnitAddDynamicContent.moduleLoadError,
          showPopupModal: true,
          messageType:MessageType.Failure,
        });
      }
    } catch (e) {
      return "";
    }
  };

  onnewHeaderTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      newHeader: value,
      errorTabMessage: {},
    });
  };

  onnewDescriptionTextChange = (editorState, name) => {
    this.setState({
      newDescription: editorState,
      errorTabMessage: {},
    });
  };

  onnewLinkTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      newLink: value,
      errorTabMessage: {},
    });
  };

  onUnitNumberTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });
  };

  onHeaderTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      unitHeader: value,
      errorMessage: {},
    });
  };

  onunitShortDescriptionTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      unitShortDescription: value,
    });
  };

  onShortDescriptionTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      shortDescription: value,
    });
  };

  onunitLongDescriptionTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      unitLongDescription: value,
    });
  };

  onDescriptionTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      description: value,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: To save the changes that have been done in the your Module tab
  <summary>*/ 
  onSubmitYourUnitTab = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      if (this.validate()) {
          const userID = GetLocalStorageDataWithoutParse("UserID");
        var moduleModel = new ModuleModel();
        moduleModel.moduleId = this.state.moduleID;
        moduleModel.uniqueId = this.state.unitNumber;
        moduleModel.modulePartNumber=this.state.modulePartNumber;
        moduleModel.moduleName = this.state.unitHeader;
        moduleModel.shortDescription = this.state.unitShortDescription;
        moduleModel.description = this.state.unitLongDescription;
        moduleModel.unitCoverImageFileMediaID = this.state.unitCoverImageFileMediaID;
        moduleModel.userID = userID;
        moduleModel.downloadUnitText = this.state.downloadUnitText;
        moduleModel.categoryOneSelected=this.state.categoryOneSelected;
        moduleModel.categoryTwoSelected=this.state.categoryTwoSelected;
        moduleModel.categoryThreeSelected=this.state.categoryThreeSelected;
        moduleModel.moduleTypeId=this.state.moduleTypeId;
        moduleModel.moduleFileMediaID=this.state.yourUnitPdfId;
        var response = await adminservice.AddEditModule(moduleModel);
        if (response.status == 200) {
          //success
          if (this.state.moduleID == 0) {
            this.setState({
              popUpModalText: helperText.AddEditUnitAddDynamicContent.moduleCreated,
              showPopupModal: true,
              isLoading: false,
              messageType:MessageType.Success,
            });
          } else {
            this.setState({
              popUpModalText: helperText.AddEditUnitAddDynamicContent.moduleDetailsUpdated,
              showPopupModal: true,
              isLoading: false,
              messageType:MessageType.Success,
            });
          }
        } else {
          this.setState({
            popUpModalText: helperText.AddEditUnitAddDynamicContent.updateDetailsError,
            showPopupModal: true,
            isLoading: false,
            messageType:MessageType.Failure,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  validate = () => {
    const { unitNumber, unitHeader } = this.state;
    var isValid = true;

    if (unitNumber == "" || unitHeader == "") isValid = false;

    if (unitNumber == "") {
      var error = this.state.errorMessage;
      error["emptyunitNumber"] = helperText.AddEditUnitAddDynamicContent.emptyModuleNumber;
      this.setState({ errorMessage: error });
      return isValid;
    }

    if (unitHeader == "") {
      var error = this.state.errorMessage;
      error["emptyunitHeader"] = helperText.AddEditUnitAddDynamicContent.emptyModuleHeader;
      this.setState({ errorMessage: error });
      return isValid;
    }

    return isValid;
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description: To save the Changes that have been done on that tab based on the tab id
  <summary>*/ 
  onSubmitNewModule = async (TabId) => {
    try {
        const userID = GetLocalStorageDataWithoutParse("UserID");
      const { 
        newHeader, 
        newDescription, 
        newLink, 
        uploadedPdfId,
        pdfCoverImageId,
      } = this.state;
      this.setState({
        isLoading: true,
      });
      if (this.validateVideoTab()) {
        var mduleDetailsModel = new ModuleDetailsModel();
        mduleDetailsModel.ModuleDetailId = this.state.moduleDetailId;
        mduleDetailsModel.moduleID = this.state.moduleID;
        mduleDetailsModel.moduleTabID = TabId;
        mduleDetailsModel.heading = this.state.newHeader;
        mduleDetailsModel.description = ConvertToHtml( this.state.newDescription );
        mduleDetailsModel.link = this.state.newLink;
        mduleDetailsModel.userID = userID;
        mduleDetailsModel.uploadedPdfId = uploadedPdfId;
        mduleDetailsModel.pdfCoverImageId = pdfCoverImageId;

        var response = await adminservice.AddEditModuleDetails( mduleDetailsModel );
        if (response.status == 200) {
          //success
          var imoduleID = this.state.moduleID;
          if (TabId == 2) {
            await this.GetModuleDataBasedOnModuleTabID(imoduleID, 2);
          } else if (TabId == 3) {
            await this.GetModuleDataBasedOnModuleTabID(imoduleID, 3);
          } else if (TabId == 4) {
            await this.GetModuleDataBasedOnModuleTabID(imoduleID, 4);
          } else if (TabId == 5) {
            await this.GetModuleDataBasedOnModuleTabID(imoduleID, 5);
          } else if (TabId == 6) {
            await this.GetModuleDataBasedOnModuleTabID(imoduleID, 6);
          }

          this.setState({
            popUpModalText: helperText.AddEditUnitAddDynamicContent.moduleDetailsUpdated,
            showPopupModal: true,
            messageType:MessageType.Success,
            showEditorModal: false,
            newHeader: "",
            newDescription: "",
            newLink: "",
            isLoading: false,
          });
        } else {
          this.setState({
            popUpModalText: helperText.AddEditUnitAddDynamicContent.updateDetailsError,
            showPopupModal: true,
            showEditorModal: false,
            messageType:MessageType.Failure,
            newHeader: "",
            newDescription: "",
            newLink: "",
            isLoading: false,
          });
        }
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  getVideoLinkEmbeded(url) {
    const regExp =  /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|.ly\/|watch\?v=|&v=)([^#&?]*).*/;
    if(url != null){
      const match = url.match(regExp);

      return match && match[2].length === 11
        ? "//www.youtube.com/embed/" + match[2]
        : null;
    }
   return ""
  }

  validateVideoTab = () => {
    const { newHeader, newDescription, newLink, tabId } = this.state;
    try {
      var isValid = true;
      //  if (TabId == 2) {
      if (newHeader == "" ) isValid = false;

      if (newHeader == "") {
        var error = this.state.errorTabMessage;
        error["emptynewHeader"] = helperText.AddEditUnitAddDynamicContent.emptyHeader;
        this.setState({ errorTabMessage: error });
      }

      // if (newDescription == "") {
      //   var error = this.state.errorTabMessage;
      //   error["emptynewDescription"] = "*Please enter a description.";
      //   this.setState({ errorTabMessage: error });
      // }
      if (tabId != 3 && tabId != 6) {
        if (newLink == "") {
          var error = this.state.errorTabMessage;
          error["emptynewLink"] = helperText.AddEditUnitAddDynamicContent.emptyLink;
          this.setState({ errorTabMessage: error });
          isValid = false;
        }
      }
      // }
      return isValid;
    } catch (error) {}
  };

  OpenEditor = (TabID) => {
    this.setState({
      showEditorModal: true,
      tabId: TabID,
      moduleDetailId: 0,
    });
  };

  EditContent = (TabID, ModuleDetailID, header, description, link,pdfImageName) => {
    this.setState({
      showEditorModal: true,
      tabId: TabID,
      moduleDetailId: ModuleDetailID,
      newHeader: header,
      newDescription: ConvertFromHTML(description),
      newLink: link,
      pdfCoverImageName:pdfImageName,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: RA
  description:To delete the content based on the module Detail Id and tab id 
  <summary>*/ 
  DeleteContent = async (ModuleDetailID, tabID) => {
    try{
      var moduleDetailresponse = await adminservice.DeleteModuleDetail(
        this.state.moduleID,
        ModuleDetailID,
        tabID
      );
      if (moduleDetailresponse.status == 200) {
        if (tabID == 2)
          this.setState({ moduleEssentialVideoList: moduleDetailresponse.data });
        else if (tabID == 3)
          this.setState({
            moduleEssentialReadingList: moduleDetailresponse.data,
          });
        else if (tabID == 4)
          this.setState({
            moduleEssentialBusinessList: moduleDetailresponse.data,
          });
        else if (tabID == 5)
          this.setState({ moduleEssentialAdviceList: moduleDetailresponse.data });
        else if (tabID == 6)
          this.setState({ moduleOtherGenaralList: moduleDetailresponse.data });
      }
    } catch (e) {
      return "";
    }
  };

  closeEditor = () => {
    this.setState({
      showEditorModal: false,
      errorTabMessage: {},
      newHeader: "",
      newDescription: "",
      newLink: "",
    });
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  closeUploadModal = () => {
    this.setState({
      showCommomUploadModel: false,
    });
  };

  showUploadModel = async (type) => {
    this.setState({
      showCommomUploadModel: true,
      typeofFile:type,
    });

    if(type=="YourUnitpdf"){
      this.setState({folderName: "ModulePDF",});
    }else{
      this.setState({folderName: "UnitCoverImages",});
    }
  };

  getFileMediaId = (data) => {
    if (data == null) {
      this.setState({
        popUpModalText: helperText.DataLoadError.imageUploadError,
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    } else {
      if (this.state.tabId == 3 && this.state.typeofFile=="PdfFile") {
        this.setState({
          uploadedPdfId: data.fileMediaID,
          imageName: data.docFileName,
          popUpModalText: helperText.EditCourseIteration.FileUploaded,
          showPopupModal: true,
          messageType:MessageType.Success,
        });
      } else if(this.state.tabId == 3 && this.state.typeofFile=="PdfCoverImage"){
        this.setState({
          pdfCoverImageId:data.fileMediaID,
          pdfCoverImageName:data.docFileName,
          popUpModalText: helperText.DataLoadError.imageUploaded,
          showPopupModal: true,
          messageType:MessageType.Success,
        })
      } else if(this.state.typeofFile=="YourUnitpdf"){
        this.setState({
          yourUnitPdfId:data.fileMediaID,
          yourUnitPdfName:data.docFileName,
          popUpModalText: helperText.AddEditUnitAddDynamicContent.modulePDFUploaded,
          showPopupModal: true,
          messageType:MessageType.Success,
        })
      } else{
        this.setState({
          unitCoverImageFileMediaID: data.fileMediaID,
          imageName: data.docFileName,
          popUpModalText: helperText.DataLoadError.imageUploaded,
          showPopupModal: true,
          messageType:MessageType.Success,
        });
      }
    }
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  oncategoryOneChecked(event) {
    const value = event.target.checked;
    this.setState({
      categoryOneSelected: value,
    });
  }

  oncategoryTwoChecked(event) {
    const value = event.target.checked;
    this.setState({
      categoryTwoSelected: value,
    });
  }

  oncategoryThreeChecked(event) {
    const value = event.target.checked;
    this.setState({
      categoryThreeSelected : value,
    });
    } 

  onUnitTypeChange=(event)=>{
    var unitValue=event.target.value
    this.setState({
      moduleTypeId:unitValue,
    })
    }

  handleTabClick = (tabId) => {
    this.setState({
    activeTab: tabId
    })
  }
  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      showEditorModal,
      moduleTabList,
      moduleYourUnitList,
      unitNumber,
      modulePartNumber,
      unitHeader,
      unitShortDescription,
      unitLongDescription,
      moduleEssentialVideoList,
      moduleEssentialReadingList,
      moduleEssentialBusinessList,
      moduleEssentialAdviceList,
      moduleOtherGenaralList,
      showCommomUploadModel,
      folderName,
      downloadUnitText,
      tabId,
      imageName,
      yourUnitPdfName,
      categoryOneSelected,
      categoryTwoSelected,
      categoryThreeSelected,
      moduleTypeId,
      pdfCoverImageName,
        currentPage,
        moduleId,
        activeTab
    } = this.state;
    return (
      <div className="overall-font" style={{ backgroundColor: "#F1F4F6" }}>
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}

        <main role="main" class="container admin-main-body ">
          <div class="row">
            <div class="col-md-12">
              <h5 class="mt-3 mb-3 gray">
                Manage Study Material - Create Module
                {/* <span class="info-icon">
            <a class="eye-icon"  title=""><img src="images/eye.png" /></a>
            <a  title="More informtion"><img src="images/info.png" /></a>
          </span> */}
              </h5>
            </div>

            <div class="col-sm-12 col-xs-12">
            <div className="row iterations-radio-sec">
                  <div className="col-12 col-sm-4">
                    {/* <input
                      type="checkbox"
                      id="yacht-small-craft"
                      name="course-iterations"
                      defaultValue="yacht-small-craft"
                      defaultChecked="checked"
                      checked={categoryOneSelected}
                      onChange={(e) => this.oncategoryOneChecked(e)}
                    /> */}
                      <div className="iterations-checkbox-table">
                      <div className="form-group">
                      <input 
                      type="checkbox" 
                      id="yacht-small-craft"
                      defaultChecked="checked"
                      checked={categoryOneSelected}
                      onChange={(e) => this.oncategoryOneChecked(e)} />
                      <label for="check1" className="iterations-checkbox-lable "></label>
                      </div>
                      </div>
                    <label htmlFor="yacht-small-craft" className="iterations-checkbox-content">
                      Professional Qualification in Yacht &amp; Small Craft
                      Marine Surveying
                      <img
                        src={coursebg1}
                        className="img-responsive"
                        alt="Commercial Ship"
                        style={{ border: "3px solid #6CFFD3" }}
                      />
                    </label>
                    <br />
                  </div>
                  <div className="col-12 col-sm-4">
                    {/* <input
                      type="checkbox"
                      id="commercial-ship"
                      name="course-iterations"
                      defaultValue="commercial-ship"
                      checked={categoryTwoSelected}
                      onChange={(e) => this.oncategoryTwoChecked(e)}
                    /> */}
                    <div className="iterations-checkbox-table">
                      <div className="form-group">
                      <input type="checkbox" 
                        id="commercial-ship" 
                        defaultValue="commercial-ship"
                        checked={categoryTwoSelected}
                        onChange={(e) => this.oncategoryTwoChecked(e)} 
                      />
                      <label for="check1" className="iterations-checkbox-lable "></label>
                      </div>
                      </div>
                    <label htmlFor="commercial-ship" className="iterations-checkbox-content">
                      Professional Qualification in Commercial Ship Marine
                      Surveying
                      <img
                        src={coursebg2}
                        className="img-responsive"
                        alt="Commercial Ship"
                        style={{ border: "3px solid #6CE8FF" }}
                      />
                    </label>
                  </div>

                  <div className="col-12 col-sm-4">
                    {/* <input
                      type="checkbox"
                      id="marine-corrosion"
                      name="course-iterations"
                      defaultValue="marine-corrosion"
                      checked={categoryThreeSelected}
                      onChange={(e) => this.oncategoryThreeChecked(e)}
                    /> */}
                    <div className="iterations-checkbox-table">
                      <div className="form-group">
                      <input type="checkbox" 
                      id="marine-corrosion" 
                      checked={categoryThreeSelected}
                      onChange={(e) => this.oncategoryThreeChecked(e)} />
                      <label for="check1" className="iterations-checkbox-lable "></label>
                      </div>
                      </div>
                    <label htmlFor="marine-corrosion" className="iterations-checkbox-content">
                      Professional Qualification in
                      <br /> Marine Corrosion
                      <img
                        src={coursebg3}
                        className="img-responsive"
                        alt="Commercial Ship"
                        style={{ border: "3px solid #FFD36C" }}
                      />
                    </label>
                  </div>
                </div>
            </div>

            <div class="col-md-12">
              <div id="nav-left-tabs">
                <div class="col-md-3 col-xs-3 tabs-cols">
                  <ul class="nav nav-tabs tabs-left sideways elementblock">
                    {moduleTabList.map((item) => {
                      return (
                          <li class={item.moduleTabId == activeTab ? "active" : ""}>
                              <a
                                                         

                                  onClick={() => this.handleTabClick(item.moduleTabId)} 
                            //data-toggle="tab"
                          >
                            {item.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div class="col-md-9 col-xs-9 tabs-cols">
                  <div class="tab-content">
                    { activeTab === 1 &&
                    <div class="tab-pane active" id="unit-v">
                      <form>
                      <div class="form-group">
                          <label
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            class="col-form-label"
                          >
                            Module Type
                          </label>
                          <select
                            className="form-control1 courses"
                            id="courses"
                            name="courses"
                            value={moduleTypeId}
                            onChange={e=>this.onUnitTypeChange(e)}
                            style={{
                              marginLeft: "20px",
                              marginRight: "20px",
                              width: "35%",
                            }}
                          >
                            <option value={1}>Core Module </option>
                            <option value={2}>Advanced Module </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <label
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            class="col-form-label"
                          >
                            Module Number
                          </label>
                          <input
                            style={{
                              marginLeft: "20px",
                              marginRight: "20px",
                              width: "95%",
                            }}
                            type="number"
                            class="form-control"
                            name="unitNumber"
                            value={unitNumber}
                            onChange={this.onUnitNumberTextChange}
                            placeholder="Enter Module Number"
                          />
                        </div>
                        <div
                          style={{
                            display: this.state.errorMessage.emptyunitNumber
                              ? "contents"
                              : "none",
                            color: "red",
                          }}
                        >
                          {this.state.errorMessage.emptyunitNumber}
                        </div>
                        <div class="form-group">
                          <label
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            class="col-form-label"
                          >
                            Module part
                          </label>
                          <input
                            style={{
                              marginLeft: "20px",
                              marginRight: "20px",
                              width: "95%",
                            }}
                            type="number"
                            class="form-control"
                            value={modulePartNumber}
                            name="modulePartNumber"
                            onChange={this.onUnitNumberTextChange}
                            placeholder="Enter Module Number"
                          />
                        </div>
                        <div class="form-group">
                          <label
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            class="col-form-label"
                          >
                            Module Header
                          </label>
                          <input
                            style={{
                              marginLeft: "20px",
                              marginRight: "20px",
                              width: "95%",
                            }}
                            type="text"
                            class="form-control"
                            value={unitHeader}
                            onChange={this.onHeaderTextChange}
                            placeholder="Enter Module Header"
                          />
                        </div>
                        <div
                          style={{
                            display: this.state.errorMessage.emptyunitHeader
                              ? "contents"
                              : "none",
                            color: "red",
                          }}
                        >
                          {this.state.errorMessage.emptyunitHeader}
                        </div>
                        <div class="form-group">
                          <label
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            class="col-form-label"
                          >
                            Module brief description
                          </label>
                          <textarea
                            style={{
                              marginLeft: "20px",
                              marginRight: "20px",
                              width: "95%",
                            }}
                            class="form-control"
                            rows="3"
                            value={unitShortDescription}
                            onChange={this.onunitShortDescriptionTextChange}
                            placeholder="Enter Module brief description"
                          />
                        </div>
                        <div class="form-group">
                          <label
                            style={{ marginLeft: "10px", marginRight: "10px" }}
                            class="col-form-label"
                          >
                            Description
                          </label>
                          <textarea
                            style={{
                              marginLeft: "20px",
                              marginRight: "20px",
                              width: "95%",
                            }}
                            class="form-control"
                            rows="6"
                            value={unitLongDescription}
                            onChange={this.onunitLongDescriptionTextChange}
                            placeholder="Enter module description"
                          />
                        </div>
                        <div style={{ marginLeft: "20px" }} class="form-group">
                          <a
                            className=" btn-upload"
                            onClick={() => this.showUploadModel("YourUnit")}
                          >
                            Upload Module Cover Image
                          </a>
                          <span style={{ paddingLeft: "8px" }}>
                            {imageName}
                          </span>
                        </div>
                        <div style={{ marginLeft: "20px" }} class="form-group">
                          <a
                            className=" btn-upload"
                            onClick={() => this.showUploadModel("YourUnitpdf")}
                          >
                            Upload Module pdf
                          </a>
                          <span style={{ paddingLeft: "8px" }}>
                            {yourUnitPdfName}
                          </span>
                        </div>
                        <div style={{ marginLeft: "20px" }} class="form-group">
                          <input
                            style={{ padding: "0 10px" }}
                            className=""
                            type="text"
                            name="downloadUnitText"
                            placeholder="Enter download Module text"
                            onChange={this.onTextChange}
                            value={downloadUnitText}
                          />
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={() => this.onSubmitYourUnitTab()}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                    }
                    { activeTab === 2 &&
                    <div class="tab-pane active" id="essentialvideos-v">
                      <div class="modal-int-add">
                        <div
                          class="modal-header elementblock"
                          onClick={() => this.OpenEditor(2)}
                        >
                          <h5 class="modal-title">
                            Add New Item{" "}
                            <span class="add-item-icon">
                              <a
                                title="Add New Item"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="modal-int-box-video">
                        <div class="modal-header">
                          <h4 class="modal-title">
                            Essential Videos to watch{" "}
                          </h4>
                        </div>
                        <div class="modal-body">
                          <table class="table-responsive">
                            {moduleEssentialVideoList.map((item) => {
                              return (
                                <tr>
                                  <div class="modal-header icon-modal-header">
                                    <h5 class="modal-title">
                                      <b>{item.heading}</b>
                                      <div class="edit-icon">
                                        <a
                                          //href="#"
                                          title="Edit"
                                          onClick={() =>
                                            this.EditContent(
                                              2,
                                              item.moduleDetailID,
                                              item.heading,
                                              item.description,
                                              item.link,
                                              item.pdfCoverImageName
                                            )
                                          }
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                        >
                                          <i
                                            class="fa fa-pencil pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          onClick={() =>
                                            this.DeleteContent(
                                              item.moduleDetailID,
                                              2
                                            )
                                          }
                                        >
                                          <i
                                            class="fa fa-trash-o trash"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </h5>
                                  </div>
                                  <td>
                                    <iframe
                                      width="380"
                                      height="220"
                                      src={this.getVideoLinkEmbeded(item.link)}
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                    <br></br>
                                  </td>
                                  <td class="video-text">
                                    <p
                                      className="overflow-content-fix"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></p>
                                  </td>
                                </tr>
                              );
                            })}
                            {/* <tr class="modal-int-box-btn">
                    <td  colspan="2"><a >
                    Load more</a></td>
                  </tr> */}
                          </table>
                        </div>
                      </div>
                    </div>
                    }
                    { activeTab === 3 &&
                    <div class="tab-pane active" id="essentialreading-v">
                      <div class="modal-int-add">
                        <div
                          class="modal-header elementblock"
                          onClick={() => this.OpenEditor(3)}
                        >
                          <h5 class="modal-title">
                            Add New Item{" "}
                            <span class="add-item-icon">
                              <a
                                title="Add New Item"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="modal-int-box-video">
                        <div class="modal-header ">
                          <h4 class="modal-title">Essential reading </h4>
                        </div>
                        <div class="modal-body">
                          <table class="table-responsive">
                            {moduleEssentialReadingList.map((item) => {
                              return (
                                <tr>
                                  <div class="modal-header icon-modal-header">
                                    <h5 class="modal-title">
                                      <b>{item.heading}</b>
                                      <div class="edit-icon">
                                        <a
                                          //href="#"
                                          title="Edit"
                                          onClick={() =>
                                            this.EditContent(
                                              3,
                                              item.moduleDetailID,
                                              item.heading,
                                              item.description,
                                              item.link,
                                              item.pdfCoverImageName
                                            )
                                          }
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                        >
                                          <i
                                            class="fa fa-pencil pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          onClick={() =>
                                            this.DeleteContent(
                                              item.moduleDetailID,
                                              3
                                            )
                                          }
                                        >
                                          <i
                                            class="fa fa-trash-o trash"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </h5>
                                  </div>
                                  {item.link == "" ? (
                                    <td>
                                      {/* <embed
                                        src={item.uploadPdfFilePath}
                                        width="400px"
                                        height="220px"
                                      />{" "} */}
                                      <a href={item.link} target="_blank">
                                      {item.link}
                                    </a>
                                    </td>
                                  ) : (
                                    <td>
                                      {/* <b>{item.heading}</b> */}
                                      {/* <iframe
                                        width="380"
                                        height="220"
                                        src={this.getVideoLinkEmbeded(
                                          item.link
                                        )}
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                      ></iframe> */}
                                       {/* <embed
                                        src={item.link}
                                        width="400px"
                                        height="220px"
                                      /> */}
                                      <a href={item.link} target="_blank">
                                      {item.link}
                                    </a>
                                      <br></br>
                                    </td>
                                  )}
                                  <td class="video-text">
                                    <p className="overflow-content-fix"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></p>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                                    </div>
                    }
                    { activeTab === 4 &&
                    <div class="tab-pane active" id="essentialbusiness-v">
                      <div class="modal-int-add">
                        <div
                          class="modal-header elementblock"
                          onClick={() => this.OpenEditor(4)}
                        >
                          <h5 class="modal-title">
                            Add New Item{" "}
                            <span class="add-item-icon">
                              <a
                                title="Add New Item"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="modal-int-box-video">
                        <div class="modal-header">
                          <h4 class="modal-title">
                            Essential business management videos{" "}
                          </h4>
                        </div>
                        <div class="modal-body">
                          <table class="table-responsive">
                            {moduleEssentialBusinessList.map((item) => {
                              return (
                                <tr>
                                  <div class="modal-header icon-modal-header">
                                    <h5 class="modal-title">
                                      <b>{item.heading}</b>
                                      <div class="edit-icon">
                                        <a
                                          //href="#"
                                          title="Edit"
                                          onClick={() =>
                                            this.EditContent(
                                              4,
                                              item.moduleDetailID,
                                              item.heading,
                                              item.description,
                                              item.link,
                                              item.pdfCoverImageName
                                            )
                                          }
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                        >
                                          <i
                                            class="fa fa-pencil pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          onClick={() =>
                                            this.DeleteContent(
                                              item.moduleDetailID,
                                              4
                                            )
                                          }
                                        >
                                          <i
                                            class="fa fa-trash-o trash"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </h5>
                                  </div>
                                  <td>
                                    <iframe
                                      width="380"
                                      height="220"
                                      src={this.getVideoLinkEmbeded(item.link)}
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                    <br></br>
                                  </td>
                                  <td class="video-text">
                                    <p
                                      className="overflow-content-fix"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></p>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                    }
                    { activeTab === 5 &&
                    <div class="tab-pane active" id="essentialadvice-v">
                      <div class="modal-int-add">
                        <div
                          class="modal-header elementblock"
                          onClick={() => this.OpenEditor(5)}
                        >
                          <h5 class="modal-title">
                            Add New Item{" "}
                            <span class="add-item-icon">
                              <a
                                title="Add New Item"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="modal-int-box-video">
                        <div class="modal-header">
                          <h4 class="modal-title">Essential advice to Help</h4>
                        </div>
                        <div class="modal-body">
                          <table class="table-responsive">
                            {moduleEssentialAdviceList.map((item) => {
                              return (
                                <tr>
                                  <div class="modal-header icon-modal-header">
                                    <h5 class="modal-title">
                                      <b>{item.heading}</b>
                                      <div class="edit-icon">
                                        <a
                                          //href="#"
                                          title="Edit"
                                          onClick={() =>
                                            this.EditContent(
                                              5,
                                              item.moduleDetailID,
                                              item.heading,
                                              item.description,
                                              item.link,
                                              item.pdfCoverImageName
                                            )
                                          }
                                          data-toggle="modal"
                                          data-target="#exampleModal"
                                        >
                                          <i
                                            class="fa fa-pencil pencil"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                        <a
                                          title="Delete"
                                          onClick={() =>
                                            this.DeleteContent(
                                              item.moduleDetailID,
                                              5
                                            )
                                          }
                                        >
                                          <i
                                            class="fa fa-trash-o trash"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      </div>
                                    </h5>
                                  </div>
                                  <td>
                                    <iframe
                                      width="380"
                                      height="220"
                                      src={this.getVideoLinkEmbeded(item.link)}
                                      title="YouTube video player"
                                      frameborder="0"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                    <br></br>
                                  </td>
                                  <td class="video-text">
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></p>
                                  </td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                                    </div>
                    }
                    { activeTab === 6 &&
                    <div class="tab-pane active" id="othergeneral-v">
                      <div class="modal-int-add">
                        <div
                          class="modal-header elementblock"
                          onClick={() => this.OpenEditor(6)}
                        >
                          <h5 class="modal-title">
                            Add New Item{" "}
                            <span class="add-item-icon">
                              <a
                                title="Add New Item"
                                data-toggle="modal"
                                data-target="#exampleModal"
                              >
                                <i
                                  class="fa fa-plus-circle"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </span>
                          </h5>
                        </div>
                      </div>
                      <div class="modal-int-box-video">
                        <div class="modal-header">
                          <h4 class="modal-title">Other General Resources </h4>
                        </div>
                        <div class="modal-body">
                          {moduleOtherGenaralList.map((item) => {
                            return (
                              <div class="modal-int-box">
                                <div class="modal-header icon-modal-header">
                                  <h5 class="modal-title" style={{display:'contents'}}>
                                    Item {item.moduleDetailId}
                                    <div class="edit-icon">
                                      <a
                                        title="Edit"
                                        data-toggle="modal"
                                        data-target="#exampleModal"
                                        onClick={() =>
                                          this.EditContent(
                                            6,
                                            item.moduleDetailID,
                                            item.heading,
                                            item.description,
                                            item.link,
                                            item.pdfCoverImageName
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-pencil pencil"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                      <a
                                        title="Delete"
                                        onClick={() =>
                                          this.DeleteContent(
                                            item.moduleDetailID,
                                            6
                                          )
                                        }
                                      >
                                        <i
                                          class="fa fa-trash-o trash"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    </div>
                                  </h5>
                                </div>
                                <div class="modal-body">
                                  <table class="table-responsive">
                                    <tr>
                                      <td>
                                        <b>Header :</b>
                                      </td>
                                      <td>{item.heading}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Description :</b>
                                      </td>
                                      <td>
                                        <div
                                          style={{
                                            marginLeft: "25px",
                                            marginTop: "5px",
                                          }}
                                          dangerouslySetInnerHTML={{
                                            __html: item.description,
                                          }}
                                        ></div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Link :</b>
                                      </td>
                                      <td>
                                        <a href={item.link} target="_blank">
                                          {item.link}
                                        </a>
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                                    </div>
                    }
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>

              <section>
                {showEditorModal && (
                  <div
                    className="modal opt-in-box modal-background show unit-modal"
                    id="changePasswordModal"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="changePasswordModalTitle"
                  >
                    <div class="modal-dialog" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          {/* <h4 class="modal-title" id="exampleModalLabel">Add Items</h4> */}
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.closeEditor}
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <form>
                            <div class="form-group">
                              <label class="col-form-label">Heading</label>
                              <input
                                type="text"
                                class="form-control"
                                value={this.state.newHeader}
                                onChange={this.onnewHeaderTextChange}
                                placeholder="Enter module header"
                              />
                            </div>
                            <div
                              style={{
                                display: this.state.errorTabMessage
                                  .emptynewHeader
                                  ? "contents"
                                  : "none",
                                color: "red",
                              }}
                            >
                              {this.state.errorTabMessage.emptynewHeader}
                            </div>
                            <div class="form-group">
                              <label class="col-form-label">Description</label>
                              {/* <textarea class="form-control" rows="3" value={this.state.newDescription} onChange={this.onnewDescriptionTextChange} placeholder="Enter module description" />
                               */}
                              <Editor
                                editorStyle={{ height: 110 }}
                                editorState={this.state.newDescription}
                                wrapperClassName="demo-wrapper"
                                editorClassName="editer-content"
                                toolbarClassName="toolbar-class"
                                onEditorStateChange={(editorState) =>
                                  this.onnewDescriptionTextChange(
                                    editorState,
                                    "message"
                                  )
                                }
                                toolbar={{
                                  options: ["inline"],
                                  inline: {
                                    inDropdown: false,
                                    options: ["bold", "italic", "underline"],
                                  },
                                }}
                              />
                            </div>
                            <div
                              style={{
                                display: this.state.errorTabMessage
                                  .emptynewDescription
                                  ? "contents"
                                  : "none",
                                color: "red",
                              }}
                            >
                              {this.state.errorTabMessage.emptynewDescription}
                            </div>
                            {tabId == 3 ? (
                              <div>
                              <div className="form-group">
                                <label
                                  className="col-form-label"
                                  style={{ marginRight: "5px" }}
                                >
                                  Pdf Upload
                                </label>
                                <button
                                  type="button"
                                  className="upload"
                                  onClick={() => this.showUploadModel("PdfFile")}
                                >
                                  Upload
                                </button>
                              </div>
                              {tabId == 3 ? (
                              <div className="form-group">
                                <a
                                  className=" btn-upload"
                                  onClick={() => this.showUploadModel("PdfCoverImage")}
                                >
                                  PDF Cover Image
                                </a>
                                <span style={{marginLeft:'5px'}}>{pdfCoverImageName}</span>
                              </div>
                            ) : (
                              ""
                            )}
                              <div className="form-group">
                              <label className="col-form-label" style={{width:'100%'}}>
                                Embed Link Code
                              </label>
                              <h5 >*Please add the full video links for each item instead of Bitlinks </h5>
                              <textarea
                                className="form-control"
                                rows="4"
                                value={this.state.newLink}
                                onChange={this.onnewLinkTextChange}
                                placeholder="Enter module link"
                              />
                            </div>
                            </div>
                            ) : (
                              <div className="form-group">
                                <label className="col-form-label" style={{width:'100%'}}>
                                  Embed Link Code
                                </label>
                                <h5 >*Please add the full video links for each item instead of Bitlinks </h5>
                                <textarea
                                  className="form-control"
                                  rows="4"
                                  value={this.state.newLink}
                                  onChange={this.onnewLinkTextChange}
                                  placeholder="Enter module link"
                                />
                              </div>
                            )}
                            <div
                              style={{
                                display: this.state.errorTabMessage.emptynewLink
                                  ? "contents"
                                  : "none",
                                color: "red",
                              }}
                            >
                              {this.state.errorTabMessage.emptynewLink}
                            </div>
                            
                          </form>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={this.closeEditor}
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={() =>
                              this.onSubmitNewModule(this.state.tabId)
                            }
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>
              <div class="col-md-12 col-xs-12">
                <button
                  type="button"
                  class="btn back-button"
                  onClick={() => history.push("/ManageStudyMaterial")}
                >
                  <i class="fa fa-angle-left"></i> Back
                </button>
              </div>
            </div>
          </div>
        </main>
        <AdminFooter />
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
        {showCommomUploadModel && (
          <CommonUploadForm
            closeModal={this.closeUploadModal}
            getFileMediaId={this.getFileMediaId}
            folderName={folderName}
            currentPage={currentPage}
            moduleId={moduleId}
          />
        )}
      </div>
    );
  }
}

export default AddEditUnitAddDynamicContent;
