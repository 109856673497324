import React, { Component } from "react";
import Highcharts from "highcharts";
import Exporting from "highcharts/modules/exporting";
import spam from "../../assets/spam.png";
import messageicon from "../../assets/message-icon.png";
import subicon from "../../assets/sub-icon.png";
import bookmarkicon from "../../assets/bookmark-icon.png";
import checked from "../../assets/checked.png";
import info from "../../assets/info.png";
//import reportmagazine from "../../assets/thumbnail_Report-DEC-2021-cover.jpg";
import { studentService } from "../../_services/student.service";
import Header from "../../_components/Header";
import Loader from "../../_helpers/Loader";
import AdvanceCoursePopupModal from "../../_helpers/AdvanceCoursePopupModal";
import { history } from "../../_helpers/history";
import StudentFooter from "../../_components/StudentFooter";
import { MessageType } from "../../_helpers/Enums";
import PopupModal from "../../_helpers/PopupModal";
import DataTable from "react-data-table-component";
import { helperText } from "../../_helpers/helpertext";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

// Initialize exporting module.
Exporting(Highcharts);

class StudentHomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            showPopupModal: false,
            popUpModalText: "",
            messageType: 0,
            studentId: 0,
            isOnHold: false,
            coreUnit: [],
            specialistUnit: [],
            showAdvancedCourseModal: false,
            courseDetails: [],
            categoryName: "",
            showAdvanceCourseButton: false,
            MarineNewsList: [],
            MarineEventsList: [],
            reportmagazineImg: "",
            reportmagazineDesc: "",
            reportmagazineLink: "",
        };
    }

    /* <summary>
    date: 15-11-2021
    Name: VD
    description: To get the details for graph
    <summary>*/
    componentDidMount = async () => {
        try {
            var studentId = GetLocalStorageDataWithoutParse("UserID");
            this.setState({
                isLoading: true,
                studentId: studentId,
            });
            var response = await studentService.GetCourseGraphDetails(studentId);
            if (response.status == 200) {
                this.setState({
                    isOnHold: response.data.isOnHold,
                    coreUnit: response.data.coreUnits,
                    specialistUnit: response.data.specialistUnits,
                    categoryName: response.data.categoryName,
                    showAdvanceCourseButton: response.data.showAdvanceCourseButton,
                });

                //localStorage.setItem("StudentOnHold", response.data.isOnHold);
                this.showcoreUnitPieChart();
                //this.showspecialistUnittPieChart();
            } else {
                this.setState({
                    popUpModalText: helperText.DataLoadError.loadError,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }
            var MarineNewsresponse = await studentService.GetMarineNews();
            if (MarineNewsresponse.status == 200) {
                this.setState({
                    MarineNewsList: MarineNewsresponse.data,
                });
            } else {
                this.setState({
                    popUpModalText: helperText.IIms.iimsNewsError,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }

            var MarineEventsresponse = await studentService.GetMarineEvents();
            if (MarineEventsresponse.status == 200) {
                this.setState({
                    MarineEventsList: MarineEventsresponse.data,
                });
            } else {
                this.setState({
                    popUpModalText: helperText.IIms.iimsEventError,
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }

            var ReportMagazineresponse = await studentService.GetReportMagazine();
            if (ReportMagazineresponse.status == 200) {
                this.setState({
                    reportmagazineImg: ReportMagazineresponse.data.reportMagazineImage,
                    reportmagazineDesc:
                        ReportMagazineresponse.data.reportMagazineDescription,
                    reportmagazineLink: ReportMagazineresponse.data.reportMagazineLink,
                });
            } else {
                this.setState({
                    popUpModalText: "error while loading report magazine",
                    showPopupModal: true,
                    messageType: MessageType.Failure,
                });
            }

            this.setState({
                isLoading: false,
            });
        } catch (ex) {
            return "";
        }
    };

    /* <summary>
    date: 15-11-2021
    Name: VD
    description: This is used to display the core Module pie chart
    <summary>*/
    showcoreUnitPieChart = () => {
        const { coreUnit, specialistUnit } = this.state;

        if (!Highcharts.charts.length) {
            // Radialize the colors
            Highcharts.setOptions({
                colors: Highcharts.map(
                    Highcharts.getOptions().colors,
                    function (color) {
                        return {
                            radialGradient: {
                                cx: 0.5,
                                cy: 0.3,
                                r: 0.7,
                            },
                            stops: [
                                [0, color],
                                [1, Highcharts.color(color).brighten(-0.3).get("rgb")], // darken
                            ],
                        };
                    }
                ),
            });
        }
        // this.state.coreUnit.map((item) => {
        // Build the chart
        Highcharts.chart(coreUnit.unitName, {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: coreUnit.unitName,
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                        distance: -60,
                        connectorColor: "silver",
                    },
                },
            },
            series: [
                {
                    name: "Modules",
                    data: [
                        { name: "Completed", y: coreUnit.completedPercent }, //item.completed
                        { name: "Pending", y: coreUnit.pendingPercent }, //item.pending
                    ],
                },
            ],
        });

        Highcharts.chart(specialistUnit.unitName, {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie",
            },
            title: {
                text: specialistUnit.unitName,
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            accessibility: {
                point: {
                    valueSuffix: "%",
                },
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: true,
                        format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                        distance: -60,
                        connectorColor: "silver",
                    },
                },
            },
            series: [
                {
                    name: "Modules",
                    data: [
                        { name: "Completed", y: specialistUnit.completedPercent }, //item.completed
                        { name: "Pending", y: specialistUnit.pendingPercent }, //item.pending
                    ],
                },
            ],
        });
        // });
    };

    closeModal = () => {
        this.setState({
            showPopupModal: false,
        });
    };

    closeAdvanceCourseModal = () => {
        this.setState({
            showAdvancedCourseModal: false,
        });
    };

    afterSubmitcloseAdvanceCourseModal = () => {
        this.setState({
            showAdvancedCourseModal: false,
            popUpModalText: helperText.AdvanceCoursePopupModalText.mailsent,
            showPopupModal: true,
            messageType: MessageType.Success,
        });
    };

    showAdvancedCoursePopupModal = () => {
        this.setState({
            showAdvancedCourseModal: true,
        });
    };

    MarineNewsHeader = [
        {
            name: <th className="align-middle text-center">Marine News</th>,
            cell: (row) => (
                <td className="center">
                    <a target="_blank" href={row.newsLink}>
                        {row.newsTitle}
                    </a>
                </td>
            ),
            center: true,
            grow: 1,
        },
    ];

    MarineEventsHeader = [
        {
            name: <th className="align-middle text-center">Marine Events</th>,
            cell: (row) => (
                <td className="center">
                    <a target="_blank" href={row.eventsLink}>
                        {row.eventsTitle}
                    </a>
                </td>
            ),
            center: true,
            grow: 1,
        },
    ];

    render() {
        const {
            isLoading,
            popUpModalText,
            showPopupModal,
            messageType,
            isOnHold,
            coreUnit,
            specialistUnit,
            showAdvancedCourseModal,
            categoryName,
            showAdvanceCourseButton,
            MarineNewsList,
            MarineEventsList,
            reportmagazineImg,
            reportmagazineDesc,
            reportmagazineLink,
        } = this.state;
        return (
            <div>
                <Header currentTab="Home" />
                {isLoading && <Loader />}
                {/* /* --- Body Code ---* /  */}
                <div className="student-footer-body-space">
                    <section id="coursenbanner" className="home-section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <p>
                                        {" "}
                                        <b className="p-title">Course Progress </b>
                                        {/* <span className="last-attend">
                          Last Attended on :<b> 06 July 2021, 08:10</b>
                        </span> */}
                                        {isOnHold ? (
                                            <div>
                                                <span>
                                                    <img src={spam} alt="course" /> Course On-Hold
                                                </span>
                                                <span style={{ cursor: "pointer" }}>
                                                    <img src={messageicon} alt="course" />
                                                    <a onClick={() => history.push("/SendEmail")}>
                                                        To resume course please email to admin
                                                    </a>
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </p>
                                    <div className="row coursenbanner-row">
                                        {/* {this.state.coreUnit.map((item) => {
                              return ( */}
                                        <div className="col-md-6">
                                            <h3>{categoryName}</h3>
                                            {/* <h4>Core Modules</h4> */}
                                            {/* <div id="piechart" className="piechart-class" /> */}

                                            <figure>
                                                <div id={coreUnit.unitName}></div>

                                                <p>
                                                    Modules{" "}
                                                    <a
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => history.push("/AllCourses")}
                                                    >
                                                        {" "}
                                                        View all
                                                    </a>
                                                </p>
                                                <div className="piechart-box">
                                                    <table className="table-responsive">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <img src={subicon} alt="course" />
                                                                    Submissions
                                                                </td>
                                                                <td>= {coreUnit.coreSubmissionCount}</td>
                                                                {this.state.coreUnit
                                                                    .submissionAssignmentModel != undefined &&
                                                                    coreUnit.submissionAssignmentModel.map(
                                                                        (item) => {
                                                                            return (
                                                                                <td className>
                                                                                    <div className="piechart-a">
                                                                                        <p>{item.modulePartNumber}</p>
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tr>
                                                            {/* <tr>
                                    <td>
                                      <img src={bookmarkicon} alt="course" />
                                      Bookmarked
                                    </td>
                                    <td>= 0</td>
                                    <td>
                                    </td>
                                    <td />
                                    <td />
                                  </tr> */}
                                                            <tr>
                                                                <td>
                                                                    <img src={checked} alt="course" />
                                                                    Completed{" "}
                                                                </td>
                                                                <td>= {coreUnit.coreCompletedCount}</td>
                                                                {this.state.coreUnit.completedAssignmentModel !=
                                                                    undefined &&
                                                                    coreUnit.completedAssignmentModel.map(
                                                                        (item) => {
                                                                            return (
                                                                                <td>
                                                                                    <div className="piechart-a">
                                                                                        <p>{item.modulePartNumber}</p>
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </figure>
                                        </div>

                                        <div className="col-md-6">
                                            <h3>{categoryName}</h3>
                                            {/* <h4>Core Modules</h4> */}
                                            {/* <div id="piechart" className="piechart-class" /> */}

                                            <figure>
                                                <div id={specialistUnit.unitName}></div>

                                                <p>
                                                    Modules{" "}
                                                    <a
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => history.push("/AllCourses")}
                                                    >
                                                        {" "}
                                                        View all
                                                    </a>
                                                </p>
                                                <div className="piechart-box">
                                                    <table className="table-responsive">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <img src={subicon} alt="course" />
                                                                    Submissions
                                                                </td>
                                                                <td>
                                                                    = {specialistUnit.specialSubmissionCount}
                                                                </td>
                                                                {this.state.specialistUnit
                                                                    .submissionAssignmentModel != undefined &&
                                                                    specialistUnit.submissionAssignmentModel.map(
                                                                        (item) => {
                                                                            return (
                                                                                <td>
                                                                                    <div className="piechart-a">
                                                                                        <p>{item.modulePartNumber}</p>
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tr>
                                                            {/* <tr>
                                    <td>
                                      <img src={bookmarkicon} alt="course" />
                                      Bookmarked
                                    </td>
                                    <td>= 0</td>
                                    <td >
                                       
                                    </td>
                                    <td   />
                                    <td   />
                                  </tr>  */}
                                                            <tr>
                                                                <td>
                                                                    <img src={checked} alt="course" />
                                                                    Completed{" "}
                                                                </td>
                                                                <td>= {specialistUnit.specialComletedCount}</td>
                                                                {this.state.specialistUnit
                                                                    .completedAssignmentModel != undefined &&
                                                                    specialistUnit.completedAssignmentModel.map(
                                                                        (item) => {
                                                                            return (
                                                                                <td>
                                                                                    <div className="piechart-a">
                                                                                        <p>{item.modulePartNumber}</p>
                                                                                    </div>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    {showAdvanceCourseButton ? (
                                        <div className="opt-in-btn">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => this.showAdvancedCoursePopupModal()}
                                            >
                                                Opt-in for Advanced Course
                                            </button>
                                            {/* <a 
                          className="info-icon"
                          title="More informtion"
                        >
                          <img src={info} />
                        </a> */}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div>
                                        <div className="student-box1">
                                            {/* <div style={{height:"500px", overflowY: "scroll"}}>
                      <DataTable
                        columns={this.MarineNewsHeader}
                        data={MarineNewsList}
                        noHeader={true}
                      />
                      </div>
                      <hr />                       */}
                                            <div style={{ height: "250px", overflowY: "scroll" }}>
                                                <DataTable
                                                    columns={this.MarineNewsHeader}
                                                    data={MarineNewsList}
                                                    noHeader={true}
                                                />
                                            </div>
                                            <hr />
                                            <div style={{ height: "250px", overflowY: "scroll" }}>
                                                <DataTable
                                                    columns={this.MarineEventsHeader}
                                                    data={MarineEventsList}
                                                    noHeader={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="student-home-sec3">
                        <div className="container">
                            <div className="row">
                                <div className="courselist col-md-6" id="content">
                                    <br />
                                    <div className="student-box1">
                                        <h4>Message from IIMS</h4>
                                        {/* <iframe
                      width="100%"
                      height="290px"
                      src="https://www.youtube.com/embed/ZAsMEoHkPxU"
                      title="YouTube video player"
                      frameBorder={0}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                    <p>
                      Video clip explains you about taking the course... Lorem
                      ipsum dolor sit amet, consectetur adipisicing elit. Ad
                      aperiam autem cumque deleniti dicta iusto laboriosam
                      laudantium omnis, possimus praesentium provident quam
                      quas, sapiente sint, ut! Adipisci aliquid assumenda
                      consequuntur
                    </p>
                    <div className="student-box1-atag">
                      <a>See All...</a>
                    </div> */}
                                        <div
                                            style={{
                                                paddingTop: "30%",
                                                display: "grid",
                                                textAlign: "center",
                                            }}
                                        >
                                            <h4>Coming Soon</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="courselist col-md-3" id="content">
                                    <br />
                                    <div className="student-box1">
                                        <h4>Report Magazines</h4>
                                        <img
                                            src={reportmagazineImg}
                                            alt="Magazines"
                                            className="img-responsive"
                                        />
                                        <br />
                                        <h4>{reportmagazineDesc}</h4>
                                        <div className="student-box1-atag">
                                            <a href={reportmagazineLink} target="_blank">
                                                See All...
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="courselist col-md-3" id="content">
                                    <br />
                                    <div className="student-box1">
                                        <h4>Student Noticeboard</h4>
                                        <p>News, events and important student info...</p>
                                        <div className="student-box1-atag">
                                            <a
                                                href="https://www.iims.org.uk/student-noticeboard/"
                                                target="_blank"
                                            >
                                                Read more...
                                            </a>
                                        </div>
                                        <hr />
                                        <h4>Marine Surveyor Resources</h4>
                                        <p>An eclectic collection of helpful resources.....</p>
                                        <div className="student-box1-atag">
                                            <a
                                                href="https://www.iims.org.uk/knowledge-centre/marine-resources/"
                                                target="_blank"
                                            >
                                                Check here...
                                            </a>
                                        </div>
                                        <hr />
                                        <h4>IIMS YouTube Channel </h4>
                                        <p>Over 300 videos produced and selected by the IIMS...</p>
                                        <div className="student-box1-atag">
                                            <a
                                                href="https://www.youtube.com/c/MarineSurveyingIIMS"
                                                target="_blank"
                                            >
                                                Read more...
                                            </a>
                                        </div>
                                        <hr />
                                        <h4>News Bulletin Back Issues</h4>
                                        <p>Monthly news and events updates from Head Office...</p>
                                        <div className="student-box1-atag">
                                            <a
                                                href="https://www.iims.org.uk/news-bulletins/"
                                                target="_blank"
                                            >
                                                Check here...
                                            </a>
                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <hr />
                                </div>
                                <div className="col-md-12 cotact-btn-sec">
                                    <h3>Contact Us</h3>
                                    <a href="mailto:education@iims.org.uk">
                                        education@iims.org.uk
                                    </a>
                                    <a href="tel:+44 (0) 2392 385223">+44 (0) 2392 385223</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <StudentFooter />
                {showAdvancedCourseModal && (
                    <AdvanceCoursePopupModal
                        closeModal={this.closeAdvanceCourseModal}
                        closeSubmitmodal={this.afterSubmitcloseAdvanceCourseModal}
                    />
                )}
                {showPopupModal && (
                    <PopupModal
                        closeModal={this.closeModal}
                        Text={popUpModalText}
                        messageType={messageType}
                    />
                )}
            </div>
        );
    }
}

export default StudentHomePage;
