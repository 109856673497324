import React, { Component } from "react";
import { Input, Row, Col, Button, Container } from "reactstrap";
import Loader from "../_helpers/Loader";
import { uploadfileservice } from "../_services/uploadfile.service";

const validFileFormats = ["pdf", "xls", "xlsx", "png","PNG", "jpeg", "JPEG", "jpg","JPG","txt","doc","docx"];
const errorStyle = {
  color: "Red",
  fontStyle: "bold",
  fontSize: 14,
  marginBottom: "10px",
};
const errorStrings = {
  emptyGroupName: "Please select a Name",
  emptyName: "Please enter the Document Name",
  emptyVersion: "Please enter the Document Version",
  emptyFile: "Please attach a valid Document",
  couldNotSave: "There was an error when saving the Document.",
};

let docTypedropDownMenuModifiers = {
  setMaxHeight: {
    enabled: true,
    order: 890,
    fn: (data) => {
      return {
        ...data,
        styles: {
          ...data.styles,
          overflow: "auto",
          maxHeight: 150,
          top: "40px",
          allowMultiple: true,
          fontSize: "10pt",
          width: "100%",
          transform: "'translate3d(0, 0, 0) !important'",
        },
      };
    },
  },
};

const messages = {
  UploadTextExcel: "Upload the file ",
  UploadTmaqFile:"Upload the file in .pdf format",
  InvalidFileHtml: "Please upload a html file.",
  FileUploadSuccess: "File uploaded successfully.",
  FileUploadFailure: "File Upload Failed",
  UploadUnsuccessful: "Upload Unsuccessful",
  Criticality: "Invalid Criticality column value in Excel File. Values should be either 'High' or 'Medium' or 'Low'.",
  ColumnLength: " exceeds the 100 char limit. Please limit characters to 100 and try again.",
  ColumnLengthInstalledSystem:  " exceeds the 250 char limit. Please limit characters to 250 and try again.",
  DateFormat:  "Invalid date. Please enter the Date column in DD/MM/YYYY format.", //"Please enter the Date column in DD/MM/YYYY format.",
  DateFuture: "Invalid date. Valid date must be from 01/01/2020 till today.",
  wordrequired: "Invalid Password Required column value in Excel File. Values should be either 'Yes' or 'No'.",
};

export default class CommonUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorFile: false,
      fileInputKey: this.generateRandomString(),
      selectedName: "Name of select",
      errors: {},
      documentFile: null,
      folderName: this.props.folderName,
      courseIterationId: this.props.courseIterationId == undefined? 0 : this.props.courseIterationId,
      moduleId: this.props.moduleId == undefined ? 0 : this.props.moduleId,
      currentPage: this.props.currentPage == undefined ? "" : this.props.currentPage,
      userId: this.props.userId == undefined ? 0 : this.props.userId,
      assignmentSubmitNumber: this.props.assignmentSubmitNumber == undefined? 0 : this.props.assignmentSubmitNumber,
      isLoading:false,
      assignmentFileList:[],
    };
  }

    generateRandomString = () => {
        var array = new Uint32Array(1);
        window.crypto.getRandomValues(array);
        var secureRandomNumber = array[0] / (Math.pow(2, 32) - 1);
        var secureRandomString = secureRandomNumber.toString(36).substring(2);
        return secureRandomString;
    //return Math.random().toString(36);
  };

  getUploadMessage = () => {
    if(this.state.currentPage == "EditCourseIteration"){
      return messages.UploadTmaqFile;
    }else{
      return messages.UploadTextExcel;
    }
  };

  getInvalidFileMessage = () => {
    let message = "* ";

    message += messages.InvalidFileExcel;

    return message;
  };

  /* <summary>
  date: 02-02-2022
  Name: VD
  description: This functiona has been changed to accept both multiple attachment as well as single attachment
  <summary>*/ 
  onFileChange = (e) => {
    if (!this.isValidFile(e.target.value)) {
      this.setState({
        fileInputKey: this.generateRandomString(),
        documentFile: null,
        errorFile: true,
        assignmentFileList:null,
      });
      return;
    }

    let file
    if(this.state.currentPage == "Assignment"){
      file = e.target.files; 
      var ins = file.length;

      for (var i = 0; i < ins; i++) {
        let attachedFile = file[i]; 
        this.setState((prevState) => ({
          assignmentFileList: [...prevState.assignmentFileList, attachedFile],
        }));
      }
    }else{
        file = e.target.files[0];
    }
    this.setState({
      errorFile: false,
      documentFile: file,
    });
  };

  isValidFile = (value) => {
    //check if value is blank
    if (!value) return false;

    //regex to get file extension
    var re = /(?:\.([^.]+))?$/;

    //second item in the array will be the extension
    let ext = re.exec(value)[1];
    return validFileFormats.some((x) => x === ext);
  };

  onSaveClick = async () => {
    try{
      const {
        documentFile,
        folderName,
        courseIterationId,
        moduleId,
        currentPage,
        userId,
        assignmentFileList,
        assignmentSubmitNumber
      } = this.state;
  
      this.setState({
        isLoading:true,
      })
      if (this.state.documentFile == null) {
        this.setState({
          errorFile: true,
        });
        return;
      }
      let data = new FormData();
      if ( folderName == "AssignmentQuestions" && currentPage == "EditCourseIteration" ){
        data.append("file", documentFile);
        data.append("folderName", folderName);
        data.append("courseIterationId", courseIterationId);
        data.append("moduleId", moduleId);
      } else if (folderName == "Assignments" && currentPage == "Assignment") {
        data.append("file", documentFile);
        data.append("folderName", folderName);
        data.append("userId", userId);
        data.append("moduleId", moduleId);
        data.append("assignmentSubmitNumber",assignmentSubmitNumber)
        if(assignmentFileList!=undefined){
          var ins = assignmentFileList.length;   
              for (var x = 0; x < ins; x++) {
                  if (assignmentFileList[x].size != null && assignmentFileList[x].size != undefined) { 
                      data.append(`file_${x}`, assignmentFileList[x]);
                  }
            }
          }
      } else if (folderName == "Remarks" && currentPage == "AssignmentSubCategory") {
        data.append("file", documentFile);
        data.append("folderName", folderName);
      }else if(folderName == "UnitCoverImages" && currentPage == "ManageStudyMaterial"){
        data.append("file", documentFile);
        data.append("folderName", folderName);
      }else if(folderName == "ProfileImage" && currentPage == "Profile"){
        data.append("file", documentFile);
        data.append("folderName", folderName);
        data.append("userId", userId);
      }else if(folderName == "ModulePDF" && currentPage == "ManageStudyMaterial"){
        data.append("file", documentFile);
        data.append("folderName", folderName);
      }

      var response = await uploadfileservice.SaveFileMedia(data);
      if (response.status == 200) {
        this.props.getFileMediaId(response.data);
      }
      this.setState({
        isLoading:false,
      })
      this.props.closeModal();
    }catch (ex) {
      return "";
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    const { 
      errorFile, 
      fileInputKey,
      currentPage, 
      isLoading,
    } = this.state;
    return (
      <div
        id="myModal"
        className="modal"
        role="dialog"
        style={{
          overflow: "auto",
          display: "block",
          marginTop: "5.5%",
          opacity: 1,
        }}
      >
        <div className="modal-dialog">
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={this.closeModal}
                style={{
                  padding: "0px 12px",
                  margin: "5px",
                  paddingBottom: "5px",
                }}
              >
                ×
              </button>
              <h4 className="modal-title">Upload</h4>
            </div>
            <div className="modal-body">
              <form>
                <p>
                  <small className="text-muted">
                    {this.getUploadMessage(1)}
                  </small>
                </p>

                <Input
                  type="file"
                  accept={currentPage=="ManageStudyMaterial"?".pdf,.png":(currentPage=="Profile"?".png,.jpg":".pdf,.xlsx, .xls,.jpeg,.png,.jpg,.txt,.doc,.docx")}
                  // accept=" .doc, .docx, .pdf"
                  name="documentFile"
                  onChange={this.onFileChange}
                  key={fileInputKey || ""}
                  multiple={currentPage == "Assignment"?"multiple":""}
                  style={{
                    marginTop: "10px",
                    width: "95%",
                    overflow: "revert",
                    whiteSpace: "break-spaces",
                  }}
                />
                {errorFile && (
                  <div style={errorStyle}>{errorStrings.emptyFile}</div>
                )}
                <Container style={{ padding: "1em 0 0 0", width: "90%" }}>
                  <Row>
                    <div>
                      <Button
                        className="buttonCancelColor"
                        size="sm"
                        style={{
                          marginLeft: "5px",
                          float: "right",
                          fontSize: "12px",
                        }}
                        onClick={this.closeModal}
                      >
                        <b>CANCEL</b>
                      </Button>
                      <Button
                        className="buttonColor"
                        size="sm"
                        style={{
                          marginLeft: "15px",
                          float: "right",
                          fontSize: "12px",
                        }}
                        onClick={this.onSaveClick}
                      >
                        <b>UPLOAD</b>
                      </Button>
                    </div>
                    <Col md="12">
                      {this.state.isLoading ? <Loader /> : <div></div>}
                    </Col>
                  </Row>
                </Container>
              </form>
            </div>
          </div>
        </div>
        {isLoading && <Loader />}
      </div>
    );
  }
}
