import Bowser from "bowser";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse("1203199320052021");
const iv = CryptoJS.enc.Utf8.parse("1203199320052021");

const XLSX = require("xlsx");
export const getHostnameFromRegex = (url) => {
    try {
      // run against regex
      const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
      // extract hostname (will be null if no match is found)
      return matches && matches[1];
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 23-07-2021
  Name: VD
  description: To get the Browser details
  <summary>*/ 
  export const getBrowserDetails = () => {
    try {
      //Create a parser instance
      const browser = Bowser.getParser(window.navigator.userAgent);
  
      //Get browser's name
      const browserName = browser.getBrowserName();

      //Get browser's Version
      const browserversion=browser.getBrowserVersion();
  
      //Get os object
      const os = browser.getOS();
  
      //Get platform name
      const platformType = browser.getPlatformType();
  
      const browserDetails = {
        os: os,
        browserName: browserName,
        browserversion:browserversion,
        platformType: platformType,
      };
  
      return browserDetails;
    } catch (e) {
      return undefined;
    }
  };

  /* <summary>
  date: 27-07-2021
  Name:VD
  description: Converts Grid (Columnn and Rows to Sheet for excel)
  <summary>*/
  export const UIGridToSheet = (data, columns) => {
    var o = [],
      oo = [],
      i = 0,
      j = 0;
    try {
      /* column headers */
      for (j = 0; j < columns.length; ++j) oo.push(columns[j]);
      o.push(oo);
  
      /* table data */
      for (i = 0; i < data.length; ++i) {
        oo = [];
        for (j = 0; j < data[i].length; ++j) oo.push(data[i][j]);
        o.push(oo);
      }
    } catch (error) {}
  
    /* aoa_to_sheet converts an array of arrays into a worksheet object */
    return XLSX.utils.aoa_to_sheet(o);
  };

  /* <summary>
  date: 27-07-2021
  Name: VD
  description: Converts Sheet data to an array buffer
  <summary>*/

  export const SheetToArrayBuffer = (s) => {
    try {
      if (typeof ArrayBuffer !== "undefined") {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      } else {
        var buf = new Array(s.length);
        for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
    } catch (error) {}
  };

  export const ConvertToHtml = (editorState) => {
    try {
      const rawContentState = convertToRaw(editorState.getCurrentContent());
      const markup = draftToHtml(
        rawContentState,
        {
          trigger: "#",
          separator: " ",
        },
        true
      );
      return markup;
    } catch (error) {
      return "";
    }
  };

  /* <summary>
  date: 11-10-2021
  Name: VD
  description: Convert Html content to Editor State
  <summary>*/ 

export const ConvertFromHTML = (html) => {
  try {
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      return editorState;
    } else {
      return EditorState.createEmpty();
    }
  } catch (error) {
    return EditorState.createEmpty();
  }
};

export const GetLocalStorageDataWithoutParse = (storageKey) => {
    const encryptedKey = EncryptUsingAES256(storageKey);
    const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
    try {
        if (encryptedValue) {
            return DecryptUsingAES256(encryptedValue);
        }
    } catch (error) { }
    return encryptedValue;
};

export const GetLocalStorageDataWithParse = (storageKey) => {
    const encryptedKey = EncryptUsingAES256(storageKey);
    const encryptedValue = localStorage.getItem(encryptedKey) ?? undefined;
    try {
        if (encryptedValue) {
            return JSON.parse(DecryptUsingAES256(encryptedValue));
        }
    } catch (error) { }
    return encryptedValue;
};

export const AddLocalStorageData = (storageKey, value) => {
    const encryptedValue = EncryptUsingAES256(value);
    const encryptedKey = EncryptUsingAES256(storageKey);

    if (localStorage.getItem(storageKey)) {
        localStorage.removeItem(storageKey);
    }

    localStorage.setItem(encryptedKey, encryptedValue);
};

export const EncryptUsingAES256 = (text) => {
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
        iv,
        keySize: 128 / 8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
};

export const DecryptUsingAES256 = (decString) => {
    const decrypted = CryptoJS.AES.decrypt(decString, key, {
        iv,
        keySize: 128 / 8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
};