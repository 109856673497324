import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Header from "../../_components/Header";
import Loader from "../../_helpers/Loader";
import eye from "../../assets/eye.png";
import { adminservice } from "../../_services/admin.service";
import StudentFooter from "../../_components/StudentFooter";
import CommonUploadForm from "../../_helpers/CommonUploadForm";
import { helperText } from "../../_helpers/helpertext";
import { MessageType } from "../../_helpers/Enums";
import PopupModal from "../../_helpers/PopupModal";
import { courseService } from "../../_services/Course.service";
import moment from "moment";
import { history } from "../../_helpers/history";
import Deleteicon from "../../assets/RedDeleteIcon.png";
import AssignmentRemarksPopupModal from "../../_helpers/AssignmentRemarksPopupModal";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class AssignmentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      moduleId: 0,
      isLoading: false,
      assignmentList: [],
      moduleName: "",
      showCommomUploadModel: false,
      folderName: "Assignments",
      popUpModalText: "",
      showPopupModal: false,
      messageType: 0,
      unFilteredAssignmentList: [],
      showFirstAttemptGrid: true,
      showSecondAttemptGrid: false,
      showThirdAttemptGrid: false,
      showFourthAttemptGrid: false,
      showFifthAttemptGrid: false,
      assignmentSubmitNumber: 1,
      firstAttemptAssignmentList: [],
      secondAttemptAssignmentList: [],
      thirdAttemptAssignmentList: [],
      fourthAttemptAssignmentList: [],
      fifthAttemptAssignmentList: [],
      finalResult: 3,
      hideUploadButton: false,
      showAssignmentRemarksPopupModal:false,
      attemptNumber:1,
      courseIterationId:0,
    };
  }

  componentDidMount = () => {
      const userID = GetLocalStorageDataWithoutParse("UserID");
    const moduleId = this.props.match.params.moduleId;

    this.getModuleName(moduleId);
    this.getAssignmentListBasedOnModuleIdAndStudentId(moduleId, userID);
    this.setState({
      userId: userID,
      moduleId: moduleId,
    });
  };

  /* <summary>
  date: 01-02-2022
  Name: VD
  description: This is used to get the Module name based on the module Is passed
  <summary>*/
  getModuleName = async (moduleId) => {
    try {
      if (moduleId !== undefined) {
        var response = await adminservice.GetModuleDataBasedOnModuleID( moduleId );
        if (response.status === 200) {
          this.setState({
            moduleName: response.data[0].moduleName,
          });
        }
      }
    } catch (e) {
      return "";
    }
  };

  /* <summary>
  date: 01-02-2022
  Name: VD
  description: This is used to get the list of all assignment that the student has uploaded for the particular modal
  <summary>*/
  getAssignmentListBasedOnModuleIdAndStudentId = async (moduleId, userId) => {
    try {
      var response = await courseService.getAssignmentListBasedOnModuleIdAndStudentId( moduleId, userId );
      if ((response.status === 200)) {
        if (response.data.length !== 0) {
          this.setState(
            {
              unFilteredAssignmentList: response.data,
              finalResult: response.data[0].finalResult,
              courseIterationId:response.data[0].courseIterationId,
            }, () => {
              this.filterAssignmentBasedOnSubmitNumber();
            }
          );
        }else{
           this.setState({unFilteredAssignmentList:[]}, 
            () => {
            this.filterAssignmentBasedOnSubmitNumber();
          });
        }
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
    } catch (e) {
      return "";
    }
  };

  filterAssignmentBasedOnSubmitNumber = () => {
    const {
      unFilteredAssignmentList,
    } = this.state;
    var firstfilterValue = unFilteredAssignmentList.filter(
      (x) => x.assignmentSubmitNumber === 1
    );
    var secondfilterValue = unFilteredAssignmentList.filter(
      (x) => x.assignmentSubmitNumber === 2
    );
    var thirdfilterValue = unFilteredAssignmentList.filter(
      (x) => x.assignmentSubmitNumber === 3
    );
    var fourthfilterValue = unFilteredAssignmentList.filter(
      (x) => x.assignmentSubmitNumber === 4
    );
    var fifthfilterValue = unFilteredAssignmentList.filter(
      (x) => x.assignmentSubmitNumber === 5
    );

    if (firstfilterValue != "" || firstfilterValue.length != 0) {
      if (firstfilterValue[0].result !== 4) {
        //4 is Not Applicable => Result has not yet been set
        this.setState({
          assignmentSubmitNumber: 2,
        });
      }
      if (firstfilterValue[0].result == 1 || firstfilterValue[0].result == 2 || firstfilterValue[0].result == 5 || firstfilterValue[0].result == 6) 
      {
        this.setState({
          hideUploadButton: true,
        });
      }
    }

    //To check if the second attempt assignment has been uploaded or not and to show and hide the upload button
    if (secondfilterValue != "" || secondfilterValue.length != 0) {
      if (secondfilterValue[0].result != 4) {
        this.setState({
          assignmentSubmitNumber: 3,
        });
      }
      if ( secondfilterValue[0].result == 1 || secondfilterValue[0].result == 2 || secondfilterValue[0].result == 5 || secondfilterValue[0].result == 6) 
      {
        this.setState({
          hideUploadButton: true,
        });
      }
      this.setState({
        showSecondAttemptGrid: true,
      });
    }

    if (thirdfilterValue != "" || thirdfilterValue.length != 0) {
      if (thirdfilterValue[0].result != 4) {
        this.setState({
          assignmentSubmitNumber: 4,
        });
      }
      if ( thirdfilterValue[0].result == 1 || thirdfilterValue[0].result == 2 || thirdfilterValue[0].result == 5 || thirdfilterValue[0].result == 6) 
      {
        this.setState({
          hideUploadButton: true,
        });
      }
      this.setState({        
        showThirdAttemptGrid: true,
      });
    }

    if (fourthfilterValue != "" || fourthfilterValue.length != 0) {
      if (fourthfilterValue[0].result != 4) {
        this.setState({
          assignmentSubmitNumber: 5,
        });
      }
      if ( fourthfilterValue[0].result == 1 || fourthfilterValue[0].result == 2 || fourthfilterValue[0].result == 5 || fourthfilterValue[0].result == 6) 
      {
        this.setState({
          hideUploadButton: true,
        });
      }
      this.setState({
        showFourthAttemptGrid: true,
      });
    }

    if (fifthfilterValue != "" || fifthfilterValue.length != 0) {
      if (fifthfilterValue[0].result != 4) {
        this.setState({
          hideUploadButton: true,
        });
      }
      this.setState({
        showFifthAttemptGrid: true,
      });
    }

    this.setState({
      firstAttemptAssignmentList: firstfilterValue,
      secondAttemptAssignmentList: secondfilterValue,
      thirdAttemptAssignmentList: thirdfilterValue,
      fourthAttemptAssignmentList: fourthfilterValue,
      fifthAttemptAssignmentList: fifthfilterValue,
    });
  };

  assignmentFilesHeader = [
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          File Name
        </div>
      ),
      //selector: "fileName",
      selector: (row) => row.fileName,
      left: true,
      grow: 3,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Action
        </div>
      ),
      //selector: "result",
      selector: (row) =>
        row.result == 1
          ? "Pass"
          : row.result == 2
          ? "Fail"
          : row.result == 3
          ? "Resubmit"
          : row.result == 5
          ? "Distinction"
          : row.result == 6
          ? "Merit"
          : row.result == 4
          ? "Submitted"
          : "Submit",
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Preview
        </div>
      ),
      //selector: "Preview",
      selector: (row) => (
        <img
          src={eye}
          width="30px;"
          style={{ cursor: "pointer" }}
          onClick={() => this.showAnswerPreview(row.assignmentFilePath)}
        />
      ),
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Uploaded On
        </div>
      ),
      //selector: "CreatedOn",
        selector: (row) => moment(row.createdOn).format("DD-MM-YYYY"),
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Delete
        </div>
      ),
      //selector: "Delete",
      selector: (row) =>
        row.result == 4 ? (
          <img
            src={Deleteicon}
            className="delete-icon"
            width="20px"
            style={{ cursor: "pointer" }}
            onClick={() => this.deleteUploadedFile(row)}
          />
        ) : (
          ""
        ),
      center: true,
    },
  ];

  /* <summary>
  date: 05-02-2022
  Name: VD
  description: This is used to Delete the uploaded file that the user had uploaded
  <summary>*/
  deleteUploadedFile = async (row) => {
    try {
      const studentAssignmentFilesBridgeID = row.studentAssignmentFilesBridgeID;

      this.setState({ isLoading: true });
      var response = await courseService.DeleteAssignmentSelectedByStudent(
        studentAssignmentFilesBridgeID
      );
      if (response.status == 200) {
        this.setState({
          popUpModalText: helperText.AssignmentDetails.assignmentDeleteSuccess,
          showPopupModal: true,
          messageType: MessageType.Success,
        },()=>{
          this.getAssignmentListBasedOnModuleIdAndStudentId(this.state.moduleId, this.state.userId);
        });
        
        //this.componentDidMount();
        //window.location.reload();
      } else {
        this.setState({
          popUpModalText: helperText.AssignmentDetails.assignmentDeleteFailure,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      return "";
    }
  };
  showAnswerPreview = (filePath) => {
    window.open(filePath, "_blank");
  };

  showUploadModelForAssignment = async (moduleID) => {
    this.setState({
      showCommomUploadModel: true,
    });
  };

  closeModal = () => {
    this.setState({
      showCommomUploadModel: false,
      showAssignmentRemarksPopupModal: false,
    });
  };

  getFileMediaId = async (Id) => {
    if (Id == null) {
      this.setState({
        popUpModalText: helperText.profile.assignmentUploadError,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    } else {
      this.setState({
        popUpModalText: helperText.profile.assinmentUploaded,
        showPopupModal: true,
        messageType: MessageType.Success,
      });
    }

    await this.componentDidMount();
  };

  closePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  showUploadModel = async (attempt) => {
    this.setState({
      showAssignmentRemarksPopupModal: true,
      attemptNumber:attempt,
    });
  };

  
  render() {
    const {
      userId,
      moduleId,
      isLoading,
      assignmentList,
      moduleName,
      showCommomUploadModel,
      folderName,
      popUpModalText,
      showPopupModal,
      messageType,
      showFirstAttemptGrid,
      showSecondAttemptGrid,
      showThirdAttemptGrid,
      showFourthAttemptGrid,
      showFifthAttemptGrid,
      assignmentSubmitNumber,
      firstAttemptAssignmentList,
      secondAttemptAssignmentList,
      thirdAttemptAssignmentList,
      fourthAttemptAssignmentList,
      fifthAttemptAssignmentList,
      finalResult,
      hideUploadButton,
      showAssignmentRemarksPopupModal,
      attemptNumber,
      courseIterationId,
    } = this.state;
    return (
      <div id="admin">
        <Header currentTab="Assignment" />
        {isLoading && <Loader />}
        <section className="AssignmentSection">
          <section id="coursenbanner" className="AssignmentHeader">
            <div className="row">
              <div className="col-md-8">
                <h2 style={{ marginTop: "3px" }}>{moduleName}</h2>
              </div>
              {!hideUploadButton && (
                <div className="col-md-4">
                  <label
                    className="uploadBtn"
                    for="file-upload"
                    onClick={() => this.showUploadModelForAssignment(moduleId)}
                    style={{ cursor: "pointer" }}
                  >
                    Upload
                  </label>
                </div>
              )}
            </div>
          </section>
          {showFifthAttemptGrid && (
            <section className="courselist col-md-12" id="content">
              <div className="row">
              <div className="col-md-10">
              <h4 className="mb-3" style={{ fontWeight: "bold" }}>
                Fifth Attempt
              </h4>
              </div>
              <div className="col-md-2" style={{textAlign:'center'}}>
              <a className="btn-green"
              style={{width:'auto'}}
                        onClick={() => this.showUploadModel(5)}
                      >
                        Remarks
                      </a>{" "}
                      </div>
                      </div>
              <div className="panel panel-default tab-content xdff">
                <div className="table-responsive Assignment-Header">
                  <DataTable
                    columns={this.assignmentFilesHeader}
                    data={fifthAttemptAssignmentList}
                    noHeader={true}
                    width="50%"
                    className="iizuaN"
                    pagination
                  />
                </div>
              </div>
            </section>
          )}
          {showFourthAttemptGrid && (
            <section className="courselist col-md-12" id="content">
              <div className="row">
              <div className="col-md-10">
              <h4 className="mb-3" style={{ fontWeight: "bold" }}>
                Fourth Attempt
              </h4>
              </div>
              <div className="col-md-2" style={{textAlign:'center'}}>
              <a className="btn-green"
              style={{width:'auto'}}
                        onClick={() => this.showUploadModel(4)}
                      >
                        Remarks
                      </a>{" "}
                      </div>
                      </div>
              <div className="panel panel-default tab-content xdff">
                <div className="table-responsive Assignment-Header">
                  <DataTable
                    columns={this.assignmentFilesHeader}
                    data={fourthAttemptAssignmentList}
                    noHeader={true}
                    width="50%"
                    className="iizuaN"
                    pagination
                  />
                </div>
              </div>
            </section>
          )}
          {showThirdAttemptGrid && (
            <section className="courselist col-md-12" id="content">
              <div className="row">
              <div className="col-md-10">
              <h4 className="mb-3" style={{ fontWeight: "bold" }}>
                Third Attempt
              </h4>
              </div>
              <div className="col-md-2" style={{textAlign:'center'}}>
              <a className="btn-green"
              style={{width:'auto'}}
                        onClick={() => this.showUploadModel(3)}
                      >
                        Remarks
                      </a>{" "}
                      </div>
                      </div>
              <div className="panel panel-default tab-content xdff">
                <div className="table-responsive Assignment-Header">
                  <DataTable
                    columns={this.assignmentFilesHeader}
                    data={thirdAttemptAssignmentList}
                    noHeader={true}
                    width="50%"
                    className="iizuaN"
                    pagination
                  />
                </div>
              </div>
            </section>
          )}

          {showSecondAttemptGrid && (
            <section className="courselist col-md-12" id="content">
              <div className="row">
                <div className="col-md-10">
                  <h4 className="mb-3" style={{ fontWeight: "bold" }}>
                    Second Attempt
                  </h4>
                </div>
                <div className="col-md-2" style={{textAlign:'center'}}>
                  <a   className="btn-green"
                  style={{width:'auto'}}
                        onClick={() => this.showUploadModel(2)}
                      >
                        Remarks
                      </a>{" "}
                </div>
              </div>
              
              
              <div className="panel panel-default tab-content xdff">
                <div className="table-responsive Assignment-Header">
                  <DataTable
                    columns={this.assignmentFilesHeader}
                    data={secondAttemptAssignmentList}
                    noHeader={true}
                    width="50%"
                    className="iizuaN"
                    pagination
                  />
                </div>
              </div>
            </section>
          )}

          {showFirstAttemptGrid && (
            <section className="courselist col-md-12" id="content">
              <div className="row">
              <div className="col-md-10">
              <h4 className="mb-3" style={{ fontWeight: "bold" }}>
                First Attempt
              </h4>
              </div>
              <div className="col-md-2" style={{textAlign:'center'}}>
              <a className="btn-green" style={{width:'auto'}}
                        onClick={() => this.showUploadModel(1)}
                      >
                        Remarks
                      </a>{" "}
                      </div>
                      </div>
              <div className="panel panel-default tab-content xdff">
                <div className="table-responsive Assignment-Header">
                  <DataTable
                    columns={this.assignmentFilesHeader}
                    data={firstAttemptAssignmentList}
                    noHeader={true}
                    width="50%"
                    className="iizuaN"
                    pagination
                  />
                </div>
              </div>
            </section>
          )}
          <div className="clearfix" />
          <div className="col-md-12" style={{ paddingLeft: "31px" }}>
            <button
              type="button"
              className="btn back-button"
              onClick={() => history.goBack()}
              style={{ marginBottom: "5rem" }}
            >
              <i className="fa fa-angle-left" />
              Back{" "}
            </button>
          </div>
        </section>
        <StudentFooter />
        {showCommomUploadModel && (
          <CommonUploadForm
            closeModal={this.closeModal}
            getFileMediaId={this.getFileMediaId}
            folderName={folderName}
            currentPage="Assignment"
            userId={userId}
            moduleId={moduleId}
            assignmentSubmitNumber={assignmentSubmitNumber}
          />
        )}
        {showPopupModal && (
          <PopupModal
            closeModal={this.closePopupModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {showAssignmentRemarksPopupModal && (
          <AssignmentRemarksPopupModal 
            closeModal={this.closeModal}
            attemptNumber={attemptNumber}
            moduleId={moduleId}
            studentId={userId}
            courseIterationId={courseIterationId}
            adminLogin={false}
          />
        )}
      </div>
    );
  }
}
