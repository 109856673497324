import React, { Component } from "react";
import AdminFooter from "../../_components/AdminFooter";
import AdminHeader from "../../_components/AdminHeader";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import { adminservice } from "../../_services/admin.service";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { ConvertFromHTML } from "../../_helpers/utilities";
import { studentService } from "../../_services/student.service";
import Header from "../../_components/Header";
import { history } from "../../_helpers/history";
import StudentFooter from "../../_components/StudentFooter";
import { MessageType, Role } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";
import DataTable from "react-data-table-component";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class ReadEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      emailId: 0,
      showPopupModal: false,
      popUpModalText: "",
      messageType:0,
      subject: "",
      message: EditorState.createEmpty(),
      isAdmin: true,
      isPageLoaded: false,
      attachedFileMediaId: "",
      recievedFrom:'',
      sendMailTo:'',
      showReplyButton:false,
      notificationAttachmentList:[],
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the Full message based on the email id
  <summary>*/ 
  componentDidMount = async () => {
    try {
      var EmailID = this.props.match.params.emailId;
      var roleID = this.props.match.params.roleId;
      if (roleID == 1) {
        this.setState({
          isAdmin: true,
          isPageLoaded: true,
        });
      } else {
        this.setState({
          isAdmin: false,
          isPageLoaded: true,
        });
      }
      
      var type=localStorage.getItem("MessageType");
      if(type=="sentMail"){
        this.setState({
          showReplyButton:false,
        })
      }else{
        this.setState({showReplyButton:true})
      }  
      var response = await adminservice.GetFullMessage(EmailID);
      if (response.status == 200) {
        this.setState({
          subject: response.data.subject,
          message: ConvertFromHTML(response.data.message),
          attachedFileMediaId: response.data.attachedFileMediaId,
          recievedFrom:response.data.from,
          sendMailTo:response.data.from,
          notificationAttachmentList:response.data.notificationsAttachmentBridgeModels===null?[]:response.data.notificationsAttachmentBridgeModels,
        });
        await this.getNotificationCount();
      } else {
        this.setState({
          showPopupModal: true,
          popUpModalText: helperText.DataLoadError.loadError,
          messageType:MessageType.Failure,
        });
      }
    } catch (ex) {
      return "";
    }

    localStorage.setItem("sendMailTo",this.state.sendMailTo);
  };

  getNotificationCount = async () => {
    try {
        const userID = GetLocalStorageDataWithoutParse("UserID");
      var response = await studentService.GetNotificationCount(userID);
      if (response.status == 200) {
          AddLocalStorageData("notificationCount", response.data);
      }
    } catch (ex) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  replyToEmail=()=>{
      var RoleID = GetLocalStorageDataWithoutParse("RoleID");
    history.push(`/SendEmail/${this.state.recievedFrom}/${RoleID}`)
  }

  goToPrevPage=()=>{
      var RoleID = GetLocalStorageDataWithoutParse("RoleID");
    if(RoleID==Role.Admin){
      history.push(`/AdminNotification`)
    }else{
      history.push(`/StudentNotification`)
    }
  }

  AttachedFilesColumns = [
    {
      cell: (row) => <td className="center" style={{cursor:'pointer'}} onClick={()=>this.showFile(row.filePath)}>{row.name}</td>,
      left: true,
      grow: 1,
    },
  ];

  showFile=(path)=>{
    if(path!==""){
      window.open(path, "_blank");
    }
  }

  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      subject,
      message,
      isAdmin,
      isPageLoaded,
      attachedFileMediaId,
      fileName,
      sendMailTo,
      showReplyButton,
      notificationAttachmentList,
    } = this.state;
    return (
      <div className="student-body">
        {isPageLoaded ? (
          isAdmin ? (
            <AdminHeader currentTab="None" />
          ) : (
            <Header />
          )
        ) : (
          ""
        )}
        <div style={{ minHeight: "87vh" }}>
          {/* /* --- Body Code ---* /  */}
          <section
            id="admincoursenbanner"
            style={{ marginTop: "-21px" }}
            className="notification-banner"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-9">
                  <p>
                    {" "}
                    <b className="p-title">Message </b>
                    {/* <span>-</span>
                        <label>admin@iims.co.uk</label> */}
                  </p>
                </div>
                <div className="col-md-3"></div>
              </div>
            </div>
          </section>
          <section className="read-msg-sec">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-10">
                  <form className="read-msg-form">
                  <div className="form-group row">
                      <bold htmlFor className="col-sm-2 col-form-label" style={{width:'80px'}}>
                        From
                      </bold>
                      <div className="col-sm-10">
                        <label htmlFor className="col-form-label">
                          {sendMailTo}
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <bold htmlFor className="col-sm-2 col-form-label" style={{width:'80px'}}>
                        Subject
                      </bold>
                      <div className="col-sm-10">
                        <label htmlFor className="col-form-label">
                          {subject}
                        </label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <bold
                        htmlFor="inputPassword"
                        className="col-sm-2 col-form-label"
                        style={{marginTop:'18px',width:'80px'}}
                      >
                        Message
                      </bold>
                      <div className="col-sm-10">
                        <Editor
                          editorStyle={{ minHeight: "200px" }}
                          editorState={message}
                          wrapperClassName="demo-wrapper"
                          editorClassName="editer-content"
                          toolbarClassName="hide-toolbar"
                          toolbar={{
                            options: ["inline"],
                            inline: {
                              inDropdown: false,
                              options: ["bold", "italic", "underline"],
                            },
                          }}
                        />
                      </div>
                    </div>
                    {notificationAttachmentList.length === 0 ? (
                      ""
                    ) : (
                      <div className="form-group row read-msg-attach">
                        <bold
                          htmlFor="inputPassword"
                          className="col-sm-2 col-form-label"
                          style={{width:'80px'}}
                        >
                          Attachments
                        </bold>
                        <div id="selectedRefereceLetters" />
                          <DataTable
                            columns={this.AttachedFilesColumns}
                            data={notificationAttachmentList}
                            noHeader={true}
                          />
                      </div>
                    )}
                    <div className="col-sm-12" >
                      <button className="btn back-button" style={{float:'left',padding:'6px 20px'}} onClick={() => this.goToPrevPage()}>Back</button>
                      
                      <div className="form-group">
                      {showReplyButton?
                        <button className="btn btn-primary reply-btn mb-2" onClick={()=>this.replyToEmail()}>Reply</button>
                      :""}
                    </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        {isPageLoaded ? isAdmin ? <AdminFooter /> : <StudentFooter /> : ""}
        {isLoading && <Loader />}
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
