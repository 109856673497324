import webserviceWrapper from "../_helpers/webserviceWrapper";

export const uploadfileservice={
    SaveFileMedia,
    SendEmail,
    AddEditTemplate,
    UploadStudentData
}

function SaveFileMedia(data){
    //return webserviceWrapper.post(
    //    `Admin/SaveFileMedia`,
    //    data
    //)
    return webserviceWrapper.postWithHeader(
        `Admin/SaveFileMedia`,
        data,
        undefined,
        { "Content-Type": "multipart/form-data" }
    )    
}

function SendEmail(data){
    //return webserviceWrapper.post(
    //    `Admin/SendEmail`,
    //    data
    //)
    return webserviceWrapper.postWithHeader(
        `Admin/SendEmail`,
        data,
        undefined,
        { "Content-Type": "multipart/form-data" }
    )
}

function AddEditTemplate(data){
    //return webserviceWrapper.post(
    //    `Admin/AddEditTemplate`,
    //    data
    //)
    return webserviceWrapper.postWithHeader(
        `Admin/AddEditTemplate`,
        data,
        undefined,
        { "Content-Type": "multipart/form-data" }
    )
}
function UploadStudentData(apiMethod, data) {
    return webserviceWrapper.postWithHeader(
        `Admin/${apiMethod}`,
        data,
        undefined,
        { "Content-Type": "multipart/form-data" }
    )
}