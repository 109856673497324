import React, { Component } from "react";
import { loginService } from "../_services/login.service";
import { helperText } from "./helpertext";
import PopupModal from "./PopupModal";
import eye from "../assets/eye.png";
import { MessageType } from "./Enums";

export default class PasswordChangeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopupModal: false,
      popUpModalText:"",
      messageType:0,
      isLoading:false,
      emailAddress:"",
      password:"",
      confirmPassword:"",
      errorMessage: {},
      showPassword: false,
      showconfirmPassword: false,
    };
  }
  componentDidMount=()=>{
      this.setState({
        emailAddress:this.props.emailAddress,
      })
  }
  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  updatePassword = async() => {
      try{
        if (this.validate()) {
            this.setState({
                isLoading:true,
            })
            var response=await loginService.EditPassword(this.state.emailAddress,this.state.password)
            if(response.status==200){
                
                this.props.closePasswordChangeModal(1)
            }else{
              this.props.closePasswordChangeModal(2)
            }
            this.setState({
                isLoading:false,
            })
        }
      } catch (ex) {
      return "";
    }
  };

  validate = () => {
    const { password,confirmPassword } = this.state;
    try {
        var isValid = true;
        if (password == ""||confirmPassword=="") isValid = false;
  
        if (password == "") {
          var error = this.state.errorMessage;
          error["emptyPassword"] = helperText.loginPageText.wordPlaceholder;
          this.setState({ errorMessage: error });
          isValid = false;
        }

        if (confirmPassword == "") {
            var error = this.state.errorMessage;
            error["emptyconfirmPassword"] = helperText.loginPageText.confirmPassword;
            this.setState({ errorMessage: error });
            isValid = false;
        }

        if(password.length<8 || password!=confirmPassword){
            var error = this.state.errorMessage;
            error["emptyconfirmPassword"] = helperText.loginPageText.bothPasswordDoesNotMatch;
            this.setState({ errorMessage: error });
            isValid = false;
        }
        return isValid;
    } catch (error) {}
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
    });
  };

  toggleEye = () => {
    const currentState = this.state.showPassword;
    this.setState({ showPassword: !currentState });
  };

  toggleConfirmEye = () => {
    const currentState = this.state.showconfirmPassword;
    this.setState({ showconfirmPassword: !currentState });
  };
  render() {
    const { 
        showPopupModal, 
        popUpModalText,
        messageType,
        password,
        confirmPassword,
        showPassword,
        showconfirmPassword,
     } = this.state;
    return (
      <div
        className="modal AdvanceCourseModal"
        id="changePasswordModal"
        //tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content change-password-pop">
            <div className="modal-header">
              <h4 className="modal-title" id="changePasswordModalLongTitle">
                Change Password
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={()=>this.props.closePasswordChangeModal(0)}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body ">
              <form>
                <div className="form-group">
                  <label htmlFor="newpassword">
                    New Password{" "}
                    <span>
                      (Min. 8 characters)
                    </span>
                  </label>
                  <input
                   type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="password"
                    name="password"
                    value={password}
                    onChange={this.onTextChange}
                  />
                  <img
                    style={{ marginLeft: "90%", marginTop: "-66px" }}
                    src={eye}
                    onClick={this.toggleEye}
                  />{" "}
                   <div
                      style={{
                        display: this.state.errorMessage.emptyPassword
                          ? "contents"
                          : "none",
                        color: "red",
                      }}
                    >
                      {this.state.errorMessage.emptyPassword}
                    </div>
                </div>
              </form>
              <form>
                <div className="form-group">
                  <label htmlFor="newpassword">Confirm New Password</label>
                  <input
                    type={showconfirmPassword ? "text" : "password"}
                    className="form-control"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={this.onTextChange}
                  />
                  <img
                    style={{ marginLeft: "90%", marginTop: "-66px" }}
                    src={eye}
                    onClick={this.toggleConfirmEye}
                  />{" "}
                   <div
                      style={{
                        display: this.state.errorMessage.emptyconfirmPassword
                          ? "contents"
                          : "none",
                        color: "red",
                      }}
                    >
                      {this.state.errorMessage.emptyconfirmPassword}
                    </div>
                </div>
              </form>
              {/* <div className="opt-in-alert">
                <h5>Password changed successfully</h5>
              </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={()=>this.props.closePasswordChangeModal(0)}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.updatePassword}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
        {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
      </div>
    );
  }
}
