import React, { Component } from "react";
import Header from "../../../_components/Header";
import { history } from "../../../_helpers/history";
import { courseService } from "../../../_services/Course.service";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Loader from "../../../_helpers/Loader";
import PopupModal from "../../../_helpers/PopupModal";
import Logout from "../../../_helpers/Logout";
import Advanceunit from "../../../assets/Advance unit.png";
import handbook from "../../../assets/handbook.jpg";
import download from "../../../assets/download.png";
import { MessageType } from "../../../_helpers/Enums";
import { helperText } from "../../../_helpers/helpertext";
import pdfHandbook from "../../../assets/Professional-Qualification-in-Marine-Surveying-Course-Handbook.pdf";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../../_helpers/utilities";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
};
class AllCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      courseData: [],
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      coreUnitList: [],
      specialUnitList: [],
      onHold: false,
      handBookPath: "",
      categoryName: "",
      showModules : false,
      viewIconId : 1
    };
  }

  componentDidMount() {
    this.showModule();
  }

 /* <summary>
  date: 19-10-2021
  Name: VD
  description: To get the list of all course based on the course assigned to the student
  <summary>*/
  getModuleList=()=>{
    try {
      this.setState({ isLoading: true });
        const userID = GetLocalStorageDataWithoutParse("UserID");
        const isOnHold = GetLocalStorageDataWithoutParse("StudentOnHold");
      this.setState({ isLoading: true }, async () => {
        var response = await courseService.GetCourseDetailsByStudentId(userID);
        if (response.status === 200) {
          if (response.data.length !== 0) {
            this.setState({
              courseData: response.data,
              onHold:
                isOnHold == undefined
                  ? response.data[0].isOnHold === true
                    ? "true"
                    : "false"
                  : isOnHold,
              categoryName: response.data[0].categoryName,
            });
            this.filterList();
          }
        } else if (response.status == 401) {
          Logout();
        } else {
          this.setState({
            popUpModalText: helperText.DataLoadError.loadError,
            showPopupModal: true,
            messageType: MessageType.Failure,
          });
        }
      });
      this.setState({ isLoading: false });
    } catch (ex) {
      return "";
    }
  }

  showModule=async()=>{
    try{
      
      this.setState({ isLoading: true });
        const userID = GetLocalStorageDataWithoutParse("UserID");
      var response = await courseService.ShowHideModule(userID);
      if(response.status == 200 ){
        if(response.data == true){
          this.setState({
            showModules:true,
          },async()=>{
            this.getModuleList();
          })
        }else{
          this.setState({
            showModules:false,
          })
        }
      }
      this.setState({ isLoading: false });
    }catch(ex){
      return "";
    }
  }

  showIndivisualCourse = (Item) => {
    if (this.state.onHold == "false") {
      history.push(`/CourseDetails/${Item}`);
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  /* <summary>
  date: 19-10-2021
  Name: VD
  description: To filter the list to show core Modules on top and to show special Module below
  <summary>*/
  filterList = () => {
    const { courseData } = this.state;
    let coreUnitArr = [];
    let specialUnitArr = [];
    courseData.forEach((item) => {
      if (item.moduleTypeId == 1) {
        coreUnitArr.push(item);
      } else {
        specialUnitArr.push(item);
      }
      return item;
    });

    this.setState({
      coreUnitList: coreUnitArr,
      specialUnitList: specialUnitArr,
    });
  };

  downloadHandbook = () => {
    window.open(pdfHandbook, "_blank");
  };

    handleViewIconClick = (iconId) => {
        debugger
    this.setState({
        viewIconId: iconId
    })
  }

  render() {
    const {
      isLoading,
      courseData,
      popUpModalText,
      showPopupModal,
      messageType,
      coreUnitList,
      specialUnitList,
      categoryName,
      showModules,
      viewIconId,
    } = this.state;
    return (
      <div className="student-body">
        <Header currentTab="StudyMaterial" />
        {isLoading && <Loader />}
        <div className="student-footer-body-space">
          <div className="menu-overlay"> &nbsp;</div>
          {/* /* --- Body Code ---* /  */}
          {/*1st section for banner*/}
          <section id="coursenbanner" className="home-section home-small">
            <div className="row">
              <div className="col-md-12">
                <h2>Course Library</h2>
                <p>All Modules available for your learning activity</p>
              </div>
            </div>
          </section>
          {/*1st section for banner ends*/}
          {showModules?
          <section className="container-fluid">
            <div className="row">
              <div className="courselist col-md-9" id="content">
                <div className="row" style={{ paddingTop: "16px" }}>
                  <div className="col-md-10">
                    <h4 className="title-unit">
                      <strong> {categoryName}</strong>
                    </h4>
                  </div>
                  <div className="col-md-2">
                                        <ul className="nav nav-tabs course-tabs m-b-n-xxs xd message-tabs image-tab parent-tab">
                                            <li className={`tabular-width tab-width-cls tab-space-cls ${viewIconId===1 && 'active'} `}>
                        {" "}
                        <a
                          className="icon-active tab-width icon-color icon-background tab-border-color tab-spacing"
                          onClick={()=>this.handleViewIconClick(1)}
                          href="#Sent"
                          data-bs-toggle="tab"
                        >
                          {" "}
                          <i className="fa fa-th" aria-hidden="true" />{" "}
                        </a>{" "}
                      </li>
                                            <li className={`tabular-width  ${viewIconId === 2 && 'active'}`}>
                        {" "}
                        <a
                          className="icon-active tab-width icon-color icon-background tab-border-color"
                          onClick={()=>this.handleViewIconClick(2)}
                          href="#Recieved"
                          data-bs-toggle="tab"
                        >
                          <i className="fa fa-th-list" aria-hidden="true" />{" "}
                        </a>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*1st tab content starts */}
              <div className="courselist col-md-9" id="content">
                <div className="panel panel-default tab-content  courses-lists">
                   
                  <ul className={`list-group tab-pane ${viewIconId===2 && 'active'}`} id="Recieved">
                    <div className="col-md-12" id="course-list">
                      <div
                        className="table-responsive"
                        style={{ paddingTop: "1em" }}
                      >
                        <table className="table certificate unit-table course-table">
                          <thead>
                            <tr>
                              {/* <th
                              className="unit-top"
                              style={{ display: "flex", border: "none" }}
                            >
                              Module <img src={sort} width="20px" />
                            </th> */}
                              <th
                                className="unit-top"
                                style={{ border: "none" }}
                              >
                                Module Part
                              </th>
                              <th />
                              {/* <th className="align-left unit-top">Type</th> */}
                              <th className="align-left unit-top">
                                Module Title
                              </th>
                              <th className="align-left unit-top">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {courseData.map((item) => {
                              return (
                                <tr
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.showIndivisualCourse(item.moduleID)
                                  }
                                >
                                  {/* <td className="unit-top">{item.moduleID}</td> */}
                                  <td className="unit-top">
                                    {item.modulePartNumber}
                                  </td>
                                  <td className="boldtag unit-top">
                                    <img
                                      src={
                                        item.unitCoverImageFileMediaId == null
                                          ? Advanceunit
                                          : item.unitCoverImageFileMediaId
                                      }
                                      width="75px"
                                    />
                                  </td>
                                  {/* <td className="left unit-top">Core Module</td> */}
                                  <td className="boldtag left unit-top">
                                    {item.unitName}
                                  </td>
                                  <td className="left unit-top">
                                    {item.description}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                                    </ul>
                   
                  {/*1st tab content tables ends */}
                  {/*2nd tab content starts */}
                   
                  <ul
                    className={`list-group tab-pane list-group-alt ${viewIconId===1 && 'active'} list-group-lg`}
                    id="Sent"
                  >
                    <div className="col-xs-12"></div>
                    <div className="col-xs-12">
                      {/* START SLICK SLIDER */}
                      <p />
                      {/* <div className="slider responsive lazy"> */}
                      <Carousel
                        ref={(el) => (this.Carousel = el)}
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        focusOnSelect={true}
                        // autoPlay={
                        //   this.props.deviceType !== "mobile" ? true : false
                        // }

                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        //removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {/* <div><div className="slider"> */}
                        {coreUnitList.map((item) => {
                          return (
                            <div
                              onClick={() =>
                                this.showIndivisualCourse(item.moduleID)
                              }
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="columno card-hover post-card">
                                  <div className="post-module hover">
                                    <div className="core-unit-thumbnail">
                                      <div>
                                        <center>
                                          <img
                                            src={
                                              item.unitCoverImageFileMediaId ==
                                              null
                                                ? Advanceunit
                                                : item.unitCoverImageFileMediaId
                                            }
                                            style={{
                                              width: "auto",
                                              height: "170px",
                                            }}
                                          />{" "}
                                          <figcaption>
                                            {item.unitName}
                                          </figcaption>
                                        </center>
                                      </div>
                                    </div>
                                    <div className="post-content post-content-responsive">
                                      <div className="clearfix" />
                                      <p>{item.description}</p>
                                      {/* <p className="description">5 Hrs. 30 Mins.<br />
                                  Expires on 23rd Oct 2021
                                </p> */}
                                      <div className="clearfix" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Carousel>
                      <p>{/* END SLICK SLIDER */}</p>
                    </div>
                    <div className="col-xs-12">
                      {/* START SLICK SLIDER */}
                      <p />
                      <Carousel
                        ref={(el) => (this.Carousel = el)}
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={false}
                        focusOnSelect={true}
                        // autoPlay={
                        //   this.props.deviceType !== "mobile" ? true : false
                        // }

                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        //removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                      >
                        {/* <div><div className="slider"> */}
                        {specialUnitList.map((item) => {
                          return (
                            <div
                              onClick={() =>
                                this.showIndivisualCourse(item.moduleID)
                              }
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "inline-block",
                                  cursor: "pointer",
                                }}
                              >
                                <div className="columno card-hover post-card">
                                  <div className="post-module hover">
                                    <div className="core-unit-thumbnail">
                                      <div>
                                        <center>
                                          <img
                                            src={item.unitCoverImageFileMediaId}
                                            style={{
                                              width: "auto",
                                              height: "170px",
                                            }}
                                          />{" "}
                                          <figcaption>
                                            {item.unitName}
                                          </figcaption>
                                        </center>
                                      </div>
                                    </div>
                                    <div className="post-content post-content-responsive">
                                      <div className="clearfix" />
                                      <p>{item.description}</p>
                                      {/* <p className="description">5 Hrs. 30 Mins.<br />
                                   Expires on 23rd Oct 2021
                                 </p> */}
                                      <div className="clearfix" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {/* </div></div> */}
                      </Carousel>
                      {/* </div> */}
                      {/* END SLICK SLIDER */}
                    </div>
                  </ul>
                   
                  {/*2nd tab content ends*/}
                </div>
              </div>
                            {GetLocalStorageDataWithoutParse("BelongsToMarineCorrosionCourse") != 1?
              <div className="courselist col-md-3" id="content">
                <div className="row">
                  <div className="col-md-12 col-sm-6"></div>
                  <div className="col-md-12 col-sm-6">
                    <div className="handbook">
                      <br />
                      <h4>
                        <strong>Student Handbook</strong>
                      </h4>
                      <img
                        style={{ cursor: "pointer" }}
                        className="handbook-img img-responsive"
                        src={handbook}
                        onClick={() => this.downloadHandbook()}
                      />
                      <small
                        className="download-txt"
                        onClick={() => this.downloadHandbook()}
                        style={{ cursor: "pointer" }}
                      >
                        Download a guide to know more &nbsp;
                        <img src={download} width="25px;" />
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              :""}
            </div>
          </section>
         :"" }
        </div>
        <footer id="footer" className="student-footer">
          <div className="row">
            <div className="col-md-12 text-center">
              Copyright {new Date().getFullYear()} <span>IIMS</span> &nbsp; |
              &nbsp;{" "}
              <a
                style={{ cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    `${window.location.origin}/pdf/Disclaimer and Site Terms.pdf`,
                    "_blank"
                  )
                }
              >
                Disclaimer and Site Terms
              </a>
            </div>
          </div>
        </footer>
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}

export default AllCourses;
