import React from 'react';
import {
  Row,
  Col,
  Input,
  Button
} from 'reactstrap';
import Loader from './Loader';
import '../Forms/commonStyle.css';
import axios from 'axios';
import {config} from "./config";
import PopupModal from './PopupModal';
import { MessageType } from './Enums';
import { helperText } from './helpertext';
import { uploadfileservice } from '../_services/uploadfile.service';

const messages = {
   UploadTextExcel: 'Upload the file in excel (.xlsx, .xls) format',
  UploadTextHtml: 'Upload the file in html (.html) format',
  InvalidFileExcel: 'Please upload an excel file.',
  InvalidFileHtml: 'Please upload a html file.',
  FileUploadSuccess: 'File uploaded successfully.',
  FileUploadFailure: 'File Upload Failed',
  UploadUnsuccessful: 'Upload Unsuccessful',
  Criticality: "Invalid Criticality column value in Excel File. Values should be either 'High' or 'Medium' or 'Low'.",
 ColumnLength: " exceeds the 100 char limit. Please limit characters to 100 and try again.",
  ColumnLengthInstalledSystem: " exceeds the 250 char limit. Please limit characters to 250 and try again.",
  //"File could not be uploaded. Please resolve errors in excel to continue.",
  DateFormat: "Invalid date. Please enter the Date column in DD/MM/YYYY format.",//"Please enter the Date column in DD/MM/YYYY format.",
  DateFuture: "Invalid date. Valid date must be from 01/01/2020 till today.",
  ITPatching: "Invalid ITPatching column value in Excel File. Values should be either 'Yes' or 'No'.",
  wordrequired: "Invalid Password Required column value in Excel File. Values should be either 'Yes' or 'No'.",
  ITPatchingAntivirus: "Please enter IT Patching value as YES for software Type 'Software - Antivirus'.",
};
export class UploadModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      excelNotAttached: false,
      excelFormFile: null,
      isModalOpen: false,
      isLoading: false,
      excelFileInputKey: '',
      callUploadMethod: '', 
      fileData: {},
      alertImage: 0,
      showPopupModal:false,
      popUpModalText:"",
      messageType:0,
    };
  }

  componentDidMount=()=>{
    this.setState({ callUploadMethod: 'UploadExcelSheetDataToDB' });
  }

  closeModal = () => {
    this.props.closeModal();
  };

  getUploadMessage = () => {
    return messages.UploadTextExcel;
  }

  getInvalidFileMessage = () => {
    let message = '* '

     message += messages.InvalidFileExcel;

    return message;
  };

  getValidFormats = () => {
    return ['xlsx', 'xls', 'csv'];
  }

  onExcelFileChange = e => {
    //check if file selected is a xls or xlsx file
    if (!this.isExcelFile(e.target.value)) {
      let randomString = Math.random().toString(36);
      this.setState({
        excelFileInputKey: randomString,
        excelFormFile: null,
        excelNotAttached: true,
      });
      return;
    }
    this.setState({
      excelNotAttached: false,
      excelFormFile: e.target.files[0],
    });

  };

  isExcelFile = value => {
    //check if value is blank
    if (!value) return false;

    //regex to get file extension
    var re = /(?:\.([^.]+))?$/;

    //second item in the array will be the extension
    let ext = re.exec(value)[1];
    const validFormats = this.getValidFormats();

    if (validFormats.some(format => format === ext)) return true;
    else return false;
  };

  handleExcelFormSubmit = async e => {
      try {
      e.preventDefault();
      if (!this.state.excelFormFile) {
        this.setState({ excelNotAttached: true });
        return;
      }

      this.setState({
        isLoading: true,
      });
      var currentMonth = new Date().getMonth() + 1;
      var localDate = new Date().getFullYear() + '-' + currentMonth + '-' + new Date().getDate();

      let data = new FormData();
      data.append('excelFile', this.state.excelFormFile);
      data.append('uploadDate', localDate);
      data.append('excelSheetName', 'RegisteredStudentSheet');

          var res = await uploadfileservice.UploadStudentData(this.state.callUploadMethod, data);
          if (res.status === 200) {
              //create a random string and redraw file input tag
              let randomString = Math.random().toString(36);

              this.setState({
                  excelFileInputKey: randomString,
                  excelFormFile: null,
              });
              if (res.data == "Success") {
                  this.setState({
                      popUpModalText: helperText.DataLoadError.dataUploadSuccess,
                      showPopupModal: true,
                      messageType: MessageType.Success,
                  });
              } else if (res.data == "Failure") {
                  this.setState({
                      popUpModalText: helperText.DataLoadError.uploadDataError,
                      showPopupModal: true,
                      messageType: MessageType.Failure,
                  });
              }
              else {
                  this.setState({
                      popUpModalText: res.data,
                      showPopupModal: true,
                      messageType: MessageType.Failure,
                  });
              }
          }
          else {
              this.setState({
                  popUpModalText: helperText.DataLoadError.uploadDataError,
                  showPopupModal: true,
                  messageType: MessageType.Failure,
              });

              this.noExcelFilePresentMessage = messages.UploadUnsuccessful;
              this.noExcelFilePresentModalTitle = 'Failure';
              this.setState({
                  isModalOpen: true,
                  alertImage: 0,
              });
          }
          this.setState({
            isLoading: false,
          });

          //await axios.post(config.apiUrl + '/Admin/' + this.state.callUploadMethod, data)
        //.then(res => {
        //  if (res.status==200) {
        //    //create a random string and redraw file input tag
        //    let randomString = Math.random().toString(36);

        //    this.setState({
        //      excelFileInputKey: randomString,
        //      excelFormFile: null,
        //    });
        //    if(res.data=="Success"){
        //      this.setState({
        //        popUpModalText: helperText.DataLoadError.dataUploadSuccess,
        //        showPopupModal: true,
        //        messageType:MessageType.Success,
        //      });
        //    }else if(res.data == "Failure"){
        //      this.setState({
        //        popUpModalText: helperText.DataLoadError.uploadDataError,
        //        showPopupModal: true,
        //        messageType:MessageType.Failure,
        //      });
        //    }
        //    else {
        //      this.setState({
        //        popUpModalText: res.data,
        //        showPopupModal: true,
        //        messageType:MessageType.Failure,
        //      });
        //    }
        //  }

        //  else {
        //    this.setState({
        //      popUpModalText: helperText.DataLoadError.uploadDataError,
        //      showPopupModal: true,
        //      messageType:MessageType.Failure,
        //    });
        //  }

        //})
        //.catch(err => {
        //  this.noExcelFilePresentMessage = messages.UploadUnsuccessful;
        //  this.noExcelFilePresentModalTitle = 'Failure';
        //  this.setState({
        //    isModalOpen: true,
        //    alertImage: 0,
        //  });
        //})
        //.then(() => {
        //  this.setState({
        //    isLoading: false,
        //  });
        //});
  } catch (e) {
    return "";
  }
};

  render() {
    const{
      showPopupModal,
      popUpModalText,
      messageType,
    }=this.state;
    return (
      <div
      id="myModal"
      className="modal"
      role="dialog"
      style={{
        overflow: "auto",
        display: "block",
        marginTop:"5.5%",
        opacity:1
      }}
    >
      <div className="modal-dialog">
        {/* Modal content*/}
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              onClick={this.closeModal}
              style={{
                padding: "0px 12px",
                margin: "5px",
                paddingBottom: "5px",
              }}
            >
              ×
            </button>
            <h4 className="modal-title">Upload</h4>
          </div>
          <div className="modal-body">
            <form>
            <p>
            <small className="text-muted">
              {this.getUploadMessage(1)}
            </small>
          </p>
          <small
            style={{
              color: 'red',
              display: this.state.excelNotAttached ? 'block' : 'none',
            }}
          >
            {this.getInvalidFileMessage(1)}
          </small>
          <Input
            type="file"
            accept={this.getValidFormats(1).map(x => `.${x}`).join(',')}
            name="excelFile"
            onChange={this.onExcelFileChange}
            key={this.state.excelFileInputKey || ''}
            disabled={this.state.isLoading}
            style={{overflow:'revert',whiteSpace:'break-spaces' }}
          />
          {/* <Container style={{ padding: '1em 0 0 0' }}> */}
            <Row>
              <div style={{marginRight:'40px'}}>
                {/* <Input
                  type="submit"
                 value="Upload"
                  size="sm"
                  className="btn btn-sm buttonColor"
                  disabled={this.state.isLoading}
                ></Input> */}
                <Button className="buttonCancelColor" size="sm"
                  style={{ marginLeft: '5px',float: 'right', fontSize: '12px' }} onClick={this.closeModal}>
                  <b>CANCEL</b>
                </Button>
                <Button className="buttonColor" size="sm"
                  style={{ marginLeft: '15px', float: 'right', fontSize: '12px' }} onClick={this.handleExcelFormSubmit}>
                  <b>UPLOAD</b>
                </Button>

              </div>
              <Col md="12" >
                {this.state.isLoading ? (
                  <Loader />
                ) : (
                    <div></div>
                  )}
              </Col>
            </Row>
          {/* </Container> */}
            </form>
          </div>
        </div>
      </div>
      {showPopupModal && (
          <PopupModal closeModal={this.closeModal} Text={popUpModalText} messageType={messageType} />
        )}
    </div>
    );
  }
}
