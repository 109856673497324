import React, { Component } from "react";
import AdminHeader from "../../_components/AdminHeader";
import p0 from "../../assets/p0.jpg";
import moment from "moment";
import { adminservice } from "../../_services/admin.service";
import Loader from "../../_helpers/Loader";
import RegisterStudentModel from "../../Models/RegisterStudentModel";
import { studentService } from "../../_services/student.service";
import AdminFooter from "../../_components/AdminFooter";
import PasswordChangeModal from "../../_helpers/PasswordChangeModal";
import CommonUploadForm from "../../_helpers/CommonUploadForm";
import upload from "../../assets/upload.png";
import { MessageType } from "../../_helpers/Enums";
import PopupModal from "../../_helpers/PopupModal";
import { helperText } from "../../_helpers/helpertext";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class AdminProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userID: 0,
      displayName: "",
      contactNo: "",
      emailAddress: "",
      address: "",
      country: "",
      createdOn: moment().format("DD-MMMM-YYYY"),
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      showPasswordModal: false,
      folderName: "ProfileImage",
      showCommomUploadModel: false,
      profileImagePath: "",
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the admin profile 
  <summary>*/
  componentDidMount = async () => {
    try {
        var adminId = GetLocalStorageDataWithoutParse("UserID");
      this.setState({
        userID: adminId,
        isLoading: true,
      });

      var response = await adminservice.GetAdminProfile(adminId);
      if (response.status === 200) {
        this.setState({
          displayName: response.data.displayName,
          contactNo: response.data.contactNo,
          emailAddress: response.data.emailAddress,
          address: response.data.address,
          createdOn: response.data.createdOn,
          country: response.data.country,
          profileImagePath: response.data.profileImagePath,
        });
          AddLocalStorageData("ProfileImagePath",response.data.profileImagePath);
      } else if (response.status == 401) {
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  onTextChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      isChanged: true,
    });
  };

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: This is used to save the changes that have been done to the admin profile
  <summary>*/
  onEditClick = async () => {
    try {
      const {
        userID,
        displayName,
        contactNo,
        emailAddress,
        address,
        createdOn,
      } = this.state;
      this.setState({
        isLoading: true,
      });

      var registerStudentModel = new RegisterStudentModel();
      registerStudentModel.userID = userID;
      registerStudentModel.emailAddress = emailAddress;
      registerStudentModel.address = address;
      registerStudentModel.contactNo = contactNo;

      var response = await studentService.AddEditUserProfileInfo(
        registerStudentModel
      );
      if (response.status == 200) {
        //success
        this.setState({
          popUpModalText: helperText.AddEditUnitAddDynamicContent.moduleDetailsUpdated,
          showPopupModal: true,
          messageType: MessageType.Success,
        });
      } else {
        this.setState({
          popUpModalText: helperText.AddEditUnitAddDynamicContent.updateDetailsError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  changePassword = () => {
    this.setState({
      showPasswordModal: true,
    });
  };

  closePasswordChangeModal = (id) => {
    this.setState({
      showPasswordModal: false,
    });
    if(id==1){
      this.setState({
        popUpModalText:  helperText.PasswordChangeModalText.wordupdated,
          showPopupModal: true,
          messageType:MessageType.Success,
        });
    }else if(id==2){
      this.setState({
        popUpModalText: helperText.profile.wordUpdateError,
        showPopupModal: true,
        messageType:MessageType.Failure,
      });
    }
  };

  showUploadModel = async () => {
    this.setState({
      showCommomUploadModel: true,
    });
  };

  closeModal = () => {
    this.setState({
      showCommomUploadModel: false,
      showPopupModal: false,
    });
  };

  getFileMediaId = async (Id) => {
    if (Id == null) {
      this.setState({
        popUpModalText:helperText.profile.assignmentUploadError,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    } else {
      this.setState({
        popUpModalText:helperText.profile.assinmentUploaded,
        showPopupModal: true,
        messageType: MessageType.Success,
      });
    }

    await this.getProfileIcon();
  };

  getProfileIcon=async()=>{
      var userID = GetLocalStorageDataWithoutParse("UserID");
    var response=await studentService.GetProfileImagePath(userID);
    if(response.status==200){
        AddLocalStorageData("ProfileImagePath",response.data.response)
      await this.componentDidMount();
      window.location.reload();
    }
    this.setState({isLoading:false})
  }

  render() {
    const {
      isLoading,
      displayName,
      createdOn,
      contactNo,
      emailAddress,
      address,
      showPasswordModal,
      folderName,
      showCommomUploadModel,
      userID,
      profileImagePath,
      showPopupModal,
      popUpModalText,
      messageType,
    } = this.state;
    return (
      <div>
        <AdminHeader currentTab="None" />
        {isLoading && <Loader />}
        <div className="menu-overlay"> &nbsp;</div>
        {/* Main body code */}
        <div className="admin-main-body">
          <section className="admin-profile-sec1">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="col-md-2 col-sm-2 col-xs-6">
                    <img
                      src={profileImagePath == "" ? p0 : profileImagePath}
                      className="img-responsive"
                    />
                    <div className="profile-img-Upload">
                      <img
                        src={upload}
                        className="img-responsive"
                        onClick={() => this.showUploadModel()}
                      />{" "}
                    </div>
                  </div>
                  <div className="col-md-10 col-sm-10 col-xs-6">
                    <h3>
                      {displayName}
                      {/* <a className="info-icon" title="More informtion">
                      <img src={info} />
                    </a> */}
                    </h3>
                    <p>IIMS Education Admin</p>
                    <p>
                      Enrolled on: {moment(createdOn).format("DD-MMMM-YYYY")}
                    </p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        this.changePassword();
                      }}
                    >
                      Change Password
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="content">
            <div className="container-fluid">
              <div className="row">
                <div className="courselist col-md-6">
                  <div className="form-group">
                    <label htmlFor="lastname" className="control-label">
                      Organisation
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      defaultValue="IIMS"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastname" className="control-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      placeholder="Phone Number"
                      name="contactNo"
                      value={contactNo}
                      onChange={this.onTextChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastname" className="control-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="lastname"
                      placeholder="Email Address"
                      name="emailAddress"
                      value={emailAddress}
                      onChange={this.onTextChange}
                    />
                  </div>
                </div>
                <div className="courselist col-md-6">
                  <div className="form-group">
                    <label htmlFor="lastname" className="control-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      placeholder="Country"
                      name="address"
                      value={address}
                      onChange={this.onTextChange}
                    />
                  </div>
                  {/* <div className="form-group">
                  <label htmlFor="lastname" className="control-label">
                    Other Info 1
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastname"
                    defaultValue="X00000000000"
                  />
                </div> */}
                </div>
              </div>
            </div>
          </section>
          <div className="student-profile-editbtn">
            <div
              className="col-md-12"
              style={{ textAlign: "center", marginBottom: "15px" }}
            >
              {" "}
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.onEditClick()}
              >
                Update Profile
              </button>
            </div>
            <div className="clearfix" />
          </div>
        </div>
        <AdminFooter />
        {showPasswordModal && (
          <PasswordChangeModal
            closePasswordChangeModal={this.closePasswordChangeModal}
            emailAddress={emailAddress}
          />
        )}
        {showCommomUploadModel && (
          <CommonUploadForm
            closeModal={this.closeModal}
            getFileMediaId={this.getFileMediaId}
            folderName={folderName}
            currentPage="Profile"
            userId={userID}
          />
        )}
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
