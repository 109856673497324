import React, { Component } from "react";
import Header from "../../_components/Header";
import StudentFooter from "../../_components/StudentFooter";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import { studentService } from "../../_services/student.service";
import DataTable from "react-data-table-component";
import moment from "moment";
import { history } from "../../_helpers/history";
import { MessageType } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class Certificates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      userId: 0,
      certificateList: [],
      studentName: "",
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the certificate list of completed Modules
  <summary>*/ 
  componentDidMount = async () => {
    try {
        var studentId = GetLocalStorageDataWithoutParse("UserID");
        var userName = GetLocalStorageDataWithoutParse("UserName");
      this.setState({
        isLoading: true,
        userId: studentId,
        studentName: userName,
      });
      var response = await studentService.GetCompletedUnitList(studentId);
      if (response.status == 200) {
        this.setState({
          certificateList: response.data,
        });
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (e) {
      return "";
    }
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  header = [
    {
      name: <div className="table-header" scope="col"></div>,
      //selector:'membershipNumber',
      selector: (row) => <img src="images/medal.png" />,
      center: true,
    },
    {
      name: (
        <div className="table-header" scope="col" key={row=>row.modulaName}>
          Module Name
        </div>
      ),
      //selector: "modulaName",
      selector: (row) => row.modulaName,
      left: true,
      grow: 2,
    },
    {
      name: (
        <div className="table-header" scope="col">
          Completed Date
        </div>
      ),
      //selector: "moduleCompletedDate",
      selector: (row) => moment(row.moduleCompletedDate).format("DD-MMMM-YYYY"),
      center: true,
    },
    {
      name: (
        <div className="table-header" scope="col">
          Download
        </div>
      ),
      //selector:'membershipNumber',
      selector: (row) => (
        <img
          src="images/download.png"
          style={{cursor:'pointer'}}
          onClick={() => this.downloadCertificate(row.modulaName)}
        />
      ),
      center: true,
    },
  ];

  downloadCertificate = (moduleName) => {
    const { studentName } = this.state;
    history.push(`/CertificatePage/${moduleName}`);
  };
  
  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      certificateList,
    } = this.state;
    return (
      <div>
        <section className="vbox">
          <Header currentPage="CourseOverview" />
          {isLoading && <Loader />}
          <section>
            <section className="hbox stretch">
              <section
                id="coursenbanner"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <h2 style={{ marginTop: "3px" }}>My Certificates</h2>
                  </div>
                </div>
              </section>
              <section className="courselist col-md-12" id="content">
                <div className="panel panel-default tab-content xdff">
                  <div className="table-responsive">
                    {/* <table className="table certificate">
                      <thead>
                        <tr>
                          <th />
                          <th>Course Name</th>
                          <th>Completed Date</th>
                          <th>Download</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img src="images/medal.png" />
                          </td>
                          <td>Stabilty</td>
                          <td>04/04/2021</td>
                          <td>
                            <img src="images/download.png" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/medal.png" />
                          </td>
                          <td>Materials and Corrosion</td>
                          <td>04/04/2021</td>
                          <td>
                            <img src="images/download.png" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/medal.png" />
                          </td>
                          <td>
                            An Introduction to the Marine Surveying Profession
                          </td>
                          <td>04/04/2021</td>
                          <td>
                            <img src="images/download.png" />
                          </td>
                        </tr>
                      </tbody>
                    </table> */}
                    <DataTable
                      columns={this.header}
                      data={certificateList}
                      noHeader={true}
                      width="50%"
                      className="iizuaN"
                      pagination
                    />
                  </div>
                </div>
              </section>
              <div className="clearfix" />
            </section>
          </section>
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
