import React, { Component } from "react";
import coreunit1 from "../assets/core-unit1.png";
import download from "../assets/download.png";
import wwwicon from "../assets/www-icon.png";
import Header from "../_components/Header";
import { courseService } from "../_services/Course.service";
import Loader from "../_helpers/Loader";
import PopupModal from "../_helpers/PopupModal";
import { history } from "../_helpers/history";
import AdminHeader from "../_components/AdminHeader";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { ConvertFromHTML } from "../_helpers/utilities";
import ShowPdfFileModal from "../_helpers/ShowPdfFileModal";
import Advanceunit from "../assets/Advance unit.png";
import { MessageType } from "../_helpers/Enums";
import { helperText } from "../_helpers/helpertext";

export default class CourseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      moduleDetails: [],
      unitName: "",
      firstTabDescription: EditorState.createEmpty(),
      imageCoverPath: "",
      secondTab: [],
      showSecondTab: false,
      thirdTab: [],
      showThirdTab: false,
      fourthTab: [],
      showFourthTab: false,
      fifthTab: [],
      showFifthTab: false,
      sixthTab: [],
      showSixthTab: false,
      path: "",
      previewMode: false,
      isComponenetLoaded: false,
      briefDescription: "",
      downloadUnitText: "",
      showPdfModal: false,
      pdfFilePath: "",
      unitCoverImageFileMediaId: "",
      onHold: false,
      activeTab: 1
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the modules details based on the module id
  <summary>*/
  componentDidMount = async () => {
    try {
      const moduleId = this.props.match.params.moduleId;
      const preview = this.props.match.params.preview;
      const isOnHold = localStorage.getItem("StudentOnHold");
      this.setState({
        isLoading: true,
        previewMode: preview,
        isComponenetLoaded: true,
        onHold: isOnHold,
      });

      var response = await courseService.GetModuleDetailsById(moduleId);
      if (response.status == 200) {
        this.setState({
          moduleDetails: response.data,
        });

        this.filterData();
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (ex) {
      return "";
    }
  };

  filterData = async () => {
    const { moduleDetails } = this.state;
    let showSecondTab, showThirdTab, showFourthTab, showFifthTab, showSixthTab;
    var firstTabValue = moduleDetails.filter((item) => {
      if (item.moduleTabID == 1) {
        return item;
      }
    });
    var secondTabValue = moduleDetails.filter((item) => {
      if (item.moduleTabID == 2) {
        return item;
      }
    });
    if (secondTabValue.length == 0) {
      showSecondTab = false;
    } else {
      showSecondTab = true;
    }

    var thirdTabValue = moduleDetails.filter((item) => {
      if (item.moduleTabID == 3) {
        return item;
      }
    });
    if (thirdTabValue.length == 0) {
      showThirdTab = false;
    } else {
      showThirdTab = true;
    }

    var fourthTabValue = moduleDetails.filter((item) => {
      if (item.moduleTabID == 4) {
        return item;
      }
    });

    if (fourthTabValue.length == 0) {
      showFourthTab = false;
    } else {
      showFourthTab = true;
    }

    var fifthTabValue = moduleDetails.filter((item) => {
      if (item.moduleTabID == 5) {
        return item;
      }
    });
    if (fifthTabValue.length == 0) {
      showFifthTab = false;
    } else {
      showFifthTab = true;
    }

    var sixthTabValue = moduleDetails.filter((item) => {
      if (item.moduleTabID == 6) {
        return item;
      }
    });

    if (sixthTabValue.length == 0) {
      showSixthTab = false;
    } else {
      showSixthTab = true;
    }
    this.setState({
      unitName: firstTabValue[0].unitName,
      briefDescription: firstTabValue[0].heading,
      firstTabDescription: ConvertFromHTML(firstTabValue[0].description),
      imageCoverPath: firstTabValue[0].link,
      path: firstTabValue[0].path,
      unitCoverImageFileMediaId: firstTabValue[0].unitCoverImageFileMediaId,
      downloadUnitText: firstTabValue[0].downloadUnitText,
      secondTab: secondTabValue,
      thirdTab: thirdTabValue,
      fourthTab: fourthTabValue,
      fifthTab: fifthTabValue,
      sixthTab: sixthTabValue,
      showSecondTab: showSecondTab,
      showThirdTab: showThirdTab,
      showFourthTab: showFourthTab,
      showFifthTab: showFifthTab,
      showSixthTab: showSixthTab,
    });
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  downloadPdf = (path) => {
    try {
      if (path == null) {
        this.setState({
          popUpModalText:helperText.courseDetails.fileNotPresentError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      } else {
        // const url = window.URL.createObjectURL(new Blob([path]));
        // const link = document.createElement("a");
        // link.href = url;
        // // const fileName = response.headers["x-download-filename"];
        // // const fileName="First File";
        // link.setAttribute("download", getFileName(path));
        // // link.setAttribute(
        // //   'download',
        // //   `FileName.pdf`,
        // // );
        // document.body.appendChild(link);
        // link.click();
        window.open(path);
      }
    } catch (e) {
      return "";
    }
  };

  getVideoLinkEmbeded(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return match && match[2].length === 11
      ? "//www.youtube.com/embed/" + match[2]
      : null;
  }

  showPdf = (path) => {
    this.setState({
      showPdfModal: true,
      pdfFilePath: path,
    });
  };

  closepdfModal = () => {
    this.setState({
      showPdfModal: false,
    });
  };

  handleTabClick = (tabId) => {
    this.setState({
    activeTab: tabId
    })
  }

  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      unitName,
      firstTabDescription,
      secondTab,
      thirdTab,
      fourthTab,
      fifthTab,
      sixthTab,
      showSecondTab,
      showThirdTab,
      showFourthTab,
      showFifthTab,
      showSixthTab,
      path,
      previewMode,
      isComponenetLoaded,
      briefDescription,
      downloadUnitText,
      showPdfModal,
      pdfFilePath,
      unitCoverImageFileMediaId,
      onHold,
      activeTab,
    } = this.state;
    return (
      <div className="student-body">
        {isComponenetLoaded ? (
          previewMode ? (
            <AdminHeader />
          ) : (
            <Header currentTab="StudyMaterial" />
          )
        ) : (
          ""
        )}
        {isLoading && <Loader />}
        <section className="vbox1">
          {/* .aside */}
          {/* /.aside */}
          {/* /* --- Body Code ---* /  */}
          <section id="coursenbanner" className="course-module-unit1-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3>
                    <img src={coreunit1} alt="checked" />
                    {unitName}
                  </h3>
                  {isComponenetLoaded ? (
                    previewMode ? (
                      ""
                    ) : (
                      <p>
                        <a onClick={() => history.push(`/AllCourses`)} style={{cursor:'pointer'}}>
                          Study Material{" "}
                        </a>{" "}
                      </p>
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="course-unit1-tabs">
            <div className="container d-flex justify-content-center">
              <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                <div
                  className="tab-regular tab-unit"
                  style={{ marginTop: "1em" }}
                >
                  <ul className="nav nav-tabs " id="myTab" role="tablist">
                    <li className={`nav-item ${activeTab === 1 ? 'active' : ''}`}>
                      {" "}
                      <a
                        className="nav-link tab-button tabular"
                        onClick={()=>this.handleTabClick(1)}
                        //data-toggle="tab"
                        //href="#tab1"
                        //role="tab"
                      >
                        <strong>Your Module</strong>
                      </a>{" "}
                    </li>
                    {showSecondTab ? (
                      <li className={`nav-item ${activeTab === 2 ? 'active' : ''}`}>
                        {" "}
                        <a
                          className="nav-link tab-button tabular"
                          onClick={()=>this.handleTabClick(2)}
                          //data-toggle="tab"
                          //href="#tab2"
                          //role="tab"
                        >
                          <strong>Essential videos to watch</strong>
                        </a>{" "}
                      </li>
                    ) : (
                      ""
                    )}
                    {showThirdTab ? (
                      <li className={`nav-item ${activeTab === 3 ? 'active' : ''}`}>
                        {" "}
                        <a
                          className="nav-link tab-button"
                          onClick={()=>this.handleTabClick(3)}
                          //data-toggle="tab"
                          //href="#tab3"
                          //role="tab"
                        >
                          <strong>Essential reading </strong>
                        </a>
                      </li>
                    ) : (
                      ""
                    )}
                    {showFourthTab ? (
                      <li className={`nav-item ${activeTab === 4 ? 'active' : ''}`}>
                        {" "}
                        <a
                          className="nav-link tab-button tabular"
                          onClick={()=>this.handleTabClick(4)}
                          //data-toggle="tab"
                          //href="#tab4"
                          //role="tab"
                        >
                          <strong>Essential business management videos</strong>
                        </a>{" "}
                      </li>
                    ) : (
                      ""
                    )}
                    {showFifthTab ? (
                      <li className={`nav-item ${activeTab === 5 ? 'active' : ''}`}>
                        {" "}
                        <a
                          className="nav-link tab-button tabular"
                          onClick={()=>this.handleTabClick(5)}
                          //data-toggle="tab"
                          //href="#tab5"
                          //role="tab"
                        >
                          <strong>
                            Essential advice to help to improve your assignment
                          </strong>
                        </a>{" "}
                      </li>
                    ) : (
                      ""
                    )}
                    {showSixthTab ? (
                      <li className={`nav-item ${activeTab === 6 ? 'active' : ''}`}>
                        {" "}
                        <a
                          className="nav-link tab-button tabular"
                          onClick={()=>this.handleTabClick(6)}
                          //data-toggle="tab"
                          //href="#tab6"
                          //role="tab"
                        >
                          <strong>
                            Recommended other general resources for this Module
                          </strong>
                        </a>{" "}
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                  {activeTab === 1 &&
                  <div
                      className="tab-pane show active"
                      id="tab1"
                      role="tabpanel"
                    >
                      <div className="row tab-content-row">
                        <div className=" col-12 col-md-9">
                          <h2 className="menu-user-icon"> {unitName}</h2>
                          <h3 className="menu-user-icon">{briefDescription}</h3>

                          <Editor
                            editorStyle={{
                              //height: 200,
                              background: "#ffffff",
                            }}
                            editorState={firstTabDescription}
                            wrapperClassName="demo-wrapper sendtext-area-bg"
                            editorClassName="editer-content"
                            toolbarClassName="hide-toolbar"
                            toolbar={{
                              options: ["inline"],
                              inline: {
                                inDropdown: false,
                                options: ["bold", "italic", "underline"],
                              },
                            }}
                          />
                        </div>
                        <div className=" col-12 col-md-3">
                          <img
                            src={
                              unitCoverImageFileMediaId == null
                                ? Advanceunit
                                : unitCoverImageFileMediaId
                            }
                            className="img-responsive"
                            alt="marine surveying"
                          />
                          <br />{" "}
                          {path == null ? (
                            ""
                          ) : (
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => this.downloadPdf(path)}
                            >
                              <a className="unit-download-btn">
                                {downloadUnitText} &nbsp;{" "}
                              </a>
                              <img src={download} width="25px;" />
                            </span>
                          )}
                          <p />
                        </div>
                      </div>
                                    </div>
                  }
                  {activeTab === 2 &&
                    <div className="tab-pane show active" id="tab2" role="tabpanel">
                      <div className="row tab-content-row">
                        <div className="col-md-12">
                          <h4>Essential videos to watch</h4>
                          <p>
                            Here is a collection of recommended videos chosen
                            from YouTube. Although IIMS has vetted the YouTube
                            video links below, the Institute does not endorse
                            them, or their presenters and cannot be held
                            responsible for the accuracy of the content.
                            However, the links have been selected because they
                            give a good general overview with information
                            relevant to this module which may assist you with
                            your studies.
                          </p>
                        </div>
                      </div>
                      {secondTab.map((item) => {
                        return (
                          <div className="row tabs-gray-bg tab-content-row ">
                            <div className="col-md-12 ">
                              <h4>{item.heading}</h4>
                              <div className="row">
                                <div className="col-md-6">
                                  <iframe
                                    width="100%"
                                    height={250}
                                    src={this.getVideoLinkEmbeded(item.link)}
                                    title="YouTube video player"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  />
                                  {/* <span className="video-time">
                                      Video run time 10 minutes
                                    </span>
                                    {/* <span className="icon-bookmark">
                                      <img src={bookmarkicon} alt="Bookmark" />
                                      <a>Bookmark</a>
                                    </span> */}
                                </div>
                                <div className="col-md-6">
                                  <Editor
                                    editorStyle={{
                                      minHeight: "200px",
                                      background: "#EEEEEE",
                                    }}
                                    editorState={ConvertFromHTML(
                                      item.description
                                    )}
                                    wrapperClassName="demo-wrapper "
                                    editorClassName="editer-content custome-editor-content"
                                    toolbarClassName="hide-toolbar"
                                    toolbar={{
                                      options: ["inline"],
                                      inline: {
                                        inDropdown: false,
                                        options: [
                                          "bold",
                                          "italic",
                                          "underline",
                                        ],
                                      },
                                    }}
                                  />
                                  {/* <p>{item.description}</p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                  {activeTab === 3 &&
                    <div className="tab-pane show active" id="tab3" role="tabpanel">
                      <div className="row tab-content-row">
                        <div className="col-md-12">
                          <h4>Essential reading</h4>
                          <hr />
                        </div>
                      </div>
                      {thirdTab.map((item) => {
                        return (
                          <div className="row tabs-gray-bg tab-content-row">
                            <div className="col-md-12 ">
                              <div className="row">
                                <div className="col-md-9">
                                  <h4
                                    className="menu-user-icon"
                                    style={{ paddingLeft: "0px" }}
                                  >
                                    {item.heading}
                                  </h4>
                                  {item.isStringEmpty ||
                                  item.description == '<p dir = "auto"></p> ' ||
                                  item.description ==
                                    "<p dir = 'auto'>&nbsp;</p>" ? (
                                    ""
                                  ) : (
                                    <Editor
                                      editorStyle={{
                                        minHeight: "20px",
                                        height: "auto",
                                        background: "#EEEEEE",
                                      }}
                                      editorState={ConvertFromHTML(
                                        item.description
                                      )}
                                      wrapperClassName="demo-wrapper"
                                      editorClassName="editer-content"
                                      toolbarClassName="hide-toolbar"
                                      toolbar={{
                                        options: ["inline"],
                                        inline: {
                                          inDropdown: false,
                                          options: [
                                            "bold",
                                            "italic",
                                            "underline",
                                          ],
                                        },
                                      }}
                                    />
                                  )}
                                  <a href={item.link} target="_blank">
                                    {item.link}
                                  </a>
                                  {item.pdfCoverImage == null ? (
                                    ""
                                  ) : (
                                    <img
                                      src={
                                        item.pdfCoverImage == null
                                          ? Advanceunit
                                          : item.pdfCoverImage
                                      }
                                      style={{
                                        width: "160px",
                                        height: "185px",
                                      }}
                                      alt="Excellence"
                                      className="img-responsive"
                                    />
                                  )}
                                </div>
                                {/* {item.pdfCoverImage == null ? (
                                  ""
                                ) : (
                                  <div className="col-12 col-md-3">
                                    
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.showPdf(item.uploadPdfFilePath)
                                      }
                                    >
                                      <a
                                        style={{ paddingRight: "6px" }}
                                        className="unit-download-btn"
                                      >
                                        Preview
                                      </a>
                                    </span>
                                    <span
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.downloadPdf(item.uploadPdfFilePath)
                                      }
                                    >
                                      <a className="unit-download-btn">
                                        Download Module 1 &nbsp;{" "}
                                      </a>
                                      <img src={download} width="25px;" />
                                    </span>
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                  {activeTab === 4 &&
                    <div className="tab-pane show active" id="tab4" role="tabpanel">
                      <div className="row tab-content-row">
                        <div className="col-md-12">
                          <h4>Essential business management videos</h4>
                          <hr />
                        </div>
                      </div>

                      <div className="col-md-12 ">
                        <div className="row">
                          {fourthTab.map((item) => {
                            return (
                              <div className="row tabs-gray-bg tab-content-row ">
                                <div className="col-md-12 ">
                                  <h4>{item.heading}</h4>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <iframe
                                        width="100%"
                                        height={250}
                                        src={this.getVideoLinkEmbeded(
                                          item.link
                                        )}
                                        title="YouTube video player"
                                        frameBorder={0}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      />
                                    </div>
                                    <div className="col-md-6">
                                      <Editor
                                        editorStyle={{
                                          minHeight: "200px",
                                          background: "#EEEEEE",
                                        }}
                                        editorState={ConvertFromHTML(
                                          item.description
                                        )}
                                        wrapperClassName="demo-wrapper "
                                        editorClassName="editer-content custome-editor-content"
                                        toolbarClassName="hide-toolbar"
                                        toolbar={{
                                          options: ["inline"],
                                          inline: {
                                            inDropdown: false,
                                            options: [
                                              "bold",
                                              "italic",
                                              "underline",
                                            ],
                                          },
                                        }}
                                      />
                                      {/* <p>{item.description}</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <hr />
                      </div>
                    </div>
                  }
                  {activeTab === 5 &&
                    <div className="tab-pane show active" id="tab5" role="tabpanel">
                      <div className="row tab-content-row">
                        <div className="col-md-12">
                          <h4>
                            Essential advice to help to improve your assignment
                          </h4>
                          <hr />
                        </div>
                        {fifthTab.map((item) => {
                          return (
                            <div className="col-md-12 ">
                              <h4>{item.heading}</h4>
                              <div className="row">
                                <div className="col-md-6">
                                  <iframe
                                    width="100%"
                                    height={250}
                                    src={this.getVideoLinkEmbeded(item.link)}
                                    title="YouTube video player"
                                    frameBorder={0}
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  />
                                  {/* <span className="video-time">
                                      Video run time 10 minutes
                                    </span>
                                    {/* <span className="icon-bookmark">
                                      <img src={bookmarkicon} alt="Bookmark" />
                                      <a>Bookmark</a>
                                    </span> */}
                                </div>
                                <div className="col-md-6">
                                  <Editor
                                    editorStyle={{
                                      minHeight: "200px",
                                      background: "#ffffff",
                                    }}
                                    editorState={ConvertFromHTML(
                                      item.description
                                    )}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="editer-content"
                                    toolbarClassName="hide-toolbar"
                                    toolbar={{
                                      options: ["inline"],
                                      inline: {
                                        inDropdown: false,
                                        options: [
                                          "bold",
                                          "italic",
                                          "underline",
                                        ],
                                      },
                                    }}
                                  />
                                  {/* <p>{item.description}</p> */}
                                </div>
                              </div>
                              <hr />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  }
                  {activeTab === 6 &&
                    <div className="tab-pane hr-tab5 show active" id="tab6" role="tabpanel">
                      <div className="row tab-content-row">
                        <div className="col-md-12">
                          <h4>
                            Recommended other general resources for this Module
                          </h4>
                          <hr />
                        </div>
                      </div>
                      {/*                        
                        <div className="row hr-row">
                          <hr />
                        </div> */}
                      {sixthTab.map((item) => {
                        return (
                          <div>
                            <div className="row tabs-gray-dark-bg tab-content-row">
                              <div className="col-md-12 ">
                                <h5>
                                  {item.heading}{" "}
                                  {/* <span className="icon-bookmark">
                                      <img src={bookmarkicon} alt="course" />
                                      <a>Bookmark</a>
                                    </span> */}
                                </h5>
                                {item.link == "" ? (
                                  ""
                                ) : (
                                  <div className="unit-link-sec">
                                    <img
                                      src={wwwicon}
                                      alt="www"
                                      width="120px"
                                    />
                                    <a href={item.link} target="_blank">
                                      {item.link}
                                    </a>
                                    <p>( Click for more details )</p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="row hr-row">
                              <hr />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  }
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer id="footer">
            <div className="row">
              <div className="col-md-12 text-center">
              Copyright {(new Date().getFullYear())} <span>IIMS</span> &nbsp; | &nbsp;{" "}
                &nbsp; <a onClick={()=> window.open(`${window.location.origin}/pdf/Disclaimer and Site Terms.pdf` , '_blank')} style={{cursor:'pointer'}}>Disclaimer and Site Terms</a>
              </div>
            </div>
          </footer>
        </section>
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
        {showPdfModal && (
          <ShowPdfFileModal
            closepdfModal={this.closepdfModal}
            pdfFilePath={pdfFilePath}
          />
        )}
      </div>
    );
  }
}
