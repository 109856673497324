import React, { Component } from "react";
import Header from "../../_components/Header";
import DataTable from "react-data-table-component";
import Loader from "../../_helpers/Loader";
import PopupModal from "../../_helpers/PopupModal";
import CommonUploadForm from "../../_helpers/CommonUploadForm";
import { studentService } from "../../_services/student.service";
import StudentFooter from "../../_components/StudentFooter";
import { MessageType } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";
import { history } from "../../_helpers/history";
import { courseService } from "../../_services/Course.service";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class Assignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showCommomUploadModel: false,
      folderName: "Assignments",
      assignmentList: [],
      userId: 0,
      moduleId: 0,
      popUpModalText: "",
      showPopupModal: false,
      messageType: 0,
      showModules : false,
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To get the list of student assignment based on the user id
  <summary>*/
  componentDidMount = async () => {
    this.showModule();
    
  };

  /* <summary>
  date: 02-08-2022
  Name: VD
  description: This is used to check if the users course has expired or not
  <summary>*/ 
  showModule=async()=>{
    try{
      
      this.setState({ isLoading: true });
        const userID = GetLocalStorageDataWithoutParse("UserID");
      var response = await courseService.ShowHideModule(userID);
      if(response.status == 200 ){
        if(response.data == true){
          this.setState({
            showModules:true,
          },async()=>{
            this.getStudentAssignmentList();
          })
        }else{
          this.setState({
            showModules:false,
          })
        }
      }
      this.setState({ isLoading: false });
    }catch(ex){
      return "";
    }
  }

  getStudentAssignmentList=async()=>{
    try {
        var userID = GetLocalStorageDataWithoutParse("UserID");
      this.setState({
        isLoading: true,
        userId: userID,
      }); 
      var response = await studentService.GetStudentAssignmentListByStudentID( userID );
      if (response.status == 200) {
        this.setState({
          assignmentList: response.data,
        });
      } else {
        this.setState({
          popUpModalText: helperText.DataLoadError.loadError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (ex) {
      return "";
    }
  }
  list = [
    {
      name: <div className="table-header" scope="col"></div>,
      selector:(row) => <img src="images/medal.png" />,
      //cell: (row) => <img src="images/medal.png" />,
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Module Name
        </div>
      ),
      selector: row=>row.moduleName,
      //cell: (row) => row.moduleName,
      left: true,
      grid:2,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Action
        </div>
      ),
      //selector: "Action",
      selector: (row) =>
        row.action == 1
          ? "Pass"
          : row.action == 2
          ? "Fail"
          : row.action == 3
          ? "Resubmit"
          : row.action == 5
          ? "Distinction"
          : row.action == 6
          ? "Merit"
          : row.action == 4
          ?"Submitted"
          :"Submit",
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Download assignment questions
        </div>
      ),
      //selector:'Action',
      selector: (row) => (
        <img
          src="images/download.png"
          onClick={() => this.downloadAssignment(row.path)}
          style={{cursor:'pointer'}}
        />
      ),
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Upload assignment answers
        </div>
      ),
      //selector:'Action',
      selector: (row) =>
        row.action == 2 || row.action == 1 || row.action == 5 || row.action == 6  ? (
          <label
          >
            Uploaded
          </label>
        ) : (
          row.uploadedAssignmentPath == null || row.action == 3?
            <label 
            className="uploadBtn"
              for="file-upload"
              onClick={()=>history.push(`/AssignmentDetails/${row.moduleId}`)}
              style={{ cursor: "pointer" }}
              >
              Upload
            </label> 
          :
              <label
              
            >
              Uploaded
            </label>
          
        ),
      center: true,
    },
    {
      name: (
        <div
          className="table-header boldtag"
          scope="col"
          style={{ fontSize: "16px" }}
        >
          Assignment Feedback 
        </div>
      ),
      //selector: "Name",
      selector: (row) =>row.uploadedAssignmentPath==null?"":<a style={{cursor:'pointer'}} onClick={()=>history.push(`/AssignmentDetails/${row.moduleId}`)}> View Assignment</a>,
      center: true,
    },
  ];

  downloadAssignment = async (path) => {
    if (path == null) {
      this.setState({
        popUpModalText:helperText.ReadEmail.noPermissionToDownload,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    } else {
      // var filename = "Trial.pdf";;
      // const link = document.createElement("a");
      // link.href = path;
      // //link.href="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
      // link.setAttribute("download", filename); //or any other extension
      // document.body.appendChild(link);
      // link.click();
      window.open(path, "_blank");

      //window.location.assign(url);
    }
  };

  closePopupModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  getFileMediaId = async (Id) => {
    if (Id == null) {
      this.setState({
        popUpModalText:helperText.profile.assignmentUploadError,
        showPopupModal: true,
        messageType: MessageType.Failure,
      });
    } else {
      this.setState({
        popUpModalText:helperText.profile.assinmentUploaded,
        showPopupModal: true,
        messageType: MessageType.Success,
      });
    }

    await this.componentDidMount();
  };

  render() {
    const {
      isLoading,
      popUpModalText,
      showPopupModal,
      messageType,
      assignmentList,
      showModules,
    } = this.state;
    return (
      <div id="admin">
        <Header currentTab="Assignment" />
        {isLoading && <Loader />}
        <section className="AssignmentSection">
          <section id="coursenbanner" className="AssignmentHeader">
            <div className="row">
              <div className="col-md-12">
                <h2 style={{ marginTop: "3px" }}>Assignments</h2>
              </div>
            </div>
          </section>
          {showModules ?
          <section className="courselist col-md-12" id="content">
            <div className="panel panel-default tab-content xdff">
              <div className="table-responsive Assignment-Header">
                <DataTable
                  columns={this.list}
                  data={assignmentList}
                  noHeader={true}
                  width="50%"
                  className="iizuaN"
                  pagination
                />
              </div>
            </div>
          </section>
          :""}
          <div className="clearfix" />
        </section>
        <StudentFooter />
        {showPopupModal && (
          <PopupModal
            closeModal={this.closePopupModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
