import React, { Component } from "react";
import AdminHeader from "../../_components/AdminHeader";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { Input } from "reactstrap";
import { ConvertToHtml } from "../../_helpers/utilities";
import { uploadfileservice } from "../../_services/uploadfile.service";
import { adminservice } from "../../_services/admin.service";
import Loader from "../../_helpers/Loader";
import { history } from "../../_helpers/history";
import PopupModal from "../../_helpers/PopupModal";
import { MessageType } from "../../_helpers/Enums";
import { helperText } from "../../_helpers/helpertext";
import { isStringEmpty,isEmailValid } from "../../_helpers/string_helper";
import Deleteicon from "../../assets/RedDeleteIcon.png";
import DataTable from "react-data-table-component";
import { GetLocalStorageDataWithoutParse, GetLocalStorageDataWithParse, AddLocalStorageData } from "../../_helpers/utilities";

export default class EmailToAssessor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      subject: "",
      message: EditorState.createEmpty(),
      fileInputKey: this.generateRandomString(),
      errorMessage: {},
      hasMessage: false,
      userId: 0,
      sendTo: 0,
      showPopupModal: false,
      popUpModalText: "",
      messageType: 0,
      assessorList: [],
      selectAssessor: "",
      showAddEmailButton:false,
      newEmailAddress:"",
      emailError: false,
      AttachedFilesList: [],
    };
  }

  /* <summary>
  date: 15-11-2021
  Name: VD
  description: To the get the list of assessor
  <summary>*/
  componentDidMount = async () => {
    try {
        var UserID = GetLocalStorageDataWithoutParse("UserID");
     
      this.setState({
        userId: UserID,
      });
      this.GetAssessorList();

    } catch (e) {
      return "";
    }
  };

  GetAssessorList=async()=>{
    this.setState({
      isLoading: true,
    });
    var response = await adminservice.GetAssessorList();
    if (response.status == 200) {
      this.setState({
        assessorList: response.data,
      });
    }

    this.setState({
      isLoading: false,
    });
  }

  subjectText = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errorMessage: {},
      emailError: false,
    });
  };

  HandleTextChange = (editorState, name) => {
    var empty = editorState.getCurrentContent().hasText();
    this.setState({
      [name]: editorState,
      errorMessage: {},
      hasMessage: empty,
    });
  };

  onFileChange = (e) => {
    if (!e.target.value) {
      this.setState({
        fileInputKey: this.generateRandomString(),
        documentFile: null,
        AttachedFilesList: null,
      });
      return;
      }
    let files = e.target.files; 
    var ins = files.length;

    for (var i = 0; i < ins; i++) {
      let attachedFile = files[i]; 
      this.setState((prevState) => ({
        AttachedFilesList: [...prevState.AttachedFilesList, attachedFile],
      }));
    }

    this.setState({
      documentFile: files,
    });
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  /* <summary>
  date: 24-09-2021
  Name: VD
  description: This is used to send the mail from both admin and student side
  <summary>*/
  sendEmail = async (e) => {
    try {
      e.preventDefault();
      const { 
        subject, 
        message, 
        documentFile, 
        userId, 
        selectAssessor,
        AttachedFilesList,  
      } = this.state; 
      var value = ConvertToHtml(message);
      if (this.validate()) {
        this.setState({
          isLoading: true,
        });
        let data = new FormData();
        data.append("subject", subject);
        data.append("message", value);
        if(AttachedFilesList!=undefined){
          var ins = AttachedFilesList.length;   
              for (var x = 0; x < ins; x++) {
                  if (AttachedFilesList[x].size != null && AttachedFilesList[x].size != undefined) { 
                      data.append(`file_${x}`, AttachedFilesList[x]);
                  }
            }
          }
        data.append("userId", userId);
        data.append("sendTo", selectAssessor);

        var response = await uploadfileservice.SendEmail(data);
        if (response.status == 200) {
          this.setState({
            popUpModalText: helperText.AdvanceCoursePopupModalText.mailsent,
            showPopupModal: true,
            messageType: MessageType.Success,
            subject: "",
            message: EditorState.createEmpty(),
            fileInputKey: this.generateRandomString(),
            documentFile: null,
            errorMessage: {},
            hasMessage: false,
            AttachedFilesList: [],
          });
        }
        this.setState({
          isLoading: false,
        });
      }
    } catch (ex) {
      return "";
    }
  };

  validate = () => {
    const { subject, message, hasMessage,selectAssessor } = this.state;
    try {
      var isValid = true;
      if (selectAssessor == "" || subject == "" || hasMessage == false) isValid = false;

      if(selectAssessor == ""){
        var error=this.state.errorMessage;
        error["emptyemail"] = helperText.Email.emptyEmailAddress;
        this.setState({ errorMessage: error });
      }

      if (subject == "") {
        var error = this.state.errorMessage;
        error["emptysubject"] = helperText.AddEditTemplate.emptyTemplatesubject;
        this.setState({ errorMessage: error });
      }
      if (hasMessage == false) {
        var error = this.state.errorMessage;
        error["emptymessage"] = helperText.AddEditTemplate.emptyTemplatemessage;
        this.setState({ errorMessage: error });
      }

      return isValid;
    } catch (error) {}
  };

  closeModal = () => {
    this.setState({
      showPopupModal: false,
    });
  };

  OnEventChange(event) {
    this.setState({
      selectAssessor: event.target.value,
      errorMessage: {},
      emailError: false,
    });
  }

  addEditEmailButton=()=>{
    this.setState({showAddEmailButton:true})
  }

  saveEmailAddress=async()=>{
    const{newEmailAddress}=this.state; 
    var email=newEmailAddress;
    try{
      if (this.emailValidate()) {
      var response=await adminservice.AddEditAccessor(newEmailAddress);
      if(response.status=200){
        if(response.data==true){
          this.setState({
            popUpModalText: helperText.Email.accessorEmailSaved,
            showPopupModal: true,
            messageType: MessageType.Success,
            selectAssessor:email,
            newEmailAddress:"",
          })
          this.GetAssessorList();
        }else{
          this.setState({
            popUpModalText: helperText.Email.emailPresentError,
            showPopupModal: true,
            messageType: MessageType.Failure,
            newEmailAddress:"",
            selectAssessor:email,
          })
        }
        
      }else{
        this.setState({
          popUpModalText: helperText.Email.emailSaveError,
          showPopupModal: true,
          messageType: MessageType.Failure,
        })
      }
    }
    } catch (error) {

    }
  }

  emailValidate=()=>{
    const{newEmailAddress}=this.state;
    let isValid = true;
    if (isStringEmpty(newEmailAddress)) {
      isValid = false;
      this.setState({ emailError: helperText.registerText.emailAddressText });
    } else if (!isEmailValid(newEmailAddress)) {
      isValid = false;
      this.setState({
        emailError: helperText.registerText.inValidEmailAddress,
      });
    } else
      this.setState({
        emailError: "",
      });
      return isValid;
  }

  DeleteFile = (row) => {   
    let attachedlist = this.state.AttachedFilesList;
     var ins = attachedlist.length;   
      for (var x = 0; x < ins; x++) {
          if (attachedlist[x].name == row.name) {
              if (attachedlist[x].size != null && attachedlist[x].size != undefined) { 
                  const items = this.state.AttachedFilesList.filter(AttachedFilesList => AttachedFilesList.name !== row.name);
                  this.setState({ AttachedFilesList: items });
              }
              else {
                  this.setState((prevState) => ({
                      DeleteAttachedFilesList: [...prevState.DeleteAttachedFilesList, row.courseEnquiryEmailTemplateAttachmentBridgeId],
                  }));
                  const items = this.state.AttachedFilesList.filter(AttachedFilesList => AttachedFilesList.name !== row.name);
                  this.setState({ AttachedFilesList: items });
              }
          }
    }
      
  }

  AttachedFilesColumns = [
    {
      cell: (row) => <td className="center">{row.name}</td>,
      center: true,
      grow: 1,
    },
    {
      cell: (row) => (
        <td
          className="center material-tab"
          onClick={() => this.DeleteFile(row)}
          style={{ cursor: "pointer" }}
        >
         <img
                      src={Deleteicon}
                      className="manage-notification-deleteIcon"
                  />
        </td>
      ),
      center: true,
    },
  ];

  render() {
    const {
      isLoading,
      subject,
      message,
      fileInputKey,
      popUpModalText,
      showPopupModal,
      messageType,
      assessorList,
      selectAssessor,
      showAddEmailButton,
      newEmailAddress,
      emailError,
      AttachedFilesList,
    } = this.state;
    return (
      <div className="student-body">
        <AdminHeader currentTab="Students" />
        {isLoading && <Loader />}
        <div className="admin-main-body">
          <div className="menu-overlay"> &nbsp;</div>
          {/* Begin page content */}
          <main role="main" className="container">
            <div className="row">
              <div className="col-md-12">
                <h5 className="mt-3 mb-3 gray">Assignment Submissions</h5>
              </div>
              <div className="col-md-12" style={{ marginTop: "-1rem" }}>
                <h6 className="mt-3 mb-3 gray">
                  Students Manager&gt;{" "}
                  <span>
                    <strong> Enrolled Courses </strong>{" "}
                  </span>{" "}
                  &gt;{" "}
                  <span>
                    {" "}
                    <strong> Assignment Submissions </strong>{" "}
                  </span>{" "}
                  <span>
                    {" "}
                    <strong>
                      {" "}
                      &gt; Email Message to Assessor <strong> </strong>
                    </strong>
                  </span>
                </h6>
                <strong>
                  <strong></strong>
                </strong>
              </div>
              <strong>
                <strong>
                  <div className="col-12 col-md-12">
                    <form className="assessor-form">
                      <div className="form-group row">
                        <span
                          htmlFor
                          className="col-sm-2 col-form-label field-txt"
                        >
                          Email Address
                        </span>
                        <div className="col-sm-4">
                          <select
                            className="form-control1 assessor-names select-unit-class"
                            id="courses"
                            name="courses"
                            value={selectAssessor}
                            onChange={this.OnEventChange.bind(this)}
                          >
                            <option value="" selected disabled>
                              Select assessor's email address
                            </option>
                            {assessorList.map((item) => {
                              return (
                                <option value={item.emailAddress}>
                                  {item.emailAddress}
                                </option>
                              );
                            })}
                          </select>
                          <div
                            style={{
                              display: this.state.errorMessage.emptyemail
                                ? "contents"
                                : "none",
                              color: "red",
                            }}
                          >
                            {this.state.errorMessage.emptyemail}
                          </div>
                        </div>
                        {!showAddEmailButton &&(
                        <div className="col-md-1" id="hide" style={{marginTop:'-3px'}}>
                          <span className="add-item-icon">
                            <a
                              title="Add email"
                              // data-toggle="modal"
                              // data-target="#exampleModal"
                              className="plus-icon"
                              onClick={this.addEditEmailButton}
                            >
                              <i
                                className="fa fa-plus-circle"
                                // aria-hidden="true"
                              />
                            </a>
                          </span>
                        </div>)}
                        {showAddEmailButton && (
                        <div
                          className="col-md-5"
                          id="showtxt"
                        >
                          <input type="email" className="email-input" name="newEmailAddress" value={newEmailAddress} onChange={(e) => this.subjectText(e)} />
                          <button
                            type="button"
                            className="btn btn-dark button-color add-email "
                            onClick={this.saveEmailAddress}
                          >
                            Add Email
                          </button>
                          {!isStringEmpty(emailError) && (
                          <small
                            id="emailHelp"
                            className="form-text"
                            style={{ color: "red", marginLeft: "4px" }}
                          >
                            Enter proper email address.
                          </small>
                        )}
                        </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <span
                          htmlFor="inputPassword"
                          className="col-sm-2 col-form-label field-txt"
                        >
                          Subject
                        </span>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            name="subject"
                            className="form-control "
                            value={subject}
                            onChange={(e) => this.subjectText(e)}
                          />
                          <div
                            style={{
                              display: this.state.errorMessage.emptysubject
                                ? "contents"
                                : "none",
                              color: "red",
                            }}
                          >
                            {this.state.errorMessage.emptysubject}
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <span
                          htmlFor="inputPassword"
                          className="col-sm-2 col-form-label field-txt"
                        >
                          Message
                        </span>
                        <div className="col-sm-8">
                          <Editor
                            editorStyle={{
                              minHeight: "200px",
                              fontWeight: "normal",
                            }}
                            editorState={message}
                            wrapperClassName="demo-wrapper sendtext-area-bg"
                            editorClassName="editer-content"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={(editorState) =>
                              this.HandleTextChange(editorState, "message")
                            }
                            toolbar={{
                              options: ["inline"],
                              inline: {
                                inDropdown: false,
                                options: ["bold", "italic", "underline"],
                              },
                            }}
                          />
                          <div
                            style={{
                              display: this.state.errorMessage.emptymessage
                                ? "contents"
                                : "none",
                              color: "red",
                            }}
                          >
                            {this.state.errorMessage.emptymessage}
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <span
                          htmlFor="inputPassword"
                          className="col-sm-2 col-form-label field-txt"
                        >
                          Attachments
                        </span>
                        <div className=" col-12 col-sm-8">
                          <Input
                            type="file"
                            accept=".pdf,.xlsx, .xls,.doc,.docx"
                            // accept=" .doc, .docx, .pdf"
                            name="documentFile"   
                            multiple="multiple"             
                            onChange={this.onFileChange}
                            key={fileInputKey || ""}
                            style={{
                              marginTop: "10px",
                              width: "95%",
                              overflow: "revert",
                              whiteSpace: "break-spaces",
                              color: "transparent",
                              cursor:'pointer',
                            }}
                          />
                          <div id="selectedRefereceLetters" />
                          <DataTable
                            columns={this.AttachedFilesColumns}
                            data={AttachedFilesList}
                            noHeader={true}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <center>
                            <button
                              type="button"
                              className="btn btn-dark button-color send-email"
                              onClick={this.sendEmail}
                            >
                              Send
                            </button>
                          </center>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn back-button"
                      onClick={() => history.goBack()}
                    >
                      <i className="fa fa-angle-left" />
                      Back{" "}
                    </button>
                  </div>
                </strong>
              </strong>
            </div>
            <strong>
              <strong></strong>
            </strong>
          </main>
          <strong>
            <strong></strong>
          </strong>
        </div>
        <strong>
          <strong>
            <footer className="admin-footer-bar">
              <div className="container">
                <span className="text-muted">
                  © International Institute of Marine Surveying 2021
                </span>
              </div>
            </footer>
          </strong>
        </strong>
        {showPopupModal && (
          <PopupModal
            closeModal={this.closeModal}
            Text={popUpModalText}
            messageType={messageType}
          />
        )}
      </div>
    );
  }
}
